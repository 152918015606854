import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import SEO from 'react-seo-component';

import Spinner from './spiner';
import Footer from "./Footer";

class posts extends React.Component {

   constructor(props){
        super(props)
        this.state ={
                post: null,
                isLoaded:false,
                error:false,
                    }
                
                        }

 componentDidMount (){
  var self=this;
  $.post(

                  "https://blog.shopsmartautos.com/react_api.php",
                  {

                    "POST_BY_ID":"POST_BY_ID",slug:this.props.match.params.slug  },
                  function(data) {
                    var getResponse=JSON.parse(data);
                    self.setState({ post: getResponse });
                    self.setState({ isLoaded: true });                  
                  }
                  ); 
    }   


  render() {

   
              const { error, isLoaded } = this.state;
              const url=window.location.href;
     if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <Spinner/>;
        } else {
            const post  = this.state.post;
            const string = this.state.post.post_content;
            const renderString = string.replace(/(?:\r\n|\r|\n)/g, "<br>");

          
    return <main>
    <SEO
  title={post.post_title}
  titleTemplate={'shopsmartautos'}
  titleSeparator={'-'}
  description={post.post_content }
  image={post.featured[0]}
  pathname={'https://shopsmartautos.com/'}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
  author={post.author_name}
  article={true}
  publishedDate={post.post_date}
  modifiedDate={post.post_date}
/>
     <section>
      <div>
        <img src={post.featured[0]} style={{width:'100%'}}/>
      </div>
    </section>
          <div className="container-fluid mt-md-0 mt-5 mb-5">
            {/* Grid row */}
            <div className="row" style={{marginTop: '-100px'}}>
              {/* Grid column */}
              <div className="col-md-12 px-lg-5">
                {/* Card */}
                <div className="card pb-5 mx-md-3">
                  <div className="card-body">
                    <div className="container">
                      {/* Section heading */}
                      <h1 className="text-center h1 pt-4 mb-3">
                        <strong>{post.post_title}</strong>
                      </h1>
                      <div className="row">
                        <div className="col-md-12 col-xl-12 d-flex justify-content-center">
                          <p className="font-small dark-grey-text mb-1">
                            <strong>Author:</strong> Richie Bello</p>
                          <p className="font-small grey-text mb-0 ml-3">
                            <i className="far fa-clock-o dark-grey-text" /> {post.post_date}</p>
                        </div>
                      </div>
                      {/* Grid row */}
                      <div className="row row pt-lg-5 pt-3">
                        {/* Grid column */}
                        <div className="col-md-12 col-xl-12">
                          <form>
                            {/* Grid row */}
                            <div className="row mt-3">
                            <div dangerouslySetInnerHTML={{ __html:renderString}} />

                            </div>
                            {/* Grid row */}
                            {/* Grid row */}
                            <div className="row my-5">
                              {/* Grid column */}
                              <div className="col-md-12 text-center">
                                <h4 className="text-center font-weight-bold dark-grey-text mt-3 mb-3">
                                  <strong>Share this post: </strong>
                                </h4>
                                <a  target="_blank" className="btn btn-fb btn-sm" href={"https://www.facebook.com/sharer.php?u="+url}>
                                  <i className="fab fa-facebook-f left" /> Facebook</a>
                                {/* Twitter */}
                                <a  target="_blank" className="btn btn-tw btn-sm" href={"https://twitter.com/intent/tweet?url="+url}>
                                  <i className="fab fa-twitter left" /> Twitter</a>
                                {/* Google + */}
                                <a  target="_blank" className="btn btn-li btn-sm" href={"https://www.linkedin.com/sharing/share-offsite/?url="+url}>
                                  <i className="fab fa-linkedin left" />Linkedin</a>
                              </div>
                              {/* Grid column */}
                            </div>
                            {/* Grid row */}
                            <hr className="mt-5" />
                            {/* Comments */}
                                   {/* Comments */}
                            {/* Section: Leave a reply (Not Logged In User) */}
                          </form></div>
                        {/* Grid column */}
                      </div>
                      {/* Grid row */}
                    </div>
                    {/* Grid column */}
                  </div>
                  {/* Grid row */}
                </div>
                {/* Card */}
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </div>
            <Footer/>
        </main>
      

       
  }}
}
export default posts;