import React from 'react';

import '../App.css';
import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LazyLoad from 'react-lazy-load';
class Header extends React.Component {

constructor(props){
      super(props)

      this.create_UUID = this.create_UUID.bind(this)
      this.getCookie = this.getCookie.bind(this)
      this.delete_cookie = this.delete_cookie.bind(this)
    
      }
 
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
   create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

 delete_cookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

componentDidMount (){
  var self=this;
  
  var cookie=self.getCookie('ssa_cookie');
  if(cookie==undefined)
  {
      var getuiid=self.create_UUID();
      document.cookie='ssa_cookie='+getuiid;
      cookie=self.getCookie('ssa_cookie');

  }else
  {
     cookie=self.getCookie('ssa_cookie');
  }
       var refrer=document.referrer;
  
      $.post("https://www.shopsmartautos.com/get_traffic.php",
      {"get_traffic":'k','url':window.location.href,"cid":cookie,'urlcoming':refrer},function (html) {
          }
        );

  }

  render() {
    return  <nav className="navbar navbar-expand-lg ">
        {/* Navbar brand */}
  
   <Link className="nav-link" to="/">
     <img   style={{'width':'170px','height':'66px'}} src="../media/SHOP_SMART_LOGO23-2-scaled.jpg"/></Link>
        {/* Collapse button */}
       <button className="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2"
    aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span className="dark-blue-text"><i
        className="fas fa-bars fa-1x"></i></span></button>
        {/* Collapsible content */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent2">
          {/* Links */}
          <ul className="navbar-nav mr-auto">
            {/* Multi-level dropdown */}
            <li className="nav-item">
                <Link className="nav-link" to="/special-deals">Special Deals</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/new-cars">New Cars</Link>
            </li>
           
           <li className="nav-item">
                <Link className="nav-link" to="/private-party-cars">Private Party Cars</Link>
            </li>
            <li className="nav-item ">
                  <Link id="menu" className="nav-link  text-uppercase" to="/used-cars">Used Cars</Link>
            </li>
            <li className="nav-item ">
                 <Link  className="nav-link" to="/certified-cars">
                      Certified Cars
                  </Link>
            </li>

            <li className="nav-item ">
                 <Link  className="nav-link text-uppercase" to="/value-my-car">
                      Value My Car
                  </Link>
            </li>
            <li className="nav-item ">
                 <Link  className=" nav-link text-uppercase" to="/research-tool">
                      Research Tool
                </Link>
            </li>
            <li className="nav-item ">
                 <Link  className=" nav-link" to="/car-reviews">
                      Car Reviews
                </Link>
            </li>

            <li className="nav-item dropdown">
            <Link id="menu" data-toggle="dropdown" className="nav-link dropdown-toggle text-uppercase" >Dealers</Link>
              <ul className="dropdown-menu mt-2 rounded-0  border-0 z-depth-1 p-2">
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" to="/dealers">Dealers</Link>
                </li>
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" to="/new-car-dealers">New Car Dealers</Link>
                </li>
                <li className="dropdown-item dropdown-submenu p-0">
                  <Link id="menu"  className=" text-uppercase text-black w-100" to="/independent-dealer">Independent Dealer</Link>
                </li>
                <li className="dropdown-item dropdown-submenu p-0">
                  <a className=" text-uppercase text-black w-100" target="_blank" href="https://dealers.shopsmartautos.com/">
                    Dealers Login
                  </a>
                </li>

              </ul>
               
           </li>
          </ul>
        </div>
           
        
      </nav>;
}
}
export default Header;