import React from 'react';

import $ from 'jquery';

import "../slider-animations.css";
import "../styles.css";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import { Link } from 'react-router-dom';
class ads extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
          error: null,
          isLoaded: false,
          items: [],
          rangeValue:0,
          zipcode:null,
          errors:[],
          formValid:false,
           totalcount:0,
          currentcount:0,
          erroResponse:'',
          adsResponseError:null,
        };
        this.getRandomNumber = this.getRandomNumber.bind(this);
		  }
componentDidMount() {
       var self = this;
      // Get User current position
      self.setState({ isLoaded: false });
       navigator.geolocation.getCurrentPosition(function(position) {
        console.log(position);
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;    
      self.setState({latitude: position.coords.latitude,longitude: position.coords.longitude,});

      
        $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "nearByDealersBanner":"nearByDealers",latitude: position.coords.latitude,longitude: position.coords.longitude
             },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  if(getResponse!==null)
                  {
                        if(!getResponse.code)
                        {
                            self.setState({ items: getResponse.dealers });
                            self.setState({ totalcount: getResponse.num_found });
                            self.setState({ currentcount: getResponse.dealers.length });
                            self.setState({ isLoaded: true });
                            self.setState({ adsResponseError:null});
                        }else
                        {
                            self.setState({ erroResponse: getResponse.message });
                        }
                  }else
                  {
                            self.setState({ adsResponseError:'Dealers are not available to this location'});

                            $.post(
                                "https://www.shopsmartautos.com/react_api.php",
                            {
                            "nearByDealersBanner":"nearByDealers",latitude:40.94623,longitude: -73.069777
                            },
                              function(data) {
                                var getResponse=JSON.parse(data);
                                           if(!getResponse.code)
                                          {
                                            self.setState({ items: getResponse.dealers });
                                            self.setState({ totalcount: getResponse.num_found });
                                            self.setState({ currentcount: getResponse.dealers.length });
                                            self.setState({ isLoaded: true });
                                          }else
                                          {
                                              self.setState({ erroResponse: getResponse.message });
                                          }
                                      }
                                    );
                            }
                  }

               );
          // Get zipcodes accoding current location

      }, (error)=>{
        // On denied position
          var self = this;
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "nearByDealersBanner":"nearByDealers",latitude:40.94623,longitude: -73.069777
             },
                  function(data) {
                  var getResponse=JSON.parse(data);
                   if(getResponse!==null)
                  {
                        if(!getResponse.code)
                        {
                            self.setState({ items: getResponse.dealers });
                            self.setState({ totalcount: getResponse.num_found });
                            self.setState({ currentcount: getResponse.dealers.length });
                            self.setState({ isLoaded: true });
                            self.setState({ adsResponseError:null});
                        }else
                        {
                            self.setState({ erroResponse: getResponse.message });
                        }
                  }else
                  {
                            self.setState({ adsResponseError:'Dealers are not available to this location'});
                  }
                  }
               );


      self.setState  ({
                latitude:40.94623,
                longitude: -73.069777,
                Makes:null,

              });
      });
      }

      getRandomNumber(){
      var getNumber=  Math.floor(Math.random() * 30) + 1;
      const content = [
                            "../media/images/amg-1880381_1280.webp",
                            "../media/images/auto-2179220_1280.webp",
                            "../media/images/car-race-438467_1280.webp",
                            "../media/images/car-5548242_1280.webp",
                            "../media/images/bmw-918407_1280.webp",
                            "../media/images/ford-mustang-146580_1280.webp",
                            "../media/images/auto-2179220_1280.webp",
                            "../media/images/car-1376190_1280.webp",
                            "../media/images/amg-1880381_1280.webp",
                            "../media/images/auto-2209439_1280.webp",
                            "../media/images/ferrari-360-2918130_1280.webp",
                            "../media/images/sports-car-146185_1280.webp",
                            "../media/images/bmw-768688_1280.webp",
                            "../media/images/bmw-918407_1280.webp",
                            "../media/images/car-1376190_1280.webp",
                            "../media/images/mercedes-841465_1280.webp",
                            "../media/images/taxi-238478_1280.webp",
                            "../media/images/ferrari-458-spider-2932191_1280.webp",
                            "../media/images/mercedes-1737908_1280.webp",
                            "../media/images/mclaren-2317538_1280.webp",
                            "../media/images/lamborghini-aventador-2932196_1280.webp",
                            "../media/images/car-2115060_1280.webp",
                            "../media/images/auto-3209449_1280.webp",
                            "../media/images/mustang-2338356_1280.webp",
                            "../media/images/car-2176944_1280.webp",
                            "../media/images/nissan-gtr-2975651_1280.webp",
                            "../media/images/car-2176960_1280.webp",
                            "../media/images/lamborghini-huracan-2975652_1280.webp",
                            "../media/images/mercedes-1737913_1280.webp",
                            "../media/images/ferrari-f430-2930661_1280.webp",
                            "../media/images/mustang-2338358_1280.webp",
];
  return content[getNumber];
      }

		 render() {
      var self=this;

		      return(
            <div className="slider_banner">
           <small className="text-center"> {this.state.adsResponseError !==null ? this.state.adsResponseError : ''}</small>
    <Slider className="slider-wrapper " autoplay={500}>
      {this.state.items.map((item, index) => (

        item.inventory_url !=undefined ? (
        <div
          key={index}
          className="slider-content"
          style={{ background: `url(`+self.getRandomNumber()+`) no-repeat center center` }}
        >
          <div className="inner">
            <h1>{item.seller_name.slice(0, 20)}</h1>
           <p className="d-inline" ><i className="fa fa-car"></i> {item.num_found !==0? item.num_found.toLocaleString():(0)} | <img height="10px" className="online_icon" width="10px" src="https://img.pngio.com/neon-green-dot-clip-art-at-clkercom-vector-clip-art-online-green-dot-png-300_300.png" alt="online"/> Active |</p> <p className="d-inline"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></p>
            <p><i className="fa fa-map-marker" style={{'color':'#FFD800','fontSize':'20px'}}></i> {item.city+','+item.state}</p>
            <Link target="_blank" className="btn-lg btn-md btn-sm btn-warning btn-rounded" to={"dealer-inventry/"+item.seller_name.replace(/\s+/g, '-')+"-"+index+"-"+item.id} target="_blank">Visit Now</Link>
          </div>
          
        </div>):('')
      ))}
    </Slider></div>)
		    }
		  }
		

export default ads;