import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';

class Footer extends React.Component {
  render() {
    return <div className="footer">
   
   <div className="row footer-Herry">
      <div className="col-md-5 col-sm-12 eee">
         <div className="logo-part">
            <Link to="/">
               <img  style={{'width':'270px','height':'157px'}} data-src="../media/logo_footer.png" className="w-50 logo-footer lazyloaded" src="../media/logo_footer.png" />
            </Link>
            <p style={{color: 'white'}} className="ah"> Shop Smart Autos is not responsible for any errors in price or vehicle information provided to us from our dealer partners. We take every precaution to ensure the information is accurate and correct. Any questions please contact the dealer.</p>
         </div>
      </div>
      <div className="links_custom col-md-2 col-sm-12 eee" style={{padding: '38px 8px 24px 20px'}}><h6 className="h1_custom" style={{paddingLeft: '24px'}}>More Links</h6>
      <ul className="nee">
         <li><Link to="/special-deals" >Special Deals</Link></li>
         <li><Link to="/new-cars" >New Cars</Link></li>
       
         <li><Link to="/used-cars" >Used Cars</Link></li>
         <li><Link to="/certified-cars" >Certified Cars</Link></li>
      </ul>
   </div>
   <div className="links_custom col-md-2 col-sm-12 eee" style={{padding: '38px 8px 24px 20px'}}><h6 className="h1_custom new" style={{paddingLeft: '24px'}}><br /></h6>
   <ul className="nee new">
      <li><Link to="/value-my-car" >Value My Car</Link></li>
      <li><Link to="/research-tool" >Research Tool</Link></li>
      <li><Link to="/car-reviews" >Vehicles Reviews</Link></li>
      <li><Link to="/dealers" >Dealers</Link></li>
      <li><a href="https://dealers.shopsmartautos.com" target="_blank" >Dealer Log In</a></li>
   </ul>
</div>
<div className="col-md-3 col-sm-12 eee links_custom" style={{padding: '38px 8px 24px 20px'}}>
<h6 className="h1_custom">Contact us</h6>
  <ul className="navbar-nav ml-auto nav-flex-icons">
           <li className="social-icons-list-item nav-item">
                <a  target="_blank" href="https://twitter.com/shopsmartautos" className="social-icon nav-link waves-effect waves-light" target="_blank">
                  <span className="fa fa-twitter" style={{padding: '10px', fontSize: '16px', backgroundColor: '#4da7de'}} />
                </a>
              </li>
              <li className="social-icons-list-item nav-item">
                <a target="_blank" href="https://www.facebook.com/ShopSmartAutos/" target="_blank" className="social-icon nav-link waves-effect waves-light">
                  <span className="fa fa-facebook" style={{padding: '10px', fontSize: '16px', backgroundColor: '#3e5b98'}} />
                </a>
              </li>
              <li className="social-icons-list-item nav-item">
                <a  target="_blank" href="https://www.youtube.com/channel/UC7ArsLGgZt6Lia39I-t8Mcw" target="_blank" className="social-icon nav-link waves-effect waves-light">
                  <span className="fa fa-youtube" style={{padding: '10px', fontSize: '16px', backgroundColor: '#ef1010'}} />
                </a>
              </li>
              <li className="social-icons-list-item nav-item">
                <a target="_blank" href="https://www.instagram.com/shopsmartautosofficial/" target="_blank" className="social-icon nav-link waves-effect waves-light">
                  <span className="fa fa-instagram" style={{padding: '10px', fontSize: '16px', backgroundColor: '#9b6b51'}} />
                </a>
              </li>
         
          </ul>
<p style={{color: 'white'}}>  Shop Smart Autos Inc
7901 4th St N 
SUITE 300,
St. Petersburg, FL 33702</p>
<p className="h1_custom">Copyright © RBBP MARKETING LTD</p>
</div></div>
</div>;
  }
}
export default Footer;