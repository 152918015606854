import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class OurShopers extends React.Component {
  render() {
    return <div className="textwidget ourshopers custom-html-widget">
   <style dangerouslySetInnerHTML={{__html: "#tm-top-d {padding-top: 12px;}.a-single.a-17 {text-align: center;}.uk-panel.widget_custom_html {text-align: center;}" }} /><h2 className="text-center " style={{textAlign: 'center'}}>Our Shoppers Become Buyers</h2>
   <div className="col-lg-12"><p style={{textAlign: 'center'}}>ShopSmartAutos.com prides itself on offering an unprecedented inventory of over 6 million vehicles pulled from the top automotive vehicle sales sites and dealers across the country. Punch in the car you are looking for and see how many offers there are on that vehicle and where they are within driving distance from your home, or delivered to your doorstep.</p></div>
</div>;
  }
}
export default OurShopers;