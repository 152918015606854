import React from 'react';
import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Notfound from './notfound';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import SEO from 'react-seo-component';
import Testdrive from "./testdrive";
import Dealertrack from "./dealertrack";
class dealerview extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: [],
          vehicleClass:'new',
          rangeValue:0,
          selectedModel:null,
          selectedMake:null,
          dealerid:null,
          dealerImage:null,
          errors:[],
          formValid:false,
          MaxPrice:100,
          Makesearch:null,
          totalcount:0,
          currentcount:0,
          dealerinfo:null,
          dealeridindex:null,
          vin:null,
          price:null,
          VehicleDataInfo:null,

        };
        this.LoadMore = this.LoadMore.bind(this)
        this.PostData = this.PostData.bind(this)
      }

      PostData(SendData)
      {
          var self = this;
          var CurrentCars=$('.loadmore').length;
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  SendData,
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.listings });
                    self.setState({ dealerinfo: getResponse.dealerinfo });
                    self.setState({ totalcount: getResponse.num_found });
                    self.setState({ currentcount:getResponse.listings.length});
                    
                    self.setState({ isLoaded: true });

                  }
                );
      }

  componentDidMount (){

     var self = this;
     var dealer_path = window.location.pathname.split(/[\s-]+/);
     var dealerId=dealer_path[dealer_path.length-1];
     var dealerImage=dealer_path[dealer_path.length-2];
     var dealeridindex=dealerImage+'-'+dealerId;
      var SendData={};
        self.setState({ isLoaded: false });
           SendData['dealerinventry']='nearBy';
           SendData['dealer_id']=dealerId;
        self.PostData(SendData);
        self.setState({dealerid:dealerId,dealerImage:dealerImage,dealeridindex:dealeridindex});
    } 
      LoadMore()
      {
        var data_query={};
        var self=this;
        var CurrentCars=$('.loadmore').length;
        var array=self.state.items;        
         $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
        $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {'dealerinventryLoadMore':'dealerinventryLoadMore','startpoint':CurrentCars,'dealer_id':self.state.dealerid},
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                         var children = array.concat(getResponse.listings); 
                         var CurrentCarsTotal=self.state.currentcount+getResponse.listings.length;
                      self.setState({ items: children });
                      self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: CurrentCarsTotal });
                     $('#btn-one').html('Load More').removeClass('disabled');
                  }
               );
        

      }
      handleVehicleData= handleVehicleData=>{
            var self=this;
              var price=handleVehicleData.prices!=undefined ? handleVehicleData.prices : handleVehicleData.price !=null ? handleVehicleData.price :'';

                  self.setState({VehicleDataInfo:handleVehicleData,vin:handleVehicleData.vin,price:price})
                  localStorage.setItem("VehicleDataInfo", JSON.stringify(handleVehicleData));
                  
                }
  
     render() {
        const { error, isLoaded, items } = this.state;
      
      const  Makes  = this.state.Makes;
      const  Years  = this.state.Years;
      const  selectedMake  = this.state.selectedMake;
      const  Models  = this.state.Models;
      const { selectedOption } = this.state;
      const { selectedModel } = this.state;
      const { selectedYear } = this.state;
      const url=window.location.host;
      const href=window.location.href;

     const content = [
                            "https://cdn.pixabay.com/photo/2015/01/19/13/51/car-604019_1280.jpg",
                            "https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_1280.jpg",
                            "https://cdn.pixabay.com/photo/2020/09/06/07/37/car-5548242_1280.jpg",
                            "https://cdn.pixabay.com/photo/2012/11/02/13/02/ford-63930_1280.jpg",
                            "https://cdn.pixabay.com/photo/2013/07/12/12/56/ford-mustang-146580_1280.png",
                            "https://cdn.pixabay.com/photo/2017/03/27/14/56/auto-2179220_1280.jpg",
                            "https://cdn.pixabay.com/photo/2016/05/06/16/32/car-1376190_1280.jpg",
                            "https://cdn.pixabay.com/photo/2016/12/03/18/57/amg-1880381_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/04/06/22/11/auto-2209439_1280.png",
                            "https://cdn.pixabay.com/photo/2017/11/04/17/13/ferrari-360-2918130_1280.jpg",
                            "https://cdn.pixabay.com/photo/2013/07/12/12/45/sports-car-146185_1280.png",
                            "https://cdn.pixabay.com/photo/2015/05/15/14/46/bmw-768688_1280.jpg",
                            "https://cdn.pixabay.com/photo/2015/09/02/12/25/bmw-918407_1280.jpg",
                            "https://cdn.pixabay.com/photo/2016/05/06/16/32/car-1376190_1280.jpg",
                            "https://cdn.pixabay.com/photo/2015/07/11/23/13/mercedes-841465_1280.jpg",
                            "https://cdn.pixabay.com/photo/2014/01/04/13/34/taxi-238478_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/11/09/01/49/ferrari-458-spider-2932191_1280.jpg",
                            "https://cdn.pixabay.com/photo/2016/10/13/14/33/mercedes-1737908_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/05/16/11/20/mclaren-2317538_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/11/09/01/49/lamborghini-aventador-2932196_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/03/03/22/00/car-2115060_1280.jpg",
                            "https://cdn.pixabay.com/photo/2018/03/08/18/45/auto-3209449_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/05/23/20/07/mustang-2338356_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/03/27/00/13/car-2176944_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/11/24/20/39/nissan-gtr-2975651_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/03/27/00/14/car-2176960_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/11/24/20/39/lamborghini-huracan-2975652_1280.jpg",
                            "https://cdn.pixabay.com/photo/2016/10/13/14/33/mercedes-1737913_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/11/08/14/39/ferrari-f430-2930661_1280.jpg",
                            "https://cdn.pixabay.com/photo/2017/05/23/20/07/mustang-2338358_1280.jpg",
];
        if (error) {
          return <div>Error: {error.message}</div>;
        }else if (!isLoaded) {
          return <Spinner/>;
        } else {
          return (
            
            <div >
              <SEO
            title={this.state.items.length !==0 ? items[0].dealer.name: 'NA'}
            titleTemplate={'shopsmartautos'}
            titleSeparator={'-'}
            description='THE EASIEST WAY TO BUY YOUR NEW CAR-Must-Know Advice from our Experts '
            image={content[this.state.dealerImage]}
            pathname={href}
            siteLanguage={'en-US'}
            siteLocale={'en-US'}
            twitterUsername={'shopsmartautos'}
          />
          
            <header className="dealerCars " style={{'backgroundImage':'url('+content[this.state.dealerImage]+')'}}>
<div className="Overalays">
              <div className="container h-100">
                <div className="row h-100 align-items-center">

                    <div className="col-12 text-center">
                          <div className="row ">
                      <div className="col-lg-2  ">
                      <div className="card mb-2">
                      <div className="card-body">
                      <img height="100px" src="../media/dealericon.png"/>
                      </div>
                      </div>
                      </div>
                      <div className="col-lg-10 text-left">
                      <h3 className="heading">{this.state.dealerinfo.length !==0 ? this.state.dealerinfo.seller_name: 'NA'}</h3>
                      <p className="lead"> {this.state.dealerinfo.length !==0  ? this.state.dealerinfo.street :'NA'}</p>
                      <p className="lead"> {this.state.dealerinfo.length !==0  ? this.state.dealerinfo.city+', '+this.state.dealerinfo.state +', '+this.state.dealerinfo.zip:'NA'}  <Link className="text-white" to={"/dealer-show/?lat="+this.state.dealerinfo.latitude+'&long='+this.state.dealerinfo.longitude+'&name='+this.state.dealerinfo.seller_name+'&index='+this.state.dealeridindex}>Map & Direction</Link></p>
                      {this.state.dealerinfo.seller_phone !==undefined ? 
                      <p><a  className="text-white" href={"tel:"+this.state.dealerinfo.seller_phone}><i className="fa fa-phone"></i> Call {this.state.dealerinfo.seller_phone}</a></p> :('')}
                    </div>
                    </div>
                    </div>
                </div>
              </div>
              </div>
        </header>

          <Testdrive/>

    <div className="modal fade " id="dealertrack" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-notify modal-primary" role="document">
          {/* Content */}
          <div className="modal-content">
            {/* Header */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            {/* Body */}
            <div className="modal-body p-0">
              <div className="col-lg-12">
                <div className="row">
                {this.state.VehicleDataInfo!==null ?
                  <iframe onLoad={this.onloadIframe } className="holds-the-iframe" src={"https://suite.dtdrs.dealertrack.com/?accountId=7920414&dealerId=124360&vin="+this.state.VehicleDataInfo.vin+"&price="+this.state.price+"&qaMode=true"}  height="1131px" width="100%" frameborder="0" wmode="transparent">


                  </iframe>  
                :''}
                </div>
              </div>
          {/* Content */}
            </div>
          </div>
        </div>
      </div>
        <div className="col-lg-12">

          <h4 className="searchResults">Searching results showing ({this.state.currentcount.toLocaleString()}) of ({this.state.totalcount.toLocaleString()}) </h4>
        </div>
        <div className="clearfix"></div>
        <div className="container-fluid">
              <div className="row vechilePage newcarss">

            <div className="col-lg-3 ">
            
            
            </div>
            {items=='' ? (<Notfound/>):(
                  <div className="col-lg-9">
                                 <div className="row appendData">

                          {items.map(item => (
                  <div className="col-lg-9 loadmore ribbon"  key={item.id}>
                  {item.conditons ?(<span class="ribbon1"><span>{item.conditons}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)
                      
                  }
                  <div className="">
                   <div className="car-box-3 row customrow">
            
        <div className="col-lg-4  col-results">
          <Link target="_blank" to={"/car-details/?id="+item.id}>
            <div className="badge-overlay">
<span className="top-left badge oranges">New</span>
</div>

            <img className="d-block w-100" src={item.media!==undefined ? (item.media.photo_links[0]):('../media/default-car.png')} alt="car" />
          </Link>
          <ul className="social-icons-lists icons-background-square" style={{marginTop: '18px'}}>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://twitter.com/intent/tweet?url="+url+"/car-details/?id="+item.id} className="social-icon" target="_blank">
                  <span className="fa fa-twitter" style={{padding: '10px', fontSize: '16px', backgroundColor: '#4da7de'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://www.facebook.com/sharer.php?u="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-facebook" style={{padding: '10px', fontSize: '16px', backgroundColor: '#3e5b98'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-linkedin" style={{padding: '10px', fontSize: '16px', backgroundColor: '#0078b9'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://pinterest.com/pin/create/button/?url="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-pinterest" style={{padding: '10px', fontSize: '16px', backgroundColor: '#e02a20'}} />
                </a>
              </li>
            </ul>

<div className="row">
<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-1">
<img data-toggle="modal" data-target="#centralModalInfoDemo" onClick={() =>{this.handleVehicleData(item)}} width="120px"  src="../media/testdrive.png" className="p-2 clickable_button"/>


</div>
{this.state.dealerid!==null && this.state.dealerid==="1000381" ?
<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-1">
<img data-toggle="modal" data-target="#dealertrack" onClick={() =>{this.handleVehicleData(item)}} width="120px"  src="../media/finance.png" className="p-2 clickable_button"/>

</div>
:''}
</div>

 
        </div>
        <div className="col-lg-4 newclas card-body">
          <p className="vehicle-name" style={{marginTop: '15px!important', fontWeight: '600!important', fontSize: '20px!important', marginBottom: '3px!important'}}><span className="mini-hide">{item.heading}</span></p>
          <p className="miles-style" style={{fontWeight: '600!important', marginBottom: '0px'}}>{item.ref_miles ?(item.ref_miles+' Miles'):('')} </p>
          <ul className="descriptions">
            <li><strong> Body Type: </strong>{item.build.body_type}</li>
            <li><strong>Exterior Color: </strong>{item.exterior_color}</li>
            <li><strong>Doors: </strong>{item.build.doors}</li>
            <li><strong>Made-in: </strong>{item.build.made_in}</li>
            <li><strong>Vehicle Type: </strong>{item.build.vehicle_type}</li>
            <li><strong>Standard Seating: </strong>{item.build.std_seating}</li>
            <li>
              <p style={{fontSize: '11px', fontStyle: 'italic', fontWeight: 600, color: 'gray', lineHeight: '14px'}}>
                <i className="fa fa-map-marker"> </i>{item.dealer.name} Distance from you only <strong>{item.dist?(item.dist):('few')}</strong> Miles 
              </p>
            </li></ul></div>
        <div className="col-lg-4">
          <div className="inventory-right card-body">
            <ul className="descriptions">
              <li>
                <strong className="price_new">
        {
        item.price!=undefined
          ?
          '$'
          :
          item.prices !=null
          ?
          '$'
          :
           ''

          }
        {
                item.prices!=undefined
          ?
          item.prices.toLocaleString()
          :
          item.price !=null
          ?
          item.price.toLocaleString()
          :
           'NA'
        }
      </strong><small className="smalls">Listing price</small>
              </li> 
              <li>
                VIN # :{item.vin}
              </li>
              <li>
                {item.dealer.city},{item.dealer.state}
              </li>
              <li className="location-tag"> {item.ratting ? ("KBB.com Expert Ratings"):('')}
              {item.ratting ? (<img style={{height: '70px'}} data-src="/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" className=" ls-is-cached lazyloaded" src="https://shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
          </li>
          <li>
          {item.ratting ?('Overall Rating'):('')}
          </li>
              <li><small><div dangerouslySetInnerHTML={{ __html: item.ratting ?(item.ratting):('') }} />{item.value ?(<strong>{item.value}/5</strong>):('')}</small></li>
            </ul>
            <p><Link target="_blank" className="btn btn-primary" to={"/car-details/?id="+item.id}>More Details</Link></p>
            
          </div>
        </div>
      </div>
                  </div>
                  </div>
                  ))}
                    </div>
                    
               </div>
               )}
               <div className="col-lg-12 text-center">
               {this.state.currentcount<this.state.totalcount ? (
                  <button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
               ):('')}
               </div>
              </div>
               </div>
               <Footer/>
               </div>
          )
        }
      }
    }

export default dealerview;