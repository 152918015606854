import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React from 'react';
import $ from "jquery";

export class MapContainer extends  React.Component  {
  constructor(props) {
        super(props);
        this.state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    lat:null,
    long:null,
    directions:null,
    directionsData:null,
    items:null,
    Address:null,
    city:null,
    StateAddress:null,
    Zipcode:null,
    errors:[],
  }
      this.PostData = this.PostData.bind(this)
      this.calculateAndDisplayRoute = this.calculateAndDisplayRoute.bind(this)
      this.Form2Validations = this.Form2Validations.bind(this)
      }



      handleAddress= Address=>{
        var self=this;
        Address.persist();
        self.setState({Address:Address.target.value});

      }

      handlecity= city=>{
        var self=this;
        city.persist();
        self.setState({city:city.target.value});

      }

      handleStateAddress= StateAddress=>{
        var self=this;
        StateAddress.persist();
        self.setState({StateAddress:StateAddress.target.value});

      }

      handleZipcode= Zipcode=>{
        var self=this;
        Zipcode.persist();
        self.setState({Zipcode:Zipcode.target.value});

      }

      PostData(SendData)
      {
          var self = this;
          var CurrentCars=$('.loadmore').length;
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  SendData,
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse });
                    
                    self.setState({ isLoaded: true });

                  }
                );
      }
  componentDidMount (){
    var url_string = window.location.href;
         var url = new URL(url_string);
     var lat = url.searchParams.get("lat");
     var long = url.searchParams.get("long");

    var self=this;
var SendData={};
        self.setState({ isLoaded: false });
           SendData['dealer_view_show']='nearBy';
           SendData['lat']=lat;
           SendData['lng']=long;
        self.PostData(SendData);

const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        const map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: 17,
          center: { lat: parseFloat(lat), lng:parseFloat(long) },
        });
        new window.google.maps.Marker({
    position: { lat:parseFloat(lat), lng: parseFloat(long)},
    map,
  
  });
        directionsRenderer.setMap(map);
        const onChangeHandler = function () {
          if(self.Form2Validations())
          {
          self.calculateAndDisplayRoute(directionsService, directionsRenderer);
          }
        };
        
        document
          .getElementById("GetDirection")
          .addEventListener("click", onChangeHandler);
        
          $("#map").css("position","fixed !important");
          

}

Form2Validations=getValidate=>{
        var self=this;
        var StateAddress= self.state.StateAddress;
        var city= self.state.city;
       

        var Messages={};
        if(city==null || city=='')
        {
          Messages['city']='Please fill up the city!';
          self.setState({errors:Messages});
          return false;
        }else if(StateAddress==null || StateAddress=='')
        {
          Messages['StateAddress']='Please select the State!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }
      }

  calculateAndDisplayRoute(directionsService, directionsRenderer) {
    var self=this;
      var url_string = window.location.href;
         var url = new URL(url_string);
     var lat = url.searchParams.get("lat");
     var long = url.searchParams.get("long");
var start=lat+','+long;
var Address=self.state.Address;
var city=self.state.city;
var StateAddress=self.state.StateAddress;
var Zipcode=self.state.Zipcode;
var content='';
if(Address!==null || Address!=='')
{
    content+=Address;
}
if(city!==null || city!=='')
{
    content+=','+city;
}
if(StateAddress!==null || StateAddress!=='')
{
    content+=','+StateAddress;
}
if(Zipcode!==null || Zipcode!=='')
{
    content+=','+Zipcode;
}
        directionsService.route(
          {
            origin: {
              query:start,
            },
            destination: {
              query: content,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === "OK") {
              console.log(response);
                self.setState({directions:response.routes[0].legs[0].steps})
                self.setState({directionsData:response.routes[0].legs[0]})
              directionsRenderer.setDirections(response);
            } else {
              window.alert("Directions request failed due to " + status);
            }
          }
        );
      }
 
 onMapClicked = (ref, map, ev) => {
  var self= this;
   console.log(ev);
  const location = ev.latLng;
  const lng = ev.latLng.lng();
  const lat = ev.latLng.lat();
   self.setState({lat:lat,long:lng});
    $('#lati').val(lat);
    $('#lati').trigger('change');

    $('#longi').val(lng);
    $('#longi').trigger('change');
  }
  render() {
    return (<div>
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label className="p-0">Start Address <small>(optional)</small></label>
            <input type="text" onChange={this.handleAddress} name="satrt" className="form-control"/>
            <p style={{color:"red"}}>{this.state.errors['Address']}</p>
              
          </div>

          <div className="form-group">
            <label className="p-0">City</label>
            <input type="text" onChange={this.handlecity} name="city" className="form-control"/>
            <p style={{color:"red"}}>{this.state.errors['city']}</p>
             
          </div>

          <div className="form-group">
            <label className="p-0">State</label>
          <select onChange={this.handleStateAddress} className="form-control" name="state" id="viewServiceProviderMap_state" className="form-control" autocomplete="state" required="true">
    <option value="">-- Select State --</option>
    <option value="AL">AL - Alabama</option>
    <option value="AK">AK - Alaska</option>
    <option value="AS">AS - American Samoa</option>
    <option value="AZ">AZ - Arizona</option>
    <option value="AR">AR - Arkansas</option>
    <option value="CA">CA - California</option>
    <option value="CO">CO - Colorado</option>
    <option value="CT">CT - Connecticut</option>
    <option value="DE">DE - Delaware</option>
    <option value="DC">DC - District of Columbia</option>
    <option value="FM">FM - Federated States of Micronesia</option>
    <option value="FL">FL - Florida</option>
    <option value="GA">GA - Georgia</option>
    <option value="GU">GU - Guam</option>
    <option value="HI">HI - Hawaii</option>
    <option value="ID">ID - Idaho</option>
    <option value="IL">IL - Illinois</option>
    <option value="IN">IN - Indiana</option>
    <option value="IA">IA - Iowa</option>
    <option value="KS">KS - Kansas</option>
    <option value="KY">KY - Kentucky</option>
    <option value="LA">LA - Louisiana</option>
    <option value="ME">ME - Maine</option>
    <option value="MH">MH - Marshall Islands</option>
    <option value="MD">MD - Maryland</option>
    <option value="MA">MA - Massachusetts</option>
    <option value="MI">MI - Michigan</option>
    <option value="MN">MN - Minnesota</option>
    <option value="MS">MS - Mississippi</option>
    <option value="MO">MO - Missouri</option>
    <option value="MT">MT - Montana</option>
    <option value="NE">NE - Nebraska</option>
    <option value="NV">NV - Nevada</option>
    <option value="NH">NH - New Hampshire</option>
    <option value="NJ">NJ - New Jersey</option>
    <option value="NM">NM - New Mexico</option>
    <option value="NY">NY - New York</option>
    <option value="NC">NC - North Carolina</option>
    <option value="ND">ND - North Dakota</option>
    <option value="MP">MP - Northern Mariana Islands</option>
    <option value="OH">OH - Ohio</option>
    <option value="OK">OK - Oklahoma</option>
    <option value="OR">OR - Oregon</option>
    <option value="PW">PW - Palau</option>
    <option value="PA">PA - Pennsylvania</option>
    <option value="PR">PR - Puerto Rico</option>
    <option value="RI">RI - Rhode Island</option>
    <option value="SC">SC - South Carolina</option>
    <option value="SD">SD - South Dakota</option>
    <option value="TN">TN - Tennessee</option>
    <option value="TX">TX - Texas</option>
    <option value="UT">UT - Utah</option>
    <option value="VT">VT - Vermont</option>
    <option value="VI">VI - Virgin Islands</option>
    <option value="VA">VA - Virginia</option>
    <option value="WA">WA - Washington</option>
    <option value="WV">WV - West Virginia</option>
    <option value="WI">WI - Wisconsin</option>
    <option value="WY">WY - Wyoming</option>


</select>
<p style={{color:"red"}}>{this.state.errors['StateAddress']}</p>

          </div>
<div className="form-group">
            <label className="p-0">Zipcode <small>(optional)</small></label>
            <input type="text" name="satrt" onChange={this.handleZipcode} className="form-control" />
            <p style={{color:"red"}}>{this.state.errors['Zipcode']}</p>
             
          </div>
            <button className="btn btn-primary" id="GetDirection">Get Direction</button>
        </div>
      </div>
      <div className="card">
      <div className="card-body" id="map"></div>
      </div>

{this.state.directionsData !==null ? 
      <div className="card">
        <div className="card-body">
        <div className="">

          <h3><img src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EA%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A" className="adp-marker1"/>{this.state.directionsData!==null ? 

               this.state.directionsData.start_address :('')
          }</h3>
          <p><i className="fa fa-clock"></i> {this.state.directionsData!==null ? 

             this.state.directionsData.distance.text+' About '+this.state.directionsData.duration.text :('')
          }</p>
        </div>
          <table className="table table-striped">
            {this.state.directions !==null ?
              this.state.directions.map((index,i)=>(
                <tr><td>{i+1}</td>
              <td><div dangerouslySetInnerHTML={{ __html: index.instructions}}/></td>
                   </tr>
                )):''}
            
          </table>
          <div className="">

          <h3><img src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EB%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A" className="adp-marker2"/> {this.state.directionsData!==null ? 

              this.state.directionsData.end_address :('')
          }</h3>
        </div>
        </div>


      </div>
:('')}

</div>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ('AIzaSyA6PdXdukxnvnSogbgRRT1B4n185wip_8Y')
})(MapContainer)