import React from 'react';

import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import Notfound from './notfound';


class vinhistory extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      count: 2,
		      isLoaded: false,
		      items: [],
		      errorMessage:'',
		      rangeValue:0,
		      selectedModel:null,
		      selectedMake:null,
		      zipcode:null,
		      errors:[],
		      formValid:false,
		       totalcount:0,
		      currentcount:10,
		      historyvin:null,
		      vin:null,
		      vehiclename:null,

		    };
		    this.LoadMore = this.LoadMore.bind(this)
		    this.getDateTime = this.getDateTime.bind(this)
		  }



 componentDidMount (){
				var self = this;
				 var get=window.location.href;
				 var url = new URL(get);
				var vin = url.searchParams.get("vin");
				var name = url.searchParams.get("name");
	 				self.setState({vin:vin})
	 				self.setState({vehiclename:name})
				
			// Get User current position
			$.post(

                  "https://www.shopsmartautos.com/react_api.php",
                  {

                    "get_vin_history":"get_vin_history","vin":  vin ,'page':1},
                  function(data) {
                    var getResponse=JSON.parse(data);
                    self.setState({ historyvin: getResponse });
                    self.setState({ isLoaded: true });
                  
                  }
                  ); 
				}
			
			getDateTime(date)
			{
				var myDate = new Date(1000*date);
				return myDate.toLocaleString();
			}
		
			LoadMore()
			{
				
				var self=this;
				var count=self.state.count;
				var array=self.state.historyvin;
				var vin=self.state.vin;
				 $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
				$.post(

                  "https://www.shopsmartautos.com/react_api.php",
                  {

                    "get_vin_history":"get_vin_history","vin":  vin ,'page':count},
                  function(data) {
                    
                     var getResponse=JSON.parse(data);
                     console.log(getResponse.code);
                     if(getResponse.code!==422)
                     {
                     	self.setState({ isLoaded: true });
                    var children = array.concat(getResponse); 
                       self.setState({ historyvin: children });
                     self.setState({errorMessage:''})
                     $('#btn-one').html('Load More').removeClass('disabled');
                  count++;
                  self.setState({count:count})
                     }else
                     {
                     	self.setState({errorMessage:getResponse.message})
                     	  $('#btn-one').html('Load More').removeClass('disabled');
                     }
                    
                  }
                  ); 
				

			}
				
		 render() {
		    const { error, isLoaded, items } = this.state;
		 	var gethistory=this.state.historyvin;
  		const  posts  = this.state.posts;
  		
		    if (error) {
		      return <div>Error: {error.message}</div>;
		    } else if (!isLoaded) {
		      return <Spinner/>;
		    } else {
		      return (
		      	

		      	<div >
		      	
		      	<header className="vinhistry">
		      	<div className="Overalay">
  						<div className="container h-100">
	    					<div className="row h-100 align-items-center">
	      						<div className="col-12 text-center">
	        						<h1 className="heading">LISTING HISTORIES FROM LAST 6 YEARS, DEPICTING EVERY CHANGE</h1>
	        						<p className="lead">Must-Know Advice from our Experts</p>
	      						</div>
	    					</div>
  						</div>
  						</div>
				</header>
				 <div className="container z-depth-1 my-5 py-5 px-4 px-lg-0">
        {/* Section */}
        <section>
          <h3 className="font-weight-bold text-center dark-grey-text pb-2">{this.state.vehiclename} VIN History</h3>
          <hr className="w-header my-4" />
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <ol className="timeline">

               {Object.values(this.state.historyvin).map(keys=> (
                	

					<li className="timeline-element" key={keys.id}>
	                  <h5 className="font-weight-bold dark-grey-text mb-3">{keys.seller_name}</h5>
	                  <p className="grey-text font-small"><time dateTime="2017-02-08">{this.getDateTime(keys.status_date)}</time></p>

	                  <strong className="text-bold"> {keys.price ? '$'+keys.price.toLocaleString():('')}</strong>
	                  <p><i className="fa fa-road"></i> Miles {keys.miles ? keys.miles:'Not available'} </p>
	                  <p className="text-muted"><i className="fa fa-map-marker"></i> {keys.city+' ,'+keys.state+ ','+ keys.zip}</p>
	                </li>
                	
                ))}
              </ol>
            </div>
          </div>

        </section>

        {/* Section */}
      </div>
      <div className="col-12 text-center">
      <p style={{'color':'red'}}>{this.state.errorMessage}</p>
      <button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
		       		 </div>
		       		 <Footer/>
		       		 </div>

		       
		      )
		    }
		  }
		}

export default vinhistory;