import React from 'react';

import $ from 'jquery';
import '../App.css';
import './app.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import ImageGallery from 'react-image-gallery';
import SEO from 'react-seo-component';
import Newsletter from "./newsletter";
class privatepartycarsview extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      error: null,
		      isLoaded: false,
		      items: [],
          Vehicle_id:null,
          extras:null,
          photos:null, 
          imagess:[],
          build:null,
          userName:null,
          userEmail:null,
          userMesssage:null,
          userPhone:null,
          errors:[],
          vehicleData:[],
          MessageResponse:null,
          Realted:null,

		    };
         this.humanize = this.humanize.bind(this)
         this.FormValidations = this.FormValidations.bind(this)
         this.handleLeadSubmit = this.handleLeadSubmit.bind(this)
         this.create_UUID = this.create_UUID.bind(this)
         this.getCookie = this.getCookie.bind(this)
     
       
		  }
 state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };

   getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
   create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }
 
		 componentDidMount() {
		 	   var self = this;
         var url_string = window.location.href;
         var url = new URL(url_string);
         var Vehicle_id = url.searchParams.get("id");
         var zip = url.searchParams.get("zip");
       
		   $.post(
                "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"privatecarsbyids":"privatecarsbyids",'Vehicle_id':Vehicle_id,zip:zip
   				         },
                  function(data) {
                    var getResponse=JSON.parse(data);
                    var arrData={};
                    self.setState({ items: getResponse });
                    arrData['vin']=getResponse.vin;
                    arrData['zip']=zip;
                    arrData['DealerId']=getResponse.dealer.id;
                    arrData['price']=getResponse.prices ? getResponse.prices : getResponse.price ;
                    self.setState({ vehicleData: arrData });
                    self.setState({ extras: getResponse.extra  });
                    self.setState({ build: getResponse.build });
                    self.setState({ photos: getResponse.photos });
                    var cookie=self.getCookie('ssa_cookie');
                      if(cookie==undefined)
                          {
                          var getuiid=self.create_UUID();
                          document.cookie='ssa_cookie='+getuiid;
                          cookie=self.getCookie('ssa_cookie');

                          }else
                          {
                             cookie=self.getCookie('ssa_cookie');
                          }                                                                     

                          var refrer=document.referrer;
  
                    $.post("https://www.shopsmartautos.com/testtraffic.php",
                          {"inventoryview":'k',
                          'url':window.location.href,
                          "cid":cookie,
                          'urlcoming':refrer,
                          'inventorytype':'pp',
                          'dealerid':self.state.vehicleData.DealerId,
                          'vin':self.state.vehicleData.vin},
                          function (html) {
                          }
                        );
                    self.setState({ isLoaded: true });
                  }
               );

               // get Related Products
           
               
		  }

              

                humanize(str) {
                var i, frags = str.split('_');
                for (i=0; i<frags.length; i++) {
                  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
                }
                // form values update into state
                handleNameChaneg= getName=>{
                  this.setState({userName:getName.target.value})
                }
                handleEmailChaneg= getEmail=>{
                  this.setState({userEmail:getEmail.target.value})
                }
                handlePhoneChaneg= getPhone=>{
                  this.setState({userPhone:getPhone.target.value})
                  
                }
                handleMessageChaneg= getMessage=>{
                  this.setState({userMessage:getMessage.target.value})
                }
               FormValidations=getValidate=>{
                               var email = this.state.userEmail;
                               var userName = this.state.userName;
                               var userPhone = this.state.userPhone;
                               var userMessage = this.state.userMessage;
                                var errors = {};
                                var isValid = true;
                            
                                if (!userName) {
                                  isValid = false;
                                  errors["name"] = "Please enter your name.";
                                }
                            
                                if (!email) {
                                  isValid = false;
                                  errors["email"] = "Please enter your email Address.";
                                }
                            
                                   
                                if (!userPhone) {
                                  isValid = false;
                                  errors["phone"] = "Please enter your phone number.";
                                }
                            
                                
                            
                                if (!userMessage) {
                                  isValid = false;
                                  errors["message"] = "Please enter your Message.";
                                }
                            
                                this.setState({
                                  errors: errors
                                });
                            
                                return isValid;
                              
                            }

                  handleLeadSubmit() {              
                  if(this.FormValidations())
                  {
                      var email = this.state.userEmail;
                      var self=this;
                      var userName = this.state.userName;
                      var userPhone = this.state.userPhone;
                      var userMessage = this.state.userMessage;
                      var vehicleData = this.state.vehicleData;
                      vehicleData['email']=email;
                      vehicleData['userName']=userName;
                      vehicleData['userPhone']=userPhone;
                      vehicleData['userMessage']=userMessage;
                      vehicleData['vin_marketcheck']='getData';
                   
                        $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                      vehicleData,
                      function(data) {
                      var getResponse=JSON.parse(data);
                        self.setState({ MessageResponse: '<div class="alert alert-success">'+getResponse.Message+'</div>' });
                        self.setState({ userName: '' });
                        self.setState({ userPhone: '' });
                        self.setState({ userMessage:'' });
                        self.setState({ userEmail: '' });
                        self.setState({ isLoaded: true });

                                    }
                            );
                    }
                  }

		 render() {
		 	 const { selectedOption } = this.state;
		    const { error, isLoaded, items,Extras } = this.state;
        var extras =this.state.extras;
        var photos =this.state.photos;
        var build =this.state.build;
        var MessageResponse =this.state.MessageResponse;
      
       

		    if (error) {
		      return <div>Error: {error.message}</div>;
		    } else if (!isLoaded) {
		      return <Spinner/>;
		    } else {

          if($(window).innerWidth() <= 990) {
        
          $(function(){
              $("#includedContent1").load("https://staging.fastor.in/chatbot/bot2/"); 
                   });
        
        }else 
        {
          $(function(){
            
            $("#includedContent").load("https://staging.fastor.in/chatbot/bot2/"); 
                  });
        }
         
           var getkeys=Object.keys(build);
           var getValues=Object.values(build);
           var getErros= this.state.errors;
           var getDealerKeys=Object.keys(this.state.items.dealer);
           var getDealerValues=Object.values(this.state.items.dealer);
		      return (
		      	<div >
            <SEO
            title={this.state.items.heading}
            titvaremplate={'shopsmartautos'}
            titleSeparator={'-'}
            description={this.state.items.prices!==null ? ('$'+this.state.items.prices.toLocaleString()):('$'+this.state.items.price.toLocaleString())}
            image={photos[0].thumbnail}
            pathname={'https://shopsmartautos.com/'}
            siteLanguage={'en-US'}
            siteLocale={'en-US'}
            twitterUsername={'shopsmartautos'}
/>
				<div className="text-center h2 my-5 pt-4"></div>
				<div className="container-fluid">

			        <div className="row vechilePage">
			        		<div className="col-lg-8">

			        		          		 <div className="">  
			             		            <ImageGallery items={photos} />

 <div className="col-lg-12 p-0 ribbon d-lg-none">

                  {this.state.items.conditons ?(<span class="ribbon1"><span>{this.state.items.conditons}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)
                      
                  }
            <div className="card">

            <div id="includedContent1" className="p-5"></div>
              <div className="card-body pb-0 searchbar">
              <div className="price-box-3"><h2 className="newprice card-title dark-grey-text text-center lighten-4 py-2">${this.state.items.prices ? (this.state.items.prices.toLocaleString()):(this.state.items.price.toLocaleString())}<small className="smalls">Listing price</small></h2></div>
              <h2 className="card-title dark-grey-text text-center lighten-4 py-2">{this.state.items.heading}</h2>
              <div className>
              <h3 className="h3custom">
              {this.state.items.ratting ? ('KBB.com Expert Ratings'):('')}
              {this.state.items.ratting ? (<img style={{height: '70px', width: 'auto'}}  className=" ls-is-cached lazyloaded" src="https://www.shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
                 
                 <p /><small><div dangerouslySetInnerHTML={{ __html: this.state.items.ratting ?(this.state.items.ratting):('') }} />{this.state.items.value ?(<strong>{this.state.items.value}/5</strong>):('')}</small>                 
                  <div className="m-border" />
              </h3>
              <h3 className="sidebar-title">Refine Your Search</h3>
              <Link to={"/vin-history/?vin="+this.state.items.vin+"&name="+this.state.items.heading}>Checkout the History</Link>
              <div className="s-border" />
              <div className="m-border" />
              <section className="contact-section my-5">
        {/* Form with header */}
        <div className="">
          {/* Grid row */}
          <div className="row">
            {/* Grid column */}
            <div className="col-lg-12">
              <div className="card-body form">
                {/* Header */}
                <h3 className="mt-4"><i className="fas fa-envelope pr-2" />Write to us:</h3>
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                     <label htmlFor="form-contact-name" className="p-0">Your name</label>
                      <input type="text" id="form-contact-name" value={this.state.userName} onChange={this.handleNameChaneg} className="form-control" placeholder=""/>
                        <p style={{color:'red'}}>{getErros['name']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                    <label htmlFor="form-contact-email" className="p-0">Your email</label>
                      <input type="email" id="form-contact-email" value={this.state.userEmail}  onChange={this.handleEmailChaneg} className="form-control" />
                      <p style={{color:'red'}}>{getErros['email']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                    <label htmlFor="form-contact-phone" className="p-0">Your phone</label>
                       <input type="text" className="form-control" id="form-contact-phone"  name="phno" value={this.state.userPhone} onChange={this.handlePhoneChaneg}/>
                      <p style={{color:'red'}}>{getErros['phone']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-12">
                    <div className="form-group mb-0">
                    <label htmlFor="form-contact-message" className="p-0">Your message</label>
                      <textarea type="text" id="form-contact-message" className="form-control md-textarea" rows={3} value={this.state.userMessage} defaultValue={""} onChange={this.handleMessageChaneg}/>
                       <p style={{color:'red'}}>{getErros['message']}</p>
                      <a onClick={this.handleLeadSubmit}className="btn btn-md blue waves-effect waves-light">
                       Submit
                      </a>
                    </div>
                   
                  </div>

                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </div>
               <div dangerouslySetInnerHTML={{ __html:MessageResponse}}/>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
        {/* Form with header */}
      </section>
              <h3 className="my-4 pb-2 text-center">Dealer information</h3>
                <ul className="text-lg-left list-unstyled ml-4">
                 

 {
  Object.keys(getDealerKeys).map((keyjji)=>(
  getDealerKeys[keyjji]!='id' && getDealerKeys[keyjji]!='latitude' && getDealerKeys[keyjji]!='website' && getDealerKeys[keyjji]!='longitude'   ? ( <li><span><strong>{ this.humanize(getDealerKeys[keyjji])}</strong></span>{getDealerValues[keyjji]}</li>):('') 
   
    ))
        }


                 
                </ul>
                </div>
             




              <div className="widget question">
              <h5 className="sidebar-title">Get a Question?</h5>
              <div className="s-border" />
                 <div className="m-border" />
                 <h5 className="text-center">
                         <i className="fa fa-envelope-o" aria-hidden="true">
                         <a href="mailto:info@shopsmartautos.com"> info@shopsmartautos.com </a>
                         </i>
                 </h5>
                   
                 </div>
              </div>

            </div>
              <Newsletter/>
            </div>





                                   <div className="card searchbar">
                                   <h4 className="text-center">Technical Details</h4>
                                   
                                    <ul className="row card-body">
  <li className="col-lg-6"><span><strong>VIN</strong></span>{this.state.items.vin}</li>
 {
  Object.keys(getkeys).map((keyjj)=>(
    <li className="col-lg-6"><span><strong>{ this.humanize(getkeys[keyjj])}</strong></span>{getValues[keyjj]}</li>
    ))
        }
 </ul>
</div>




			             		    	<div className="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                            {extras != undefined ? (
         Object.keys(extras).map((key)=>(

        <div className="card">
          {/* Card header */}
          <div className="card-header" role="tab" id={key[0]+"h"}>

            <a data-toggle="collapse" data-parent="#accordionEx" href={"#"+key[0]} aria-expanded="false" aria-controls="collapseOne1" className="collapsed">
              <h5 className="mb-0">
              {this.humanize(key)}
                <i className="fas fa-angle-down rotate-icon" />
              </h5>
            </a>
          </div>
          {/* Card body */}
         
          <div id={key[0]} className="collapse" role="tabpanel" aria-labelledby={key[0]+"h"} data-parent="#accordionEx" style={{}}>
            <div className="card-body">
           <div className=" row">
          {typeof extras[key]=='object' ?(
            
          Object.keys(extras[key]).map((keyss)=>(<li className="col-lg-4"><i className="fa fa-check"></i> {extras[key][keyss]}</li>))
            
          ):(<div className="col-lg-8">{extras[key]}</div>)}
               </div>
               </div>
          </div>
         
        </div>
       
     
))
):('')}
          </div>
          <form action="/value-my-car" method="get">
          <div className="col-lg-12 card searchbar">
    <div className="row">
      <div className="col-lg-4">
      <div className="row">
        <div className="col-lg-2">
          <img
            id="kbb-leaddriver-search-logo"
            alt="KBB Logo"
            data-src=" https://tradeinadvisor.kbb.com/images/kbb-logo.png"
            className=" lazyloaded"
            src=" https://tradeinadvisor.kbb.com/images/kbb-logo.png"
          />
          </div>
          <div className="col-lg-10 text-center">
            <div id="kbb-leaddriver-search-title " className="worth">
              What's Your Trade‑In Worth?
            </div>
            <div id="kbb-leaddriver-search-subtitle">
              Get your Kelley Blue Book® Trade‑In Value.
            </div>
          
        </div>
      </div>
      </div>
      <div className="col-lg-8">
        <div className="form-inline">
          <label className="mr-sm-2">ENTER YOUR VIN NUMBER</label>
          <input
            type="text"
            className="form-control"
            name="vin"
            id="VIN"
            placeholder="Search By VIN"
          />
          
          <button className="btn btn-primary adsclass seachByVin" id="btn-one" type="submit">
            Search Now
          </button>
        </div>
        <div className="col-lg-12"><p style={{'color':'red'}}className="error_get"></p></div>
    </div>
    </div>
    </div>
    </form>
       <div className="divider-new">
        <h3 className="h3-responsive font-weight-bold blue-text mx-3">Vehicle Pricing</h3>
      </div>
      	<div className="card  mb-3 text-center hoverable">
        <div className="card-body">
          <div className="row">
            <div className="col-md-5 mx-3 my-3">
              <div className="view overlay rgba-white-slight cust12">
               <p>Shopsmartautos is displaying the Kelley Blue Book<span>®</span> Price Advisor to help shoppers better understand vehicle pricing.
      </p>
                  <div className="text-size-100 margin-bottom-2"><strong>
    </strong>The dealer’s listed price is in the lower half of the Kelley Blue Book® Fair Market Range or in the White Zone. This is based on cars in your area similar to the one you want. The dealer provides the vehicle information and listing price, which may not include all fees.
    </div>
              </div>
            </div>
            <div className="col-md-6 ml-xl-3 ml-lg-0 ml-md-3 mt-3">
            <div data-cmp="section" className="col-lg-12" id="kbbPriceAdvisorSection">
      <div className="">
      <iframe data-cmp="priceAdvisor" title="priceAdvisor-WBY1Z2C59FV555455" width="300" height="375" frameborder="0" scrolling="no" src={"https://pauc.syndication.kbb.com/priceadvisorusedcar/fairpurchaseprice?apikey=e9068180-03c3-4b3f-99c5-81adc74aa49d&vehicleId="+this.state.items.vehicleId+"&zipcode="+this.state.vehicleData.zip} className="margin-horizontal-auto" id="ae_iframe_ny03536g">
      </iframe>
      </div>
      </div>
               
            </div>
          </div>
        </div>
      </div>

			            	</div>

			         </div>

			         <div className="col-lg-4 ribbon my-content">

                  {this.state.items.conditons ?(<span class="ribbon1"><span>{this.state.items.conditons}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)
                      
                  }
						<div className="card">

            <div id="includedContent" className="p-5"></div>
							<div className="card-body pb-0 searchbar">
							<div className="price-box-3"><h2 className="newprice card-title dark-grey-text text-center lighten-4 py-2">${this.state.items.prices ? (this.state.items.prices.toLocaleString()):(this.state.items.price.toLocaleString())}<small className="smalls">Listing price</small></h2></div>
							<h2 className="card-title dark-grey-text text-center lighten-4 py-2">{this.state.items.heading}</h2>
							<div className>
							<h3 className="h3custom">
              {this.state.items.ratting ? ('KBB.com Expert Ratings'):('')}
              {this.state.items.ratting ? (<img style={{height: '70px', width: 'auto'}}  className=" ls-is-cached lazyloaded" src="https://www.shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
							   
							   <p /><small><div dangerouslySetInnerHTML={{ __html: this.state.items.ratting ?(this.state.items.ratting):('') }} />{this.state.items.value ?(<strong>{this.state.items.value}/5</strong>):('')}</small>							   
                  <div className="m-border" />
							</h3>
							<h3 className="sidebar-title">Refine Your Search</h3>
              <Link to={"/vin-history/?vin="+this.state.items.vin+"&name="+this.state.items.heading}>Checkout the History</Link>
							<div className="s-border" />
							<div className="m-border" />
              <section className="contact-section my-5">
        {/* Form with header */}
        <div className="">
          {/* Grid row */}
          <div className="row">
            {/* Grid column */}
            <div className="col-lg-12">
              <div className="card-body form">
                {/* Header */}
                
                <h3 className="mt-4"><i className="fas fa-envelope pr-2" />Write to us:</h3>
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                     <label htmlFor="form-contact-name" className="p-0">Your name</label>
                      <input type="text" id="form-contact-name" value={this.state.userName} onChange={this.handleNameChaneg} className="form-control" placeholder=""/>
                        <p style={{color:'red'}}>{getErros['name']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                    <label htmlFor="form-contact-email" className="p-0">Your email</label>
                      <input type="email" id="form-contact-email" value={this.state.userEmail}  onChange={this.handleEmailChaneg} className="form-control" />
                      <p style={{color:'red'}}>{getErros['email']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                    <label htmlFor="form-contact-phone" className="p-0">Your phone</label>
                       <input type="text" className="form-control" id="form-contact-phone"  name="phno" value={this.state.userPhone} onChange={this.handlePhoneChaneg}/>
                      <p style={{color:'red'}}>{getErros['phone']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-12">
                    <div className="form-group mb-0">
                    <label htmlFor="form-contact-message" className="p-0">Your message</label>
                      <textarea type="text" id="form-contact-message" className="form-control md-textarea" rows={3} value={this.state.userMessage} defaultValue={""} onChange={this.handleMessageChaneg}/>
                       <p style={{color:'red'}}>{getErros['message']}</p>
                      <a onClick={this.handleLeadSubmit}className="btn btn-md blue waves-effect waves-light">
                       Submit
                      </a>
                    </div>
                   
                  </div>

                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </div>
               <div dangerouslySetInnerHTML={{ __html:MessageResponse}}/>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
        {/* Form with header */}
      </section>
							<h3 className="my-4 pb-2 text-center">Dealer information</h3>
                <ul className="text-lg-left list-unstyled ml-4">
                 

 {
  Object.keys(getDealerKeys).map((keyjji)=>(
  getDealerKeys[keyjji]!='id' && getDealerKeys[keyjji]!='latitude' && getDealerKeys[keyjji]!='website' && getDealerKeys[keyjji]!='longitude'   ? ( <li><span><strong>{ this.humanize(getDealerKeys[keyjji])}</strong></span>{getDealerValues[keyjji]}</li>):('') 
   
    ))
        }


                 
                </ul>
                </div>
             




							<div className="widget question">
							<h5 className="sidebar-title">Get a Question?</h5>
							<div className="s-border" />
							   <div className="m-border" />
							   <h5 className="text-center">
								         <i className="fa fa-envelope-o" aria-hidden="true">
								         <a href="mailto:info@shopsmartautos.com"> info@shopsmartautos.com </a>
								         </i>
							   </h5>
							     
							   </div>
							</div>

						</div>
              <Newsletter/>
						</div>

			        </div>

              
			        
		       	</div>
		       <Footer/>
		     </div>
		       
		      )
		    }
		  }
		}

export default privatepartycarsview;