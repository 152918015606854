import React from 'react';
import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Notfound from './notfound';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import SEO from 'react-seo-component';
import Newsletter from "./newsletter";

import Testdrive from "./testdrive"
class privatepartycars extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      error: null,
		      isLoaded: false,
		      items: [],
		      vehicleClass:'new',
		      rangeValue:0,
		      selectedModel:null,
		      selectedMake:null,
		      zipcode:null,
		      errors:[],
		      formValid:false,
		      MaxPrice:100,
		      Makesearch:null,
		      totalcount:0,
		      currentcount:0,
		      FirstName:null,
		      LastName:null,
		      email:null,
		      PhoneNumber:null,
		      Comment:null,
		      TestDriveDate:null,
		      MessageStatus:'',
		      TestDriveTime:null,
		      TestDriveTc:1,
		      VehicleDataInfo:null,


		    };
		    this.LoadMore = this.LoadMore.bind(this)
		    this.PostData = this.PostData.bind(this)
		  }

		  PostData(getStringPara)
		  {
		  	 	var self = this;
		  	 	var CurrentCars=$('.loadmore').length;
		  		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"privatepartycars":"true",'getStringPara':getStringPara
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.listings });
                    self.setState({ totalcount: getResponse.num_found });
                    self.setState({ currentcount:getResponse.listings.length});
                    
                    self.setState({ isLoaded: true });

                  }
               	);
					// load make according to search bar selection
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {

                  	"LoadMake":"LoadMake",vehicleClass:self.state.vehicleClass
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Makes: getResponse });
                      
                  }
               ); 
		  }
		handlesubmitTc(){
				var self=this;
				var FirstName= self.state.FirstName;
				var LastName= self.state.LastName;
				var email= self.state.email;
				var PhoneNumber= self.state.PhoneNumber;
				var Comment= self.state.Comment;
				var TestDriveDate= self.state.TestDriveDate;
				var TestDriveTime= self.state.TestDriveTime;
				var TestDriveTc= self.state.TestDriveTc;
				var VehicleDataInfo= self.state.VehicleDataInfo;
				if(self.Form2Validations())
				{
					


					 var getStringPara={'FirstName':FirstName,'LastName':LastName,'email':email,'PhoneNumber':PhoneNumber,'Comment':Comment,'TestDriveDate':TestDriveDate,'TestDriveTime':TestDriveTime,'TestDriveTc':TestDriveTc,vin:VehicleDataInfo.vin,mc_id:VehicleDataInfo.id,dealer_id:VehicleDataInfo.dealer.id};
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"TestDriveRequest":"true",getStringPara:getStringPara
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  
                  if(getResponse.status)
                  {
                  	self.setState({MessageStatus:'<div class="alert alert-success border-green"><i class="fa fa-check"></i> Thanks for the request</div>'})

                  }else
                  {
                  	self.setState({MessageStatus:'<div class="alert alert-danger border-red">Try again..</div>'})

                  }
                  
                  }
               	);
				}
		      
			}


			
			Form2Validations=getValidate=>{
				var self=this;
				var FirstName= self.state.FirstName;
				var LastName= self.state.LastName;
				var email= self.state.email;
				var PhoneNumber= self.state.PhoneNumber;
				var Comment= self.state.Comment;
				var TestDriveDate= self.state.TestDriveDate;
				var TestDriveTime= self.state.TestDriveTime;
				var TestDriveTc= self.state.TestDriveTc;

				var Messages={};
				if(FirstName==null)
				{
					Messages['FirstName']='Please select the  First Name!';
					self.setState({errors:Messages});
					return false;
				}else if(LastName==null)
				{
					Messages['LastName']='Please select the Last Name!';
					self.setState({errors:Messages});
					return false;
				}else if(TestDriveDate==null)
				{
					Messages['TestDriveDate']='Please select the Test Drive Date!';
					self.setState({errors:Messages});
					return false;
				}else if(TestDriveTime==null)
				{
					Messages['TestDriveDate']='Please select the Test Drive Time!';
					self.setState({errors:Messages});
					return false;
				}else if(TestDriveTc==null)
				{
					Messages['TestDriveTc']='Please select the Test Drive Term & condition!';
					self.setState({errors:Messages});
					return false;
				}else if(Comment==null)
				{
					Messages['Comment']='Please select the Comment!';
					self.setState({errors:Messages});
					return false;
				}else if(PhoneNumber==null)
				{
					Messages['PhoneNumber']='Please select the Phone Number!';
					self.setState({errors:Messages});
					return false;
				}else if(email==null)
				{
					Messages['email']='Please fill up the email!';
					self.setState({errors:Messages});
					return false;
				}else
				{
					self.setState({errors:''});
					return true;
				}

				
			}
	// get model on user make selection end

	// get model on user make selection start

	/* test drive modl*/


	handleFirstName= FirstName=>{
                  this.setState({FirstName:FirstName.target.value})
                }

    handleLastName= LastName=>{
                  this.setState({LastName:LastName.target.value})
                }
    handleemail= email=>{
                  this.setState({email:email.target.value})
                }
    handlePhoneNumber= PhoneNumber=>{
                  this.setState({PhoneNumber:PhoneNumber.target.value})
                }
    handleComment= Comment=>{
                  this.setState({Comment:Comment.target.value})
                }
    handleTestDriveDate= TestDriveDate=>{
                  this.setState({TestDriveDate:TestDriveDate.target.value})
                }
	handleTestDriveTime= TestDriveTime=>{
                  this.setState({TestDriveTime:TestDriveTime.target.value})
                }
    handleTC= tc=>{
                  this.setState({TestDriveTc:tc.target.value})
                }

    handleVehicleData= handleVehicleData=>{
    			  var self=this;
                  self.setState({VehicleDataInfo:handleVehicleData})
                  localStorage.setItem("VehicleDataInfo", JSON.stringify(handleVehicleData));
                  
                }
	
 	componentDidMount (){

		 var self = this;
		 navigator.geolocation.getCurrentPosition(function(position) {
		 var url_string = window.location.href;
         var url = new URL(url_string);
         var car_type = url.searchParams.get("car_type");
         var getprice = url.searchParams.get("getprice");
         var Makesearch = url.searchParams.get("Makesearch");
		 	if (car_type!=null) {
		 		// Load data according searchbar home page
				var Make = url.searchParams.get("make");
         		var model = url.searchParams.get("model");
         		var zipcode = url.searchParams.get("zipcode");
				self.setState({zipcode:zipcode});
				self.setState({selectedMake:Make});
				self.setState({selectedModel:model});
				self.setState({rangeValue:100});
				var getStringPara={'make':Make,'model':model,'zip':zipcode,'radius':100};
				self.PostData(getStringPara);

		 	}else if (getprice!=null) {


				var MaxPrice = url.searchParams.get("MaxPrice");
				self.setState({MaxPrice:MaxPrice});
				var getStringPara={
					'price_range':MaxPrice,
					latitude:position.coords.latitude,
					longitude: position.coords.longitude
					,'radius':100
					};
				self.PostData(getStringPara);


		 	}else if (Makesearch!=null) {


				var Makesearch = url.searchParams.get("Makesearch");
				self.setState({Makesearch:Makesearch});
				var getStringPara={
					'make':Makesearch,
					latitude:position.coords.latitude,
					longitude: position.coords.longitude,
					'radius':100

					};
				self.PostData(getStringPara);


		 	}else
		 	{

			var SendData={};
			self.setState({ isLoaded: false });
		
			     SendData['nearBy']='nearBy';
			     SendData['latitude']=position.coords.latitude;
			     SendData['longitude']=position.coords.longitude;
			     SendData['radius']='100';
				self.PostData(SendData);
			}
			self.setState({latitude: position.coords.latitude,longitude: position.coords.longitude,});
			}, (error)=>{
				// On denied position
				var self = this;
				var url_string = window.location.href;
         var url = new URL(url_string);
         var getprice = url.searchParams.get("getprice");
         var Makesearch = url.searchParams.get("Makesearch");
		   	 if (getprice!=null) {


				var MaxPrice = url.searchParams.get("MaxPrice");
				self.setState({MaxPrice:MaxPrice});
				var getStringPara={
					'price_range':MaxPrice,
					latitude:40.94623,
					longitude: -73.069777,
					'radius':100
					};
				self.PostData(getStringPara);


		 	}else if (Makesearch!=null) {


				var Makesearch = url.searchParams.get("Makesearch");
				self.setState({Makesearch:Makesearch});
				var getStringPara={
					'make':Makesearch,
					latitude:40.94623,
					longitude: -73.069777,
					'radius':100
					};
				self.PostData(getStringPara);


		 	}else
		 	{

			var SendData={};
			self.setState({ isLoaded: false });
		
			     SendData['nearBy']='nearBy';
			     SendData['latitude']=40.94623;
			     SendData['longitude']=-73.069777;
			    
				 SendData['radius']='100';
			
				self.PostData(SendData);
			}

			this.setState({latitude:40.94623,longitude:-73.069777,});
			});

		}
	
	// get model on user make selection start
	handleChange = selectedOption => {
				var self = this;
				 $('#homesearchbutton').html('<span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span><span className="text-white">Models Loading</span>').addClass('disabled');
		   		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"LoadModel":"LoadModel",latitude: self.state.latitude,longitude: self.state.longitude,make:selectedOption.value,vehicleClass:self.state.vehicleClass
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Models: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedMake: selectedOption.value });
                    $('#homesearchbutton').html('Search now').removeClass('disabled');
                  }
               ); 

			}

			handleRange= handleGetRange=>{
				var self=this;
				handleGetRange.persist();
				self.setState({rangeValue:handleGetRange.target.value});

			}
		
			LoadMore()
			{
				var data_query={};
				var self=this;
				var CurrentCars=$('.loadmore').length;
			
				var array=self.state.items;
				 if(self.state.rangeValue!=0 && self.state.zipcode!=null)
				 {
				 	data_query['radius']=self.state.rangeValue;
				 	data_query['zip']=self.state.zipcode;
					data_query['model']=self.state.selectedModel;
					data_query['make']=self.state.selectedMake;

				 }else
				 {			var url_string = window.location.href;
					        var url = new URL(url_string);
					        var getprice = url.searchParams.get("getprice");
					        var Makesearch = url.searchParams.get("Makesearch");

				 			if (getprice=='true') {

							var MaxPrice = url.searchParams.get("MaxPrice");
							self.setState({MaxPrice:MaxPrice});
							 data_query={
													'price_range':MaxPrice,
													'radius':100,
													'latitude':self.state.latitude,
													'longitude':self.state.longitude
							};
							
						 	}else if (Makesearch!=null) {

							var Makesearch = url.searchParams.get("Makesearch");
							self.setState({Makesearch:Makesearch});
							 data_query={
													'make':Makesearch,
													'radius':100,
													'latitude':self.state.latitude,
													'longitude':self.state.longitude

									};

						 	}else
						 	{
						 		 data_query={
													
													'radius':100,
													'latitude':self.state.latitude,
													'longitude':self.state.longitude

									};
						 	}
							

				 }
				 
				 $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
				$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {data_query:data_query,startpoint:CurrentCars,'Partycarsloadmore':'Partycarsloadmore'},
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    		 var children = array.concat(getResponse.listings); 
                    		 var CurrentCarsTotal=self.state.currentcount+getResponse.listings.length;
                      self.setState({ items: children });
                      self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: CurrentCarsTotal });
                     $('#btn-one').html('Load More').removeClass('disabled');
                  }
               );
				

			}
			handleModelChange= getValue=>{
				var self=this;
					self.setState({selectedModel:getValue.value});
			}

			handleZipcode= GetZipCode=>{
				var self=this;
				GetZipCode.persist();
				self.setState({zipcode:GetZipCode.target.value});

			}
			handleSearchMakeModel=MakeModelZipSearch=>{
				MakeModelZipSearch.persist();
				var self=this;
				var Make=self.state.selectedMake;
				var Model=self.state.selectedModel;
				var zipcode=self.state.zipcode;
				var rangeValue=self.state.rangeValue;
				var CurrentCars=$('.loadmore').length;
				if(self.FormValidations())
				{
					  self.setState({ isLoaded: false });
					 var getStringPara={'make':Make,'model':Model,'zip':zipcode,'radius':rangeValue};
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"privatepartycars":"true",getStringPara:getStringPara
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.listings });
                    self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: getResponse.listings.length });
                     
                    self.setState({ isLoaded: true });

                  }
               	); 
				}

			}
			FormValidations=getValidate=>{
				var self=this;
				var Make=self.state.selectedMake;
				var Model=self.state.selectedModel;
				var zipcode=self.state.zipcode;
				var rangeValue=self.state.rangeValue;
				var Messages={};
				if(Make==null)
				{
					Messages['make']='Please select the  make!';
					self.setState({errors:Messages});
					return false;
				}else if(Model==null)
				{
					Messages['model']='Please select the Model!';
					self.setState({errors:Messages});
					return false;
				}else if(zipcode==null)
				{
					Messages['zipcode']='Please fill up the Zipcode!';
					self.setState({errors:Messages});
					return false;
				}else
				{
					self.setState({errors:''});
					return true;
				}

				
			}
	// get model on user make selection end

	// get model on user make selection start
	
		 render() {
		    const { error, isLoaded, items } = this.state;
		 	
  		const  Makes  = this.state.Makes;
  		const  Years  = this.state.Years;
  		const  selectedMake  = this.state.selectedMake;
  		const  Models  = this.state.Models;
  		const { selectedOption } = this.state;
  	 	const { selectedModel } = this.state;
  	 	const { selectedYear } = this.state;
  	 	const url=window.location.host;
  	 	const href=window.location.href;

  	 
		    if (error) {
		      return <div>Error: {error.message}</div>;
		    }else if (!isLoaded) {
		      return <Spinner/>;
		    } else {
		      return (
		      	
		      	<div >
		      		<SEO
  title={'Private Part Cars-shopsmartautos'}
  titvaremplate={'shopsmartautos'}
  titleSeparator={'-'}
  description='THE EASIEST WAY TO BUY YOUR NEW CAR-Must-Know Advice from our Experts '
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
		      	<header className="privatecars">
		      	<div className="Overalay">
  						<div className="container h-100">
	    					<div className="row h-100 align-items-center">
	      						<div className="col-12 text-center">
	        						<h1 className="heading">THE EASIEST WAY TO BUY YOUR PRIVATE CARS</h1>
	        						
	      						</div>
	    					</div>
  						</div>
  						</div>
				</header>

  				<Testdrive/>
				<div className="container"><Newsletter/></div>
				<div className="col-lg-12">
						
					<h4 className="searchResults">Searching results showing ({this.state.currentcount.toLocaleString()}) of ({this.state.totalcount.toLocaleString()}) </h4>
				</div>
				<div className="clearfix"></div>
				<div className="container-fluid">
			        <div className="row vechilePage newcarss">

						<div className="col-lg-3 ">
						<div className="card">
							<div className="form-group">
							<label> Select  Make</label>
								 <Select name="make"

	        								onChange={this.handleChange}
	        								options={Makes}/>
	        								<p style={{color:"red"}}>{this.state.errors['make']}</p>
							</div>
							<div className="form-group">
							<label> Select Model</label>
								   <Select name="model"
	        								onChange={this.handleModelChange}
	        								options={Models}/>
	        						<p style={{color:"red"}}>{this.state.errors['model']}</p>
							</div>

							<div className="form-group">
							<label> Zipcodes</label>
								<input type="text" name="zip" className="form-control" onChange={this.handleZipcode}/>
								<p style={{color:"red"}}>{this.state.errors['zipcode']}</p>
							</div>
							<div className="form-group">
							<label> Select radius</label>
							<p className="RangeValue">{this.state.rangeValue} Miles</p>
								  <input 
							      id="typeinp" 
							      type="range" 
							      min="0" max="100" 
							      value={this.state.rangeValue} 
							      onChange={this.handleRange}
							      step="1"/>
							      </div>
								<div className="form-group ">
							<button onClick={this.handleSearchMakeModel} className="btn btn-primary waves-effect waves-light" id="homesearchbutton">Search now</button>
							</div>
						</div>
						</div>
						{items=='' ? (<Notfound/>):(
			        		<div className="col-lg-9">
			        		          		 <div className="row appendData">  
			             		    {items.map(item => (
									<div className="col-lg-9 loadmore ribbon"  key={item.id}>
									{item.conditons ?(<span class="ribbon1"><span>{item.conditons}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)
									    
									}
									<div className="">
									 <div className="car-box-3 row customrow">
						
        <div className="col-lg-4  col-results">
          <Link target="_blank" to={"/private-party-cars-view/?id="+item.id+'&zip='+item.dealer.zip}>
            <div className="badge-overlay">
<span className="top-left badge oranges">Private</span>
</div>
            <img className="d-block w-100" src={item.media!==undefined ? (item.media.photo_links[0]):('../media/default-car.png')} alt="car" />
          </Link>
          <ul className="social-icons-lists icons-background-square" style={{marginTop: '18px'}}>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://twitter.com/intent/tweet?url="+url+"/private-party-cars-view/?id="+item.id+'&zip='+item.dealer.zip} className="social-icon" target="_blank">
                  <span className="fa fa-twitter" style={{padding: '10px', fontSize: '16px', backgroundColor: '#4da7de'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://www.facebook.com/sharer.php?u="+url+"/private-party-cars-view/?id="+item.id+'&zip='+item.dealer.zip} target="_blank" className="social-icon">
                  <span className="fa fa-facebook" style={{padding: '10px', fontSize: '16px', backgroundColor: '#3e5b98'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url="+url+"/private-party-cars-view/?id="+item.id+'&zip='+item.dealer.zip} target="_blank" className="social-icon">
                  <span className="fa fa-linkedin" style={{padding: '10px', fontSize: '16px', backgroundColor: '#0078b9'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://pinterest.com/pin/create/button/?url="+url+"/private-party-cars-view/?id="+item.id+'&zip='+item.dealer.zip} target="_blank" className="social-icon">
                  <span className="fa fa-pinterest" style={{padding: '10px', fontSize: '16px', backgroundColor: '#e02a20'}} />
                </a>
              </li>
            </ul>

        <img data-toggle="modal" data-target="#centralModalInfoDemo" onClick={() =>{this.handleVehicleData(item)}} width="75%"  src="../media/testdrive.png" className="p-4 clickable_button"/>
        
        </div>
        <div className="col-lg-4 newclas card-body">
          <p className="vehicle-name" style={{marginTop: '15px!important', fontWeight: '600!important', fontSize: '20px!important', marginBottom: '3px!important'}}><span className="mini-hide">{item.heading}</span></p>
          <p className="miles-style" style={{fontWeight: '600!important', marginBottom: '0px'}}>{item.ref_miles ?(item.ref_miles+' Miles'):('')} </p>
          <ul className="descriptions">
            <li><strong> Body Type: </strong>{item.build.body_type}</li>
            <li><strong>Exterior Color: </strong>{item.exterior_color}</li>
            <li><strong>Doors: </strong>{item.build.doors}</li>
            <li><strong>Vehicle Type: </strong>{item.build.vehicle_type}</li>
            <li>
              <p style={{fontSize: '11px', fontStyle: 'italic', fontWeight: 600, color: 'gray', lineHeight: '14px'}}>
                <i className="fa fa-map-marker"> </i>{item.dealer.name} Distance from you only <strong>{item.dist?(item.dist):('few')}</strong> Miles 
              </p>
            </li></ul></div>
        <div className="col-lg-4">
          <div className="inventory-right card-body">
            <ul className="descriptions">
              <li>
                <strong className="price_new">
				{
				item.price!=undefined
				  ?
				 	'$'
				  :
				  item.prices !=null
				  ?
				  '$'
				  :
				   ''

					}
				{
            	  item.prices!=undefined
				  ?
				 	item.prices.toLocaleString()
				  :
				  item.price !=null
				  ?
				  item.price.toLocaleString()
				  :
				   'NA'
				}
			</strong><small className="smalls">Listing price</small>
              </li> 
              <li>
                VIN # :{item.vin}
              </li>
              <li>
                {item.dealer.city},{item.dealer.state}
              </li>
              <li className="location-tag"> {item.ratting ? ("KBB.com Expert Ratings"):('')}
              {item.ratting ? (<img style={{height: '70px'}} data-src="/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" className=" ls-is-cached lazyloaded" src="https://shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
        	</li>
        	<li>
        	{item.ratting ?('Overall Rating'):('')}
        	</li>
              <li><small><div dangerouslySetInnerHTML={{ __html: item.ratting ?(item.ratting):('') }} />{item.value ?(<strong>{item.value}/5</strong>):('')}</small></li>
            </ul>
            <p><Link target="_blank" className="btn btn-primary" to={"/private-party-cars-view/?id="+item.id+'&zip='+item.dealer.zip}>More Details</Link></p>
            
          </div>
        </div>
      </div>
									</div>
									</div>
									))}
			            	</div>
			            	
			         </div>
			         )}
			         <div className="col-lg-12 text-center">
			         {this.state.currentcount<this.state.totalcount ? (
			         		<button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
			         ):('')}

			         </div>
			        </div>
		       		 </div>
		       		 <Footer/>
		       		 </div>

		       
		      )
		    }
		  }
		}

export default privatepartycars;