import React from 'react';

import '../App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Spinner extends React.Component {
  render() {
    return   <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
          <section className="section-preview">
        
        <div className="preloader-wrapper active big ">
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle" />
            </div>
            <div className="gap-patch">
              <div className="circle" />
            </div>
            <div className="circle-clipper right">
              <div className="circle" />
            </div>
          </div>
        </div>
      </section></div>
        </div>
      </div>;
}
}
export default Spinner;