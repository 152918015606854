import React from 'react';
import {Redirect, Route,BrowserRouter as Router } from "react-router-dom";
import Header from '../component/Header'; // Import a component from another file
import Maincontent from '../component/Maincontent'; // Import a component from another file
import Path from './path'; // Import a component from another file
import Vehicles from '../component/Vehicles';
import Valuemycar from "../component/Valuemycar";
import ResearchTool from "../component/ResearchTool";
import carreviews from "../component/carreviews";
import newcars from "../component/newcars";
import usedcars from "../component/usedcars";
import canadanewcars from "../component/canadanewcars";
import canadausedcars from "../component/canadausedcars";
import dealers from "../component/dealers";
import canadadealers from "../component/candadealers";
import cardetails from "../component/cardetails";
import comparecar from "../component/comparecar";
import carreviewsresults from "../component/carreviewsresults";
import certifiedcars from "../component/certifiedcars";
import carview from "../component/carview";
import post from "../component/post";
import blogs from "../component/blogs";
import ads from "../component/ads";
import dealerinventry from "../component/dealerinventry";

import dealerview from "../component/dealerview";
import dealershow from "../component/dealershow";
import vinhistory from '../component/vinhistory'; // Import a component from another file
import financelease from '../component/financelease'; // Import a component from another file
import privatepartycars from '../component/privatepartycars'; // Import a component from another file
import chat from '../component/chat'; // Import a component from another file
import privatepartycarsview from '../component/privatepartycarsview'; // Import a component from another file
import newcardealers from '../component/newcardealers'; // Import a component from another file
import independentdealer from '../component/independentdealer'; // Import a component from another file
import privacypolicy from '../component/privacypolicy'; // Import a component from another file


class Routes extends React.Component {

  render() {
   var url= window.location.pathname;
   var chaturl= window.location.pathname.split('/');
  
       let header = chaturl[1] !== 'chat' ? <Header /> :'';

  	return(
    <div>

        <Router>
      {header}
           <Route exact path="/" component={Maincontent} />
        <Route path="/special-deals" component={Vehicles} />
        <Route path="/ads" component={ads} />
        <Route path="/value-my-car" component={Valuemycar} />
        <Route path="/research-tool" component={ResearchTool} />
        <Route path="/car-reviews" component={carreviews} />
        <Route path="/new-cars" component={newcars} />
        <Route path="/used-cars" component={usedcars} />
        <Route path="/canada-new-cars" component={canadanewcars} />
        <Route path="/canada-used-cars" component={canadausedcars} />
        <Route path="/dealers" component={dealers} />
        <Route path="/canadian-dealers" component={canadadealers} />
        <Route path="/car-details" component={cardetails} />
        <Route path="/compare-car" component={comparecar} />
        <Route path="/car-reviews-results" component={carreviewsresults} />
        <Route path="/certified-cars" component={certifiedcars} />
        <Route path="/post/:slug" component={post} />
        <Route path="/car-view" component={carview} />
        <Route path="/feature-blog" component={blogs} />
        <Route path="/dealer-inventry" component={dealerinventry} />
        <Route path="/dealer-view" component={dealerview} />
        <Route path="/chat/*" component={chat} />
        <Route path="/vin-history" component={vinhistory} />
        <Route path="/lease" component={financelease} />
        <Route path="/private-party-cars" component={privatepartycars} />
        <Route path="/private-party-cars-view" component={privatepartycarsview} />
        <Route path="/dealer-show" component={dealershow} />
        <Route path="/new-car-dealers" component={newcardealers} />
        <Route path="/independent-dealer" component={independentdealer} />
        <Route path="/privacy-policy" component={privacypolicy} />
        </Router>
      </div>

      );
  }
}
export default Routes;