import React from 'react';

import '../App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class notfound extends React.Component {
  render() {
    return    <main className=" col-lg-9">
        <div className="card">
          {/*Section: Content*/}
          <section className=" card-body px-md-5 mx-md-5 text-center dark-grey-text mb-4">
            <img src="../media/notfound.jpg" alt="Error 404" className="img-fluid mb-4" />
            <h3 className="font-weight-bold">Oops! We have not found any data according to your search.</h3>
            <p>Please change the search terms and try with another selections</p>          
          </section>
          {/*Section: Content*/}
        </div>
      </main>;
}
}
export default notfound;