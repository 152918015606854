import React from 'react';

import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import Notfound from './notfound';
import SEO from 'react-seo-component';
class canadausedcars extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      error: null,
		      isLoaded: false,
		      items: [],
		      vehicleClass:'used',
		      rangeValue:0,
		      selectedModel:null,
		      selectedMake:null,
		      zipcode:null,
		      errors:[],
		      formValid:false,
		       totalcount:0,
		      currentcount:0,

		    };
		    this.LoadMore = this.LoadMore.bind(this)
		  }
 componentDidMount (){
// get Vehicles

				var self = this;
			// Get User current position
			self.setState({ isLoaded: false });
			 navigator.geolocation.getCurrentPosition(function(position) {
			     
			this.setState({latitude: position.coords.latitude,longitude: position.coords.longitude,});
				$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"nearBy":"nearBy",latitude: position.coords.latitude,longitude: position.coords.longitude,vehicleClass:'used',country:'ca'
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.listings });
                    self.setState({ totalcount:getResponse.listings.length});
                    self.setState({ currentcount: getResponse.num_found });
                    self.setState({ isLoaded: true });
                  }
               );
				// get makes accordig to curretn position
		   		$.post(

                  "https://www.shopsmartautos.com/react_api.php",
                  {

                  	"LoadMake":"LoadMake",latitude: self.state.latitude,longitude: self.state.longitude,vehicleClass:self.state.vehicleClass,country:'ca'
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Makes: getResponse.listings });
                  
                  }
               ); 
		   		// Get zipcodes accoding current location

			}, (error)=>{
				// On denied position
					var self = this;
		   		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"LoadMake":"LoadMake",latitude:40.94623,longitude: -73.069777,vehicleClass:self.state.vehicleClass,country:'ca'
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Makes: getResponse });
                 
                  }
               );
		   		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"nearBy":"nearBy",latitude:40.94623,longitude: -73.069777,vehicleClass:'used',country:'ca'
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);

                    self.setState({ items: getResponse.listings });
                    self.setState({ currentcount:getResponse.listings.length});
                    self.setState({ totalcount: getResponse.num_found });
                    self.setState({ isLoaded: true });
                  }
               );


			this.setState  ({
								latitude:40.94623,
								longitude: -73.069777,
								Makes:null,

							});
			});

		}
		
	// get model on user make selection start
	handleChange = selectedOption => {
				var self = this;
		   		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"LoadModel":"LoadModel",latitude: self.state.latitude,longitude: self.state.longitude,make:selectedOption.value,vehicleClass:self.state.vehicleClass,country:'ca'
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Models: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedMake: selectedOption.value });
                  }
               ); 

			}

			handleRange= handleGetRange=>{
				var self=this;
				handleGetRange.persist();
				self.setState({rangeValue:handleGetRange.target.value});

			}
		
			LoadMore()
			{
				var data_query={};
				var self=this;
				var CurrentCars=$('.loadmore').length;
				data_query['car_type']='used';
				var array=self.state.items;
				 if(self.state.rangeValue!=0 && self.state.zipcode!=null)
				 {
				 	data_query['radius']=self.state.rangeValue;
				 	data_query['zip']=self.state.zipcode;
				 }
				 if(self.state.selectedModel!=null)
				 {
				 	data_query['model']=self.state.selectedModel;
				 }
				 if(self.state.selectedMake!=null)
				 {
				 	data_query['make']=self.state.selectedMake;
				 }
				 data_query['country']='ca';
				 $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
				$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {data_query:data_query,startpoint:CurrentCars,'loadMore':'loadMore'},
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    		 var children = array.concat(getResponse.listings); 
                      var CurrentCarsTotal=self.state.currentcount+getResponse.listings.length;
                      self.setState({ items: children });
                      self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: CurrentCarsTotal });
                     $('#btn-one').html('Load More').removeClass('disabled');
                  }
               );
				

			}
			handleModelChange= getValue=>{
				var self=this;
					self.setState({selectedModel:getValue.value});
			}

			handleZipcode= GetZipCode=>{
				var self=this;
				GetZipCode.persist();
				self.setState({zipcode:GetZipCode.target.value});

			}
			handleSearchMakeModel=MakeModelZipSearch=>{
				MakeModelZipSearch.persist();
				var self=this;
				var Make=self.state.selectedMake;
				var Model=self.state.selectedModel;
				var zipcode=self.state.zipcode;
				var rangeValue=self.state.rangeValue;
					
				if(self.FormValidations())
				{
					  self.setState({ isLoaded: false });
					 var getStringPara={'make':Make,'model':Model,'zip':zipcode,'radius':rangeValue,country:"ca",'car_type':'used'};
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"SearchMakeModel":"true",getStringPara:getStringPara
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.listings });
                      self.setState({ totalcount: getResponse.num_found });
                       self.setState({ currentcount: getResponse.listings.length });
                    self.setState({ isLoaded: true });

                  }
               	); 
				}

			}
			FormValidations=getValidate=>{
				var self=this;
				var Make=self.state.selectedMake;
				var Model=self.state.selectedModel;
				var zipcode=self.state.zipcode;
				var rangeValue=self.state.rangeValue;
				var Messages={};
				if(Make==null)
				{
					Messages['make']='Please select the  make!';
					self.setState({errors:Messages});
					return false;
				}else if(Model==null)
				{
					Messages['model']='Please select the Model!';
					self.setState({errors:Messages});
					return false;
				}else if(zipcode==null)
				{
					Messages['zipcode']='Please fill up the Zipcode!';
					self.setState({errors:Messages});
					return false;
				}else
				{
					self.setState({errors:''});
					return true;
				}

				
			}
	// get model on user make selection end

	// get model on user make selection start
	
		 render() {
		    const { error, isLoaded, items } = this.state;
		 	
  		const  Makes  = this.state.Makes;
  		const  Years  = this.state.Years;
  		const  selectedMake  = this.state.selectedMake;
  		const  Models  = this.state.Models;
  		const { selectedOption } = this.state;
  	 	const { selectedModel } = this.state;
  	 	const { selectedYear } = this.state;
  	 	const href=window.location.href;
		const url=window.location.host;
		    if (error) {
		      return <div>Error: {error.message}</div>;
		    } else if (!isLoaded) {
		      return <Spinner/>;
		    } else {
		      return (
		      	

		      	<div >
		      	<SEO
  title={'Canada Used Cars- Shopsmartautos'}
  titleTemplate={'Shopsmartautos'}
  titleSeparator={'-'}
  description='THE EASIEST WAY TO BUY YOUR NEW CAR-Must-Know Advice from our Experts '
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
		      	<header className="candausedcars">
		      	<div className="Overalay">
  						<div className="container h-100">
	    					<div className="row h-100 align-items-center">
	      						<div className="col-12 text-center">
	        						<h1 className="heading">THE EASIEST WAY TO BUY YOUR USED CAR</h1>
	        						<p className="lead">Must-Know Advice from our Experts</p>
	      						</div>
	    					</div>
  						</div>
  						</div>
				</header>
				<div className="col-lg-12">

					<h4 className="searchResults">Searching results showing ({this.state.currentcount.toLocaleString()}) of ({this.state.totalcount.toLocaleString()}) </h4>
				</div>
				<div className="clearfix"></div>
				<div className="container-fluid">
			        <div className="row vechilePage newcarss">

						<div className="col-lg-3 ">
						<div className="card">
							<div className="form-group">
							<label> Select  Make</label>
								 <Select name="make"

	        								onChange={this.handleChange}
	        								options={Makes}/>
	        								<p style={{color:"red"}}>{this.state.errors['make']}</p>
							</div>
							<div className="form-group">
							<label> Select Model</label>
								   <Select name="model"
	        								onChange={this.handleModelChange}
	        								options={Models}/>
	        						<p style={{color:"red"}}>{this.state.errors['model']}</p>
							</div>

							<div className="form-group">
							<label> Zipcodes</label>
								<input type="text" name="zip" className="form-control" onChange={this.handleZipcode}/>
								<p style={{color:"red"}}>{this.state.errors['zipcode']}</p>
							</div>
							<div className="form-group">
							<label> Select radius</label>
							<p className="RangeValue">{this.state.rangeValue} Miles</p>
								  <input 
							      id="typeinp" 
							      type="range" 
							      min="0" max="100" 
							      value={this.state.rangeValue} 
							      onChange={this.handleRange}
							      step="1"/>
							      </div>
								<div className="form-group ">
							<button onClick={this.handleSearchMakeModel} className="btn btn-primary waves-effect waves-light">Search now</button>
							</div>
						</div>
						</div>
			        		{items=='' ? (<Notfound/>):(
			        		<div className="col-lg-9">
			        		          		 <div className="row appendData">  
			             		    {items.map(item => (
									<div className="col-lg-9 loadmore ribbon"  key={item.id}>
									{item.conditons ?(<span class="ribbon1"><span>{item.conditons}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)
									    
									}
									<div className="">
									 <div className="car-box-3 row customrow">
						
        <div className="col-lg-4  col-results">
          <Link target="_blank" to={"/car-details/?id="+item.id}>
            <div className="badge-overlay">
<span className="top-left badge oranges">USED</span>
</div>
            <img className="d-block w-100" src={item.media!==undefined ? (item.media.photo_links[0]):('../media/default-car.png')} alt="car" />
          </Link>
          <ul className="social-icons-lists icons-background-square" style={{marginTop: '18px'}}>
               <li className="social-icons-list-item">
                <a  target="_blank" href={"https://twitter.com/intent/tweet?url="+url+"/car-details/?id="+item.id} className="social-icon" target="_blank">
                  <span className="fa fa-twitter" style={{padding: '10px', fontSize: '16px', backgroundColor: '#4da7de'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://www.facebook.com/sharer.php?u="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-facebook" style={{padding: '10px', fontSize: '16px', backgroundColor: '#3e5b98'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-linkedin" style={{padding: '10px', fontSize: '16px', backgroundColor: '#0078b9'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://pinterest.com/pin/create/button/?url="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-pinterest" style={{padding: '10px', fontSize: '16px', backgroundColor: '#e02a20'}} />
                </a>
              </li>
            </ul>
        </div>
        <div className="col-lg-4 newclas card-body">
          <p className="vehicle-name" style={{marginTop: '15px!important', fontWeight: '600!important', fontSize: '20px!important', marginBottom: '3px!important'}}><span className="mini-hide">{item.heading}</span></p>
          <p className="miles-style" style={{fontWeight: '600!important', marginBottom: '0px'}}>{item.ref_miles ?(item.ref_miles+' Miles'):('')} </p>
          <ul className="descriptions">
            <li><strong> Body Type: </strong>{item.build.body_type}</li>
            <li><strong>Exterior Color: </strong>{item.exterior_color}</li>
            <li><strong>Doors: </strong>{item.build.doors}</li>
            <li><strong>Made-in: </strong>{item.build.made_in}</li>
            <li><strong>Vehicle Type: </strong>{item.build.vehicle_type}</li>
            <li><strong>Standard Seating: </strong>{item.build.std_seating}</li>
            <li>
              <p style={{fontSize: '11px', fontStyle: 'italic', fontWeight: 600, color: 'gray', lineHeight: '14px'}}>
                <i className="fa fa-map-marker"> </i>{item.dealer.name} Distance from you only <strong>{item.dist?(item.dist):('few')}</strong> Miles 
              </p>
            </li></ul></div>
        <div className="col-lg-4">
          <div className="inventory-right card-body">
            <ul className="descriptions">
              <li>
                <strong className="price_new">${item.price!=null ? (item.price.toLocaleString()):('NA')}</strong><small className="smalls">Listing price</small>
              </li> 
              <li>
                VIN # :{item.vin}
              </li>
              <li>
                {item.dealer.city},{item.dealer.state}
              </li>
              <li className="location-tag"> {item.ratting ? ("KBB.com Expert Ratings"):('')}
              {item.ratting ? (<img style={{height: '70px'}} data-src="/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" className=" ls-is-cached lazyloaded" src="https://shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
        	</li>
        	<li>
        	{item.ratting ?('Overall Rating'):('')}
        	</li>
              <li><small><div dangerouslySetInnerHTML={{ __html: item.ratting ?(item.ratting):('') }} />{item.value ?(<strong>{item.value}/5</strong>):('')}</small></li>
            </ul>
            <p><Link target="_blank" className="btn btn-primary" to={"/car-details/?id="+item.id}>More Details</Link></p>
            
          </div>
        </div>
      </div>
									</div>
									</div>
									))}
			            	</div>
			            	
			         </div>
			         )}
			         <div className="col-lg-12 text-center">
			         		{this.state.currentcount<this.state.totalcount ? (
			         		<button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
			         ):('')} </div>
			        </div>
		       		 </div>
		       		 <Footer/>
		       		 </div>

		       
		      )
		    }
		  }
		}
export default canadausedcars;