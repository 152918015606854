import React from 'react';
import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Notfound from './notfound';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import SEO from 'react-seo-component';
import NewsLetter from "./newsletter";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
class financelease extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      error: null,
		      isLoaded: false,
		      items: [],
		      vehicleClass:'new',
		      rangeValue:0,
		      selectedModel:null,
		      selectedMake:null,
		      zipcode:null,
		      errors:[],
		      formValid:false,
		      MaxPrice:100,
		      Makesearch:null,
		      totalcount:0,
		      currentcount:0,
		      lowerBound:5000,
          upperBound:1000000,
          value: [5000, 1000000],
          changeLowvalue:5000.,
          changeHighvalue:1000000,

          leaseTermstart:2,
          leaseTermEnd:72,
          valueLeasterm: [2, 72],
          changeLeaseLowterm:2.,
          changeLeaseHighTerm:72,


          leaseDownPaymentstart:200,
          leaseDownPaymentEnd:10000,
          valueLeaseDownPayemnt: [200, 10000],
          changeLeaseDownPayemntLowTerm:200,
          changeLeaseDownPayemntHighTerm:10000,

          leaseEMPstart:200,
          leaseEMPEnd:10000,
          valueLeaseEMP: [200, 10000],
          changeLeaseEMPLowTerm:200,
          changeLeaseEMPHighTerm:10000,

		    };
		    this.LoadMore = this.LoadMore.bind(this)
		    this.PostData = this.PostData.bind(this)
		  }

		  PostData(getStringPara)
		  {
		  	 	var self = this;
		  	 	var CurrentCars=$('.loadmore').length;
		  		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"SearchMakeModel":"true",'getStringPara':getStringPara
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.listings });
                    self.setState({ totalcount: getResponse.num_found });
                    self.setState({ currentcount:getResponse.listings.length});
                    
                    self.setState({ isLoaded: true });

                  }
               	);
					// load make according to search bar selection
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {

                  	"LoadMake":"LoadMake",vehicleClass:self.state.vehicleClass
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Makes: getResponse });
                      
                  }
               ); 
		  }

 	componentDidMount (){

		 var self = this;
		 var url_string = window.location.href;
         var url = new URL(url_string);
         var loan_term = url.searchParams.get("loan-term");

         var down_payment = url.searchParams.get("down-payment");
         var leaseemp = url.searchParams.get("lease-emp");
         	var data={};
         	data['loan_term']=loan_term;
         	data['down_payment']=down_payment;
         	data['leaseemp']=leaseemp;
         	data['leaseoffer']='leaseoffer';
         		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  data,
                  function(data) {
                  var getResponse=JSON.parse(data);
                 console.log(getResponse);
                     self.setState({ items: getResponse.listings });
                     self.setState({ isLoaded: true });
                     self.setState({ totalcount: getResponse.num_found });
                     var CurrentCarsTotal=self.state.currentcount+getResponse.listings.length;
                     self.setState({ currentcount: CurrentCarsTotal });  

                  }
               );
		}
	
	// get model on user make selection start
	handleChange = selectedOption => {
				var self = this;
				 $('#homesearchbutton').html('<span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span><span className="text-white">Models Loading</span>').addClass('disabled');
		   		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"LoadModel":"LoadModel",latitude: self.state.latitude,longitude: self.state.longitude,make:selectedOption.value,vehicleClass:self.state.vehicleClass
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Models: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedMake: selectedOption.value });
                    $('#homesearchbutton').html('Search now').removeClass('disabled');
                  }
               ); 

			}

			handleRange= handleGetRange=>{
				var self=this;
				handleGetRange.persist();
				self.setState({rangeValue:handleGetRange.target.value});

			}


		
 onLeaseTermChange = value => {
   console.log(value);
   var self=this;
self.setState({changeLeaseLowterm:value[0]});
self.setState({changeLeaseHighTerm:value[1]});

      //$('.link_price').attr('href',geturls[0]+'/?getprice=true&MaxPrice='+value[0]+'-'+value[1]);
  }


onLeaseDownPaymentChange = value => {
   console.log(value);
   var self=this;
self.setState({changeLeaseDownPayemntLowTerm:value[0]});
self.setState({changeLeaseDownPayemntHighTerm:value[1]});


      //$('.link_price').attr('href',geturls[0]+'/?getprice=true&MaxPrice='+value[0]+'-'+value[1]);
  }


 onLeaseEMP = value => {
   console.log(value);
   var self=this;
self.setState({changeLeaseEMPLowTerm:value[0]});
self.setState({changeLeaseEMPHighTerm:value[1]});

  }



			LoadMore()
			{
				var data_query={};
				var self=this;
				var CurrentCars=$('.loadmore').length;
				data_query['car_type']='new';
				var array=self.state.items;
				 		var url_string = window.location.href;
					        var url = new URL(url_string);
					        var car_type = url.searchParams.get("car_type");
					        var getprice = url.searchParams.get("getprice");
					        var Makesearch = url.searchParams.get("Makesearch");

				 			
				 
				 $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
				$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {data_query:data_query,startpoint:CurrentCars,'loadMore':'loadMore'},
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    		 var children = array.concat(getResponse.listings); 
                    		 var CurrentCarsTotal=self.state.currentcount+getResponse.listings.length;
                      self.setState({ items: children });
                      self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: CurrentCarsTotal });
                     $('#btn-one').html('Load More').removeClass('disabled');
                  }
               );
				

			}
			handleModelChange= getValue=>{
				var self=this;
					self.setState({selectedModel:getValue.value});
			}

			handleZipcode= GetZipCode=>{
				var self=this;
				GetZipCode.persist();
				self.setState({zipcode:GetZipCode.target.value});

			}
			handleSearchMakeModel=MakeModelZipSearch=>{
				MakeModelZipSearch.persist();
				var self=this;
				var Make=self.state.selectedMake;
				var Model=self.state.selectedModel;
				var zipcode=self.state.zipcode;
				var rangeValue=self.state.rangeValue;
				var CurrentCars=$('.loadmore').length;
				if(self.FormValidations())
				{
					  self.setState({ isLoaded: false });
					 var getStringPara={'make':Make,'model':Model,'zip':zipcode,'radius':rangeValue,'car_type':'new'};
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"SearchMakeModel":"true",getStringPara:getStringPara
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.listings });
                    self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: getResponse.listings.length });
                     
                    self.setState({ isLoaded: true });

                  }
               	); 
				}

			}
			FormValidations=getValidate=>{
				var self=this;
				var Make=self.state.selectedMake;
				var Model=self.state.selectedModel;
				var zipcode=self.state.zipcode;
				var rangeValue=self.state.rangeValue;
				var Messages={};
				if(Make==null)
				{
					Messages['make']='Please select the  make!';
					self.setState({errors:Messages});
					return false;
				}else if(Model==null)
				{
					Messages['model']='Please select the Model!';
					self.setState({errors:Messages});
					return false;
				}else if(zipcode==null)
				{
					Messages['zipcode']='Please fill up the Zipcode!';
					self.setState({errors:Messages});
					return false;
				}else
				{
					self.setState({errors:''});
					return true;
				}

				
			}
	// get model on user make selection end

	// get model on user make selection start
	
		 render() {
		    const { error, isLoaded, items } = this.state;
		 	
  		const  Makes  = this.state.Makes;
  		const  Years  = this.state.Years;
  		const  selectedMake  = this.state.selectedMake;
  		const  Models  = this.state.Models;
  		const { selectedOption } = this.state;
  	 	const { selectedModel } = this.state;
  	 	const { selectedYear } = this.state;
  	 	const url=window.location.host;
  	 	const href=window.location.href;

  	 
		    if (error) {
		      return <div>Error: {error.message}</div>;
		    }else if (!isLoaded) {
		      return <Spinner/>;
		    } else {
		      return (
		      	
		      	<div >
		      		<SEO
  title={'New Cars-shopsmartautos'}
  titleTemplate={'shopsmartautos'}
  titleSeparator={'-'}
  description='THE EASIEST WAY TO BUY YOUR NEW CAR-Must-Know Advice from our Experts '
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
		      	<header className="newcars">
  						<div className="container h-100">
	    					<div className="row h-100 align-items-center">
	      						<div className="col-12 text-center">
	        						<h1 className="heading">THE EASIEST WAY TO BUY YOUR NEW CAR</h1>
	        						<p className="lead">Must-Know Advice from our Experts</p>
	      						</div>
	    					</div>
  						</div>
				</header>
				<div className="container"><NewsLetter/></div>
				<div className="col-lg-12">
						
					<h4 className="searchResults">Searching results showing ({this.state.currentcount.toLocaleString()}) of ({this.state.totalcount.toLocaleString()}) </h4>
				</div>
				<div className="clearfix"></div>
				<div className="container-fluid">
			        <div className="row vechilePage newcarss">

						<div className="col-lg-3 ">
						<div className="card">
							<div className="col-sm-12 card-body ">
           <label className="lable_ince">Lease Term</label>
            <div >

              <span className="min_price" id="slider-range-value1"  >{this.state.changeLeaseLowterm.toLocaleString()} Months</span> <span className="min_price"> - </span>
              <span className="max_price" id="slider-range-value2" >{this.state.changeLeaseHighTerm.toLocaleString()} Months</span>
                         </div>
            <div className="slider-range">

             <Range step={2}
          defaultValue={this.state.valueLeasterm}
          min={this.state.leaseTermstart}
          max={this.state.leaseTermEnd}
          onChange={this.onLeaseTermChange}
        /></div>

   
          </div>

          <div className="col-sm-12 card-body ">
           <label className="lable_ince">Lease Down Payment</label>
            <div >

              <span className="min_price" id="slider-range-value1"  >${this.state.changeLeaseDownPayemntLowTerm.toLocaleString()}</span><span className="min_price">-</span>
              <span className="max_price" id="slider-range-value2" >${this.state.changeLeaseDownPayemntHighTerm.toLocaleString()}</span>
                         </div>
            <div className="slider-range">

             <Range
          defaultValue={this.state.valueLeaseDownPayemnt}
          min={this.state.leaseDownPaymentstart}
          max={this.state.leaseDownPaymentEnd}
          onChange={this.onLeaseDownPaymentChange}
        /></div>

   
          </div>

         <div className="col-sm-12 card-body">
           <label className="lable_ince">Lease Emp (Finance Estimated Monthly Payment)</label>
            <div >

              <span className="min_price" id="slider-range-value1"  >${this.state.changeLeaseEMPLowTerm.toLocaleString()}</span><span className="min_price">-</span>
              <span className="max_price" id="slider-range-value2" >${this.state.changeLeaseEMPHighTerm.toLocaleString()}</span>
                         </div>
            <div className="slider-range">

             <Range
          defaultValue={this.state.valueLeaseEMP}
          min={this.state.leaseEMPstart}
          max={this.state.leaseEMPEnd}
          onChange={this.onLeaseEMP}
        /></div>

   
          </div>

        
        <div className="row slider-labels">
        </div>
        <div className="row">
          <div className="col-sm-12">
            
            <form action="/lease" method="get">
              <input type="hidden" id="min_price" name="q" defaultValue="true" />
              <input type="hidden" id="max_price" name="loan-term" defaultValue={this.state.changeLeaseLowterm+'-'+this.state.changeLeaseHighTerm} />

              <input type="hidden" id="max_price" name="down-payment" defaultValue={this.state.changeLeaseDownPayemntLowTerm+'-'+this.state.changeLeaseDownPayemntHighTerm} />
              
              <input type="hidden" id="max_price" name="lease-emp" defaultValue={this.state.changeLeaseEMPLowTerm+'-'+this.state.changeLeaseEMPHighTerm} />

              <button className="link_price btn btn-primary" type="submit">
                Get Your Offer
              </button>
            </form>
						</div>
						</div>
						</div>
						</div>
						{items=='' ? (<Notfound/>):(
			        		<div className="col-lg-9">
			        		          		 <div className="row appendData">  
			             		    {items.map(item => (
									<div className="col-lg-9 loadmore ribbon"  key={item.id}>
									{item.conditons ?(<span class="ribbon1"><span>{item.conditons}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)
									    
									}
									<div className="">
									 <div className="car-box-3 row customrow">
						
        <div className="col-lg-4  col-results">
          <Link target="_blank" to={"/car-details/?id="+item.id}>
            <div className="badge-overlay">
<span className="top-left badge oranges">New</span>
</div>
            <img className="d-block w-100" src={item.media!==undefined ? (item.media.photo_links[0]):('../media/default-car.png')} alt="car" />
          </Link>
          <ul className="social-icons-lists icons-background-square" style={{marginTop: '18px'}}>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://twitter.com/intent/tweet?url="+url+"/car-details/?id="+item.id} className="social-icon" target="_blank">
                  <span className="fa fa-twitter" style={{padding: '10px', fontSize: '16px', backgroundColor: '#4da7de'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://www.facebook.com/sharer.php?u="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-facebook" style={{padding: '10px', fontSize: '16px', backgroundColor: '#3e5b98'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-linkedin" style={{padding: '10px', fontSize: '16px', backgroundColor: '#0078b9'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://pinterest.com/pin/create/button/?url="+url+"/car-details/?id="+item.id} target="_blank" className="social-icon">
                  <span className="fa fa-pinterest" style={{padding: '10px', fontSize: '16px', backgroundColor: '#e02a20'}} />
                </a>
              </li>
            </ul>
        </div>
        <div className="col-lg-4 newclas card-body">
          <p className="vehicle-name" style={{marginTop: '15px!important', fontWeight: '600!important', fontSize: '20px!important', marginBottom: '3px!important'}}><span className="mini-hide">{item.heading}</span></p>
          <p className="miles-style" style={{fontWeight: '600!important', marginBottom: '0px'}}>{item.ref_miles ?(item.ref_miles+' Miles'):('')} </p>
          <ul className="descriptions">
            <li><strong> Body Type: </strong>{item.build.body_type}</li>
            <li><strong>Exterior Color: </strong>{item.exterior_color}</li>
            <li><strong>Doors: </strong>{item.build.doors}</li>
            <li><strong>Made-in: </strong>{item.build.made_in}</li>
            <li><strong>Vehicle Type: </strong>{item.build.vehicle_type}</li>
            <li><strong>Standard Seating: </strong>{item.build.std_seating}</li>
            <li>
              <p style={{fontSize: '11px', fontStyle: 'italic', fontWeight: 600, color: 'gray', lineHeight: '14px'}}>
                <i className="fa fa-map-marker"> </i>{item.dealer.name} Distance from you only <strong>{item.dist?(item.dist):('few')}</strong> Miles 
              </p>
            </li></ul></div>
        <div className="col-lg-4">
          <div className="inventory-right card-body">
            <ul className="descriptions">
              <li>
                <strong className="price_new">
				{
				item.price!=undefined
				  ?
				 	'$'
				  :
				  item.prices !=null
				  ?
				  '$'
				  :
				   ''

					}
				{
            	  item.prices!=undefined
				  ?
				 	item.prices.toLocaleString()
				  :
				  item.price !=null
				  ?
				  item.price.toLocaleString()
				  :
				   'NA'
				}
			</strong><small className="smalls">Listing price</small>
              </li> 
              <li>
                VIN # :{item.vin}
              </li>
              <li>
                {item.dealer.city},{item.dealer.state}
              </li>
              <li className="location-tag"> {item.ratting ? ("KBB.com Expert Ratings"):('')}
              {item.ratting ? (<img style={{height: '70px'}} data-src="/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" className=" ls-is-cached lazyloaded" src="https://shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
        	</li>
        	<li>
        	{item.ratting ?('Overall Rating'):('')}
        	</li>
              <li><small><div dangerouslySetInnerHTML={{ __html: item.ratting ?(item.ratting):('') }} />{item.value ?(<strong>{item.value}/5</strong>):('')}</small></li>
            </ul>
            <p><Link target="_blank" className="btn btn-primary" to={"/car-details/?id="+item.id}>More Details</Link></p>
            
          </div>
        </div>
      </div>
									</div>
									</div>
									))}
			            	</div>
			            	
			         </div>
			         )}
			         <div className="col-lg-12 text-center">
			         {this.state.currentcount<this.state.totalcount ? (
			         		<button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
			         ):('')}

			         </div>
			        </div>
		       		 </div>
		       		 <Footer/>
		       		 </div>

		       
		      )
		    }
		  }
		}

export default financelease;