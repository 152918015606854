import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import Searchbarhome from "./Searchbarhome";
import OurShopers from "./OurShopers";
import BrowseByBodyStyle from "./BrowseByBodyStyle";
import BrwoseByPrice from "./BrwoseByPrice";
import BrowseByBrand from "./BrowseByBrand";
import DreamCar from "./DreamCar";
import Footer from "./Footer";
import Ads from "./ads";
import Topcars from "./Topcars";
import Newsletter from "./newsletter";
import Helmet from 'react-helmet';
import SEO from 'react-seo-component';



class Maincontent extends React.Component {
  
   render(){   
    var settings = {
      dots:true,
      infinite: true,
      speed: 500,
      autoplay:true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
         
              <SEO
            title={'Home-shopsmartautos'}
            titleTemplate={'shopsmartautos'}
            titleSeparator={'-'}
            description='ShopSmartAutos One Stop Solution for Automobile Enthusiasts'
            image={'https://www.shopsmartautos.com/media/Depositphotos_173886668_l-2015.jpg'}
            pathname={'https://www.shopsmartautos.com'}
            siteLanguage={'en-US'}
            siteLocale={'en-US'}
            twitterUsername={'shopsmartautos'}
/>
              <div className="containers">
                 <Searchbarhome/>
                 <div className="sliderss">
        <Slider {...settings}>

          <div className="slick_custom">
          <img
            
            src="../media/Depositphotos_173886668_l-2015.jpg"
            alt="ShopSmartAutos-slider image 1"
            />         
           </div>
          <div className="slick_custom">
             <img
            src="../media/image001.jpg"
            alt="ShopSmartAutos-slider image 2"
            />   
          </div>
          <div className="slick_custom">
            <img
            src="../media/Depositphotos_46592921_l-2015.jpg"
            alt="ShopSmartAutos-slider image 3"
            />
          </div>
          <div className="slick_custom">
        
            <img
            src="../media/Depositphotos_127692368_l-2015.jpg"
            alt="ShopSmartAutos-slider image 4"
            />
          </div>
          <div className="slick_custom">
            <img
            src="../media/Depositphotos_27373401_l-2015.jpg"
            alt="ShopSmartAutos-slider image 5"
            />
          </div>
          <div className="slick_custom">
             <img
            src="../media/Depositphotos_13965161_l-2015.jpg"
            alt="ShopSmartAutos-slider image 6"
            />
          </div>
          </Slider>
        </div>
        </div>
        <div className="container mt-5">
        <Newsletter/>        
        <Ads/>
         <Topcars/>
       <OurShopers/>

       <BrowseByBodyStyle/>
        
       <BrwoseByPrice/>
       <BrowseByBrand/>
       <DreamCar/>
       </div>
       <Footer/>
        </div>
       

           

     
    );
  }
}
export default Maincontent;