import React from 'react';

import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Footer from "./Footer";

import { Link } from 'react-router-dom';
import Notfound from './notfound';
import SEO from 'react-seo-component';
import Newsletter from "./newsletter";

import Testdrive from "./testdrive";
class Vehicles extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
				error: null,
				isLoaded: false,
				items: [],
				Makes: [],
				Models:[],
				Years:[],
				selectedMake:null,
				selectedModel:null,
				selectedYear:null,
				zipcode:null,
				 errors:[],
		      formValid:false,
		       totalcount:0,
		      currentcount:10,
					};
					this.LoadMore = this.LoadMore.bind(this);
					this.FormValidations = this.FormValidations.bind(this);
					this.handleSearchMakeModel = this.handleSearchMakeModel.bind(this);
				}
				state = {
					selectedOption: null,
				};

			componentDidMount() {
				var self = this;
				$.post(
				"https://www.shopsmartautos.com/react_api.php",
				{
				"GetMakeVehicle":"GetMakeVehicle"
				},
				function(data) {
					var getResponse=JSON.parse(data);
					self.setState({ Makes: getResponse });
					
				}
				);

				$.post(
				"https://www.shopsmartautos.com/react_api.php",
				{
				"LoadOne":"LoadOne"
				},
				function(data) {
					var getResponse=JSON.parse(data);
					self.setState({ items: getResponse.data });
					self.setState({ totalcount: getResponse.num_found[0].NumRows });
					 self.setState({ currentcount: getResponse.data.length });
					self.setState({ isLoaded: true });
				}
				); 
			}

			handleChange = selectedOption => {
				var self=this;
				$('#homesearchbutton').html('<span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span><span className="text-white">Models Loading</span>').addClass('disabled');
		   		$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"LoadModelVehicle":"LoadModelVehicle",'make':selectedOption.value
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Models: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedMake: selectedOption.value });
                    $('#homesearchbutton').html('Search now').removeClass('disabled');
                  }
               );  

			}
			handleModelChange= getValue=>{
				$('#homesearchbutton').html('<span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span><span className="text-white">Years Loading</span>').addClass('disabled');
		   		
				var self=this;

				$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"LoadYearVehicle":"LoadYearVehicle",make:self.state.selectedMake,model:getValue.value
   				  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Years: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedModel: getValue.value });
                    $('#homesearchbutton').html('Search now').removeClass('disabled');
                  }
               	); 
					self.setState({selectedModel:getValue.value});
			}

			handleZipcode= GetZipCode=>{
				var self=this;
				GetZipCode.persist();
				self.setState({zipcode:GetZipCode.target.value});

			}

			handleYearChange= GetYear=>{
				var self=this;
				console.log(GetYear.value);
				self.setState({selectedYear:GetYear.value});

			}
			LoadMore()
			{
				var data_query={};
				var self=this;
				var CurrentCars=$('.loadmore').length;
				
				var array=self.state.items;
				 if(self.state.zipcode!=null)
				 {
				 	data_query['zipcodes']=self.state.zipcode;
				 
				 }else
				 {
					
				 	data_query['filterDataModel']=self.state.selectedModel;
				 	data_query['filterDataMakes']=self.state.selectedMake;
				 	data_query['filterDataYear']=self.state.selectedYear;
				 }
				 data_query['startpoint']=CurrentCars;
				 data_query['dataloadmore_new']=CurrentCars;
				 $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
				$.post(
                  "https://www.shopsmartautos.com/react_api.php",data_query,
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    		 var children = array.concat(getResponse.data); 

                    var CurrentCarsTotal=self.state.currentcount+getResponse.data.length;
                      self.setState({ items: children });
                     self.setState({ totalcount: getResponse.num_found.NumRows });
                      self.setState({ currentcount: CurrentCarsTotal });
                     $('#btn-one').html('Load More').removeClass('disabled');
                  }
               );
			}
					FormValidations=getValidate=>{
				var self=this;
				var Make=self.state.selectedMake;
				var Model=self.state.selectedModel;
				var Year=self.state.selectedYear;
				var zipcode=self.state.zipcode;
				var Messages={};
				if(Make==null)
				{
					Messages['make']='Please select the  Make!';
					self.setState({errors:Messages});
					return false;
				}else if(Model==null)
				{
					Messages['model']='Please select the Model!';
					self.setState({errors:Messages});
					return false;
				}else if(Year==null)
				{
					Messages['year']='Please select the Year!';
					self.setState({errors:Messages});
					return false;
				}else
				{
					self.setState({errors:''});
					return true;
				}

				
			}

			FormValidationsZipcode=getValidate=>{
				var self=this;
				var zipcode=self.state.zipcode;
				var Messages={};
				if(zipcode==null)
				{
					Messages['zipcode']='Please fill zipcode !';
					self.setState({errors:Messages});
					return false;
				}else
				{
					self.setState({errors:''});
					return true;
				}

				
			}

				handleSearchMakeModel=MakeModelZipSearch=>{
				MakeModelZipSearch.persist();
				var self=this;
				var filterDataMake=self.state.selectedMake;
				var filterDataModel=self.state.selectedModel;
				var filterDataYear=self.state.selectedYear;
				self.setState({zipcode:null})				
				if(self.FormValidations())
				{
					  self.setState({ isLoaded: false });
					 var getStringPara={'filterDataMake':filterDataMake,'filterDataModel':filterDataModel,'filterDataYear':filterDataYear,'SearchByMakeModelYearVehicle':'SearchByMakeModelYearVehicle'};
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  getStringPara,
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.data });
                    self.setState({ totalcount: getResponse.num_found.NumRows });
                     self.setState({ currentcount: getResponse.data.length });
                    self.setState({ isLoaded: true });

                  }
               	); 
				}

			}
			handleSearchZipcode=handleSearchZipcode=>{
				handleSearchZipcode.persist();
				var self=this;
				var zipcode=self.state.zipcode;
				self.setState({selectedMake:null});
				self.setState({selectedModel:null});
				self.setState({selectedYear:null});
					
				if(self.FormValidationsZipcode())
				{
					  self.setState({ isLoaded: false });
					 var getStringPara={'zipcode':zipcode,'SearchByZipcodeVehicle':'SearchByZipcodeVehicle'};
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  getStringPara,
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.data });
                     self.setState({ totalcount: getResponse.num_found.NumRows });
                     self.setState({ currentcount: getResponse.data.length});
                    self.setState({ isLoaded: true });

                  }
               	); 
				}

			}
				
			handleVehicleData= handleVehicleData=>{
    			  var self=this;
                  self.setState({VehicleDataInfo:handleVehicleData})
                  var data={
                  		'id':handleVehicleData['DealerID']
                  	
                  }
                  handleVehicleData['dealer']=data;
                  localStorage.setItem("VehicleDataInfo", JSON.stringify(handleVehicleData));
                  
                }
render() {
		const { selectedOption } = this.state;
		const { error, isLoaded, items } = this.state;
		var Makes = this.state.Makes;
		var Models = this.state.Models;
		var Years = this.state.Years;
		const href=window.location.href;
		const url=window.location.host;

		    if (error) {
		      return <div>Error: {error.message}</div>;
		    } else if (!isLoaded) {
		      return <Spinner/>;
		    } else {
		      return (
		      	<div >
		      	 <SEO
					  title={'Special Deals-Shopsmartautos'}
					  titvaremplate={'shopsmartautos'}
					  titleSeparator={'-'}
					  description='THE EASIEST WAY TO BUY YOUR NEW CAR-Must-Know Advice from our Experts '
					  image={'../media/certifiedcars.jpg'}
					  pathname={href}
					  siteLanguage={'en-US'}
					  siteLocale={'en-US'}
					  twitterUsername={'shopsmartautos'}/>

		      	<header className="masthead">
		      	<div className="Overalay">
  						<div className="container h-100">
	    					<div className="row h-100 align-items-center">
	      						<div className="col-12 text-center">
	        						<h1 className="heading">THE EASIEST WAY TO BUY YOUR BEST VEHICLES</h1>
	        						<p className="lead">Must-Know Advice from our Experts</p>
	      						</div>
	    					</div>
  						</div>
  						</div>
				</header>

  				<Testdrive/>
				<div className="container"><Newsletter/></div>
				<div className="col-lg-12">

					<h4 className="searchResults">Searching results showing ({this.state.currentcount.toLocaleString()}) of ({this.state.totalcount.toLocaleString()}) </h4>
				</div>
				<div className="clearfix"></div>
				<div className="container-fluid">
			        <div className="row vechilePage newcarss">
						<div className="col-lg-3 ">
						<div className="card">
							<div className="form-group ">
							<label> Search by zipcode</label>
							<input type="text" className="form-control" id="zipcode" name="zipcode" onChange={this.handleZipcode} />
							<p style={{color:'red'}}>{this.state.errors['zipcode']}</p>
							</div>
							<div className="form-group ">
							<button className="btn btn-primary" onClick={this.handleSearchZipcode}>Search By zipcode</button>
							</div>
							<div className="form-group">
							<label> Sealect  Make</label>
								  <Select
	       								 value={selectedOption}
	        								onChange={this.handleChange}
	        								options={Makes}/>
							<p style={{color:'red'}}>{this.state.errors['make']}</p>
							</div>
							<div className="form-group">
							<label> Select Model</label>
								  <Select
	       								 value={selectedOption}
	        								onChange={this.handleModelChange}
	        								options={Models}/>
							<p style={{color:'red'}}>{this.state.errors['model']}</p>
							</div>
							<div className="form-group">
							<label> Select Year</label>
								  <Select
	       								 value={selectedOption}
	        								onChange={this.handleYearChange}
	        								options={Years}/>
							<p style={{color:'red'}}>{this.state.errors['year']}</p>
							</div>
								<div className="form-group ">
							<button className="btn btn-primary waves-effect waves-light" id="homesearchbutton" onClick={this.handleSearchMakeModel}>Search now</button>
							</div>
						</div>

						</div>
						{items=='' ? (<Notfound/>):(
						<div className="col-lg-9">
			        		 <div className="row appendData">  
			             		    {items.map(item => (
									<div className="col-lg-9 loadmore ribbon"  key={item.id}>
									{item.conditon ?(<span class="ribbon1"><span>{item.conditon}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)  
									}
									<div className="">
									 <div className="car-box-3 row customrow">
        <div className="col-lg-4  col-results">
          <Link target="_blank" to={"/car-view/?id="+ btoa(item.id)}>
            	<div className="badge-overlay">
				<span className="top-left badge oranges">{item.Conditions}</span>
				</div>
            <img className="d-block w-100" src={"https://img.leaddelivery.net/images/"+item.VIN+"/Main/"+item.Images.split("|")[0]+".jpg?s=0000-4525"} alt="car" />
          </Link>
          <ul className="social-icons-lists icons-background-square" style={{marginTop: '18px'}}>
             <li className="social-icons-list-item">
                <a  target="_blank" href={"https://twitter.com/intent/tweet?url="+url+"/car-details/?id="+btoa(item.id)} className="social-icon" target="_blank">
                  <span className="fa fa-twitter" style={{padding: '10px', fontSize: '16px', backgroundColor: '#4da7de'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://www.facebook.com/sharer.php?u="+url+"/car-details/?id="+btoa(item.id)} target="_blank" className="social-icon">
                  <span className="fa fa-facebook" style={{padding: '10px', fontSize: '16px', backgroundColor: '#3e5b98'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a  target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url="+url+"/car-details/?id="+btoa(item.id)} target="_blank" className="social-icon">
                  <span className="fa fa-linkedin" style={{padding: '10px', fontSize: '16px', backgroundColor: '#0078b9'}} />
                </a>
              </li>
              <li className="social-icons-list-item">
                <a target="_blank" href={"https://pinterest.com/pin/create/button/?url="+url+"/car-details/?id="+btoa(item.id)} target="_blank" className="social-icon">
                  <span className="fa fa-pinterest" style={{padding: '10px', fontSize: '16px', backgroundColor: '#e02a20'}} />
                </a>
              </li>
            </ul>
             <img data-toggle="modal" data-target="#centralModalInfoDemo" onClick={() =>{this.handleVehicleData(item)}} width="75%"  src="../media/testdrive.png" className="p-4"/>
        
        </div>
        <div className="col-lg-4 usedclas card-body">
          <p className="vehicle-name">{item.Make+' '+item.Model+' '+item.Style}</p>
          <p className="miles-style" style={{fontWeight: '600!important', marginBottom: '0px'}}>{item.Mileage} Miles</p>
          <ul className="descriptions">
            <li><strong> Body Type: </strong>{item.Body_Type}</li>
            <li><strong>Exterior Color: </strong>{item.ExteriorColor}</li>
            <li><strong>Doors: </strong>{item.Doors}</li>
            <li><strong>Style: </strong>{item.Style}</li>
            <li><strong>Trim: </strong>{item.Trim}</li>
            <li><strong>Vehicle Type: </strong>{item.VehicleCategory}</li>
          </ul></div>
        <div className="col-lg-4">
          <div className="inventory-right card-body">
            <ul className="descriptions">
              <li>
                <strong className="price_used">${item.price ? (item.price.toLocaleString()):(item.SellingPrice.toLocaleString())}</strong><small className="smalls">Listing price</small>
              </li> 
              <li>
                VIN # :{item.VIN}
              </li>
              <li className="location-tag"> {item.City+', '+item.State}
        	</li>
        	<li className="location-tag"> {item.ratting ? ("KBB.com Expert Ratings"):('')}
              {item.ratting ? (<img style={{height: '70px'}} data-src="/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" className=" ls-is-cached lazyloaded" src="https://shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
        	</li>
        	<li>
        	{item.ratting ?('Overall Rating'):('')}
        	</li>
            <li><small><div dangerouslySetInnerHTML={{ __html: item.ratting ?(item.ratting):('') }} />{item.value ?(<strong>{item.value}/5</strong>):('')}</small></li>
            </ul>
            <p><Link target="_blank" className="btn btn-primary" to={"/car-view/?id="+btoa(item.id)}>More Details</Link></p>
            
          </div>
        </div>
      </div>
									</div>
									</div>
									))}
			            	</div>
			            	
			         </div>
			        		 )}
			         <div className="col-lg-12 text-center">
			         		{this.state.currentcount<this.state.totalcount ? (
			         		<button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
			         ):('')} </div>
			        </div>
		       		 </div>
		       		 <Footer/>
		       		 </div>
		       
		      )
		    }
		  }
		}

export default Vehicles;