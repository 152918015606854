import React from 'react';

import $ from 'jquery';
import '../App.css';
import './app.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import SEO from 'react-seo-component';


class comparecar extends React.Component {
			constructor(props){
      super(props)
      this.state ={
         errors:[],
         formValid:false,
         zipcode:null,
         vin:null,
         error: null,
          isLoaded: false,
          fetchData: [],
          vehicleClass:'new',
          SpecValue:0,
          selectedModel:null,
          selectedMake:null,
          Makes:null,
          Models:null,
          Years:null,
          selectedYear:null,
          VehicleData:[],
          cDataArr:[],
          responseError:'',
          EmtyError:'',
          latitude:null,
          longitude:null,
            }
    this.handleVehcileCompare = this.handleVehcileCompare.bind(this)
    this.handleCancelCar = this.handleCancelCar.bind(this)
    this.FormValidations = this.FormValidations.bind(this)
        }
componentDidMount (){
         var self = this;
         var url_string = window.location.href;
         var url = new URL(url_string);
         var Vehicle_id = url.searchParams.get("id");  
         var zipcode = url.searchParams.get("zipcode");  
         var arr=[];        
         var carData={};        
         var cData={};        
         var allData=[];        
         var cDataArr=[];        
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "VehcileCompareDataResult":"VehcileCompareDataResult",'Vehicle_id':Vehicle_id,zipcode:zipcode
                  },

                  function(data) {
                  var getResponse=JSON.parse(data);
                  if(getResponse.vehcileListingData!=null)
                  {
                  arr.push(getResponse);
                  if(getResponse.vehcileListingData.hasOwnProperty('media'))
                  {
                     
                        carData['image'] =getResponse.vehcileListingData.media.photo_links[0];
                  }else
                  {
                       carData['image'] ='../media/default-car.png';
                  }
                 
                 carData['id'] =getResponse.vehcileListingData.id;
                 carData['heading'] =getResponse.vehcileListingData.heading;
                 carData['price'] =getResponse.vehcileListingData.price;
                 carData['prices'] =getResponse.vehcileListingData.prices;
                 carData['ratting'] =getResponse.ratting;
                 carData['value'] =getResponse.value;
                 allData.push(carData);

                    for (var carDatai =0; carDatai<getResponse.CompareData.items.length; carDatai++) {
                cData[getResponse.CompareData.items[carDatai].specId]=[getResponse.CompareData.items[carDatai]];
                      
                    }
                  
                    self.setState({ VehicleData: arr });
                    self.setState({ fetchData: allData });
                    self.setState({ isLoaded: true });
                    self.setState({ cDataArr: cData });
                    self.setState({ EmtyError: '' });
                  }else
                    {
                      self.setState({ isLoaded: true });
                      self.setState({ EmtyError: 'Vehicle currently not available to compare ' });
                    }
                }
               );

        var self = this;       
        navigator.geolocation.getCurrentPosition(function(position) {
          self.setState({latitude:position.coords.latitude,longitude:position.coords.longitude});
          
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadMakeResearch":"LoadMakeResearch",latitude: position.coords.latitude,longitude:position.coords.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Makes: getResponse });
                  
                  }
               ); 
                }, (error)=>{
          self.setState({latitude:40.94623,longitude:-73.069777});
          
                 $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadMakeResearch":"LoadMakeResearch",latitude:40.94623,longitude:-73.069777
                  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Makes: getResponse });
                  
                  }
               );
          })
    }

handleCancelCar=(getvalue)=>{
  var self=this;
  var getData=self.state.fetchData;
  var cDataArr=self.state.cDataArr;
 getvalue.persist();
Object.values(cDataArr).map(key=>(
key.splice(getvalue.target.id, 1)
  ))
  getData.splice(getvalue.target.id, 1);
   self.setState({SpecValue:self.state.SpecValue-1})
  self.setState({fetchData:getData});
  self.setState({cDataArr:cDataArr});
}


handleVehcileCompare()
{
                var self=this;
                var make=self.state.selectedMake;
                var model=self.state.selectedModel;
                var Year=self.state.selectedYear;
                var SpecValue=self.state.SpecValue;

                var url_string = window.location.href;
                var url = new URL(url_string);
                    var zipcode = url.searchParams.get("zipcode");  
                self.setState({SpecValue:SpecValue+1})
              $('#btncompare').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
            
             if(self.FormValidations())
              {  self.setState({isLoaded:false})
            
             var settings = {
              "url": "https://www.shopsmartautos.com/react_api.php",
              "data":{'VehcileCompareDatas':'VehcileCompareData','make':make,'model':model,'Year':Year,zipcode:zipcode},
              "method": "POST",
              "timeout": 0,
              "dataType":"json",
                    };
             self.setState({responseError:''})
                 
              $.ajax(settings).done(function(getData){
               console.log(getData);
              var carData={};        
                    
               var allData=self.state.fetchData;        
               var cDataArr=[]; 
               var arr=[];  

                if (getData!=null) {

                    if(getData.vinResults!='')
                     {
                      
                        $.post(
                                "https://www.shopsmartautos.com/react_api.php",
                                {
                                  "VehcileCompareDataResult":"VehcileCompareDataResult",'Vehicle_id':getData.vinResults[0].vehicleId,zipcode:zipcode
                                },
                          function(data) {
                            var carDatas={};        
               var cData=self.state.cDataArr !=='' ? self.state.cDataArr :carDatas;  
                          var getResponse=JSON.parse(data);
                          
                          arr.push(getResponse);
                      if(getResponse.vehcileListingData!==null )
                          {
                      $('#modalCart').hide();
                      $('.modal-backdrop').hide();

                   carData['image'] =getResponse.vehcileListingData.media!==undefined ? (getResponse.vehcileListingData.media.photo_links[0]):('../media/default-car.png');
                   carData['id'] =getResponse.vehcileListingData.id;
                   carData['heading'] =getResponse.vehcileListingData.heading;
                   carData['price'] =getResponse.vehcileListingData.price;
                   carData['prices'] =getResponse.vehcileListingData.prices;
                   carData['ratting'] =getResponse.ratting;
                   carData['value'] =getResponse.value;
                   allData.push(carData);
                  var getDataSpec='';
                  var getDataSpecKey='';
                
                    for (var carDatai =0; carDatai<getResponse.CompareData.items.length; carDatai++) {
                      console.log('check');
                         console.log(cData);
                     if(cData!=='')
                     {
                        getDataSpec= cData[getResponse.CompareData.items[carDatai].specId];
                     }else
                     {
                        getDataSpec= cData[getResponse.CompareData.items[carDatai]];

                     }
                     console.log('compare');
                       console.log(getDataSpec);
                     
                  getDataSpecKey=  getResponse.CompareData.items[carDatai].specId;
 
                     if(getDataSpec !==undefined && getDataSpecKey !==undefined)
                     {
                      
                    getDataSpec[self.state.SpecValue]=getResponse.CompareData.items[carDatai];
                    cData[getDataSpecKey]=getDataSpec;
                       
}
                 console.log('compare');
                       console.log(cData);     
                    }
                  console.log(cData);
                    self.setState({ VehicleData: arr });
                    self.setState({ fetchData: allData });
                    self.setState({ isLoaded: true });
                    self.setState({ cDataArr: cData });
                    self.setState({responseError:''})
                    self.setState({ EmtyError: ''});
                 
$('#btncompare').html('Compare').removeClass('disabled');
              
                }else
                {
                  self.setState({responseError:'Vehicle currently not available to compare. Please try with anothe year or model'})
                 $('#btncompare').html('Compare').removeClass('disabled');
              
                 }}
               );
                }else if(getData.warnings!=null)
                  {
                        self.setState({responseError:getData.warnings[0].message});
                        $('#btncompare').html('Compare').removeClass('disabled');
              
                }else
                {
                    self.setState({responseError:'Vehicle currently not available to compare. Please try with anothe year or model'})
                    $('#btncompare').html('Compare').removeClass('disabled');
              
                }
              }else
                {
                    self.setState({responseError:'Vehicle currently not available to compare. Please try with another year or model'})
                    $('#btncompare').html('Compare').removeClass('disabled');
              
                }
              })
               self.setState({isLoaded:true})
                 
}}

		 handleChange = selectedOption => {
        var self = this;
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadModelResearch":"LoadModelResearch",make:selectedOption.value,latitude: self.state.latitude,longitude:self.state.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Models: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedMake: selectedOption.value });
                  }
               ); 

      }

FormValidations=getValidate=>{
        var self=this;
         var make=self.state.selectedMake;
         var model=self.state.selectedModel;
         var Year=self.state.selectedYear;

        var Messages={};
        if(make==null)
        {
          Messages['make']='Please select the  make!';
          self.setState({errors:Messages});
          return false;
        }else if(model==null)
        {
          Messages['model']='Please select the Model!';
          self.setState({errors:Messages});
          return false;
        }else if(Year==null)
        {
          Messages['year']='Please select year!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }
      handleModelChange= getValue=>{
        var self=this;
          self.setState({selectedModel:getValue.value});
          var self = this;           
        // get makes accordig to curretn position
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadYearResearch":"LoadYearResearch",make:self.state.selectedMake,model:getValue.value,latitude: self.state.latitude,longitude:self.state.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Years: getResponse });
                  
                  }
               ); 
      }

       handleChangeYears= getValue=>{
        var self=this;
          self.setState({selectedYear:getValue.value});
      }

		 render() {
      var self=this;
      const getsVehicleData=self.state.VehicleData;
      const isLoaded=self.state.isLoaded;
      if(!isLoaded)
      {
        return <Spinner/>;
      }else
      {
      const fetchData=self.state.fetchData;

      var arr_addvehicl={};
        var getLength=parseInt(4-fetchData.length);
      for (var i = 0; i<getLength; i++) {
        arr_addvehicl[i]=i;
      }
     
        const  Makes  = this.state.Makes;
        const  Models  = this.state.Models;
        const  Years  = this.state.Years;
        const  cDataArr  = this.state.cDataArr;
        const url=window.location.host;
        const href=window.location.href;
		      return (
		      	<div >
            <SEO
  title={'Compare Resutls- Shopsmartautos'}
  titleTemplate={'Shopsmartautos'}
  titleSeparator={'-'}
  description='Compare your cars with best feature-Based on Blue Book® Values'
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
				<div className="text-center h2 my-5 pt-4"></div>
				<div className="container-fluid">
        <h1> Vehicle Compare Resutls</h1>
			        <div className="row vechilePage ">
              <div  className="col-lg-12">
			        		<div className="row">
                  {this.state.EmtyError=='' ?                   
                  this.state.fetchData.map((keys,i)=>(
                    <div className="col-lg-3">

                      <div className="card">
                      {i!==0 ?( <span id={i} onClick={this.handleCancelCar} className="spanCroxx">×</span>):('')}
                       
                        <img src={keys.image} className="card-img-top"/>
                        <div className="card-body">
                        <h5 className="card-title">{keys.heading}</h5>
                        <h4>{
                            keys.prices!=undefined
                              ?
                              '$'
                              :
                              keys.price !=null
                              ?
                              '$'
                              :
                               ''

                              }
                            {
                                    keys.prices!=undefined
                              ?
                              keys.prices.toLocaleString() 
                              :
                              keys.price !=null
                              ?
                              keys.price.toLocaleString()
                              :
                               'NA'
                            }</h4>
                        <p>{keys.ratting ?('KBB Expert Rating'):('') }

                         <div dangerouslySetInnerHTML={{ __html: keys.ratting ?(keys.ratting):('') }} />{keys.value ? (keys.value+'/5'):('')}</p>
                        <Link to={"/car-details/?id="+keys.id} className="btn btn-primary">More Details</Link>
                      </div>
                    </div>
              </div>
  )
) :('')}

{  Object.values(arr_addvehicl).map(key=>(
<div className="col-lg-3">
                      <div className="card">
                      
                        <div className="card-body">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalCart">Add Vehicle</button>
                      </div>
                    </div>
              </div>

  ))}

              </div>
			   <div className="card mb-5">
            <div className="card-body">
              <table className="table table-responsive-md">
                
                <tbody>
                { this.state.EmtyError=="" ? Object.values(this.state.cDataArr).map(key=>(
  <tr>
  <th style={{width:"1%"}}><strong>{key[0].specName}</strong></th>



{
  key.map(getkey=>(
<td style={{width:"1%"}}>{getkey['specValue'] !==undefined ? (getkey['specValue']):('Not available')} {getkey['units']}</td>

  ))
}



  </tr>

                  )):<tr><td>{this.state.EmtyError}</td></tr>}
                </tbody>
              </table>
            </div>
          </div>		 
			         </div>			       
		       	</div>
            </div>
             {/* Button trigger modal*/}

        {/* Modal: modalCart */}
        <div className="modal fade" id="modalCart" tabIndex={-1} role="dialog" aria-labelledby="shopsmartautos" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content newcarss">
              {/*Header*/}
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">Select Vehcile</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {/*Body*/}
              <div className="modal-body">
                <div className="form-group">
              <label> Select  Make</label>
                 <Select name="make"

                          onChange={this.handleChange}
                          options={Makes}/>
                          <p style={{color:"red"}}>{this.state.errors['make']}</p>
              </div>
               <p className="error_make" />
            
            <div className="form-group">
              <label> Select Model</label>
                   <Select name="model"
                          onChange={this.handleModelChange}
                          options={Models}/>
                      <p style={{color:"red"}}>{this.state.errors['model']}</p>
              </div>
            <div className="form-group">
              <label> Select  Year</label>
                 <Select name="make"

                          onChange={this.handleChangeYears}
                          options={Years}/>
                          <p style={{color:"red"}}>{this.state.errors['year']}</p>
              </div>
            <p style={{'color':'red'}} className="error_genral">{this.state.responseError}</p>
            </div>
              
              {/*Footer*/}
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                <button className="btn btn-primary" onClick={this.handleVehcileCompare} id="btncompare" >Compare</button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal: modalCart */}
		       <Footer/>
		     </div>
		       
		      )
		    
		  }
		}}

export default comparecar;