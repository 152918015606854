import React from 'react';
/* Select tag import*/
import Select from 'react-select';
import '../App.css';
import $ from 'jquery';
import '../mdb.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from './spiner';
import SectionSpiner from './sectionspinner';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

class Topcars extends React.Component {


  constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: [],
          count:0,
          slides:0,
          vehicleresponseError:null,
        };
        this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
      }
      next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
	componentDidMount() {
         var self = this;
         navigator.geolocation.getCurrentPosition(function(position) { 
          self.setState({latitude: position.coords.latitude,longitude: position.coords.longitude,});
        
       $.post(
                  "https://www.shopsmartautos.com/car_submit.php",
                  {lattd:position.coords.latitude,longtd:position.coords.longitude,top_deals:"top_deals"
             },
                  function(data) {

                  var getResponse=JSON.parse(data);
                  if(getResponse.num_found!==0)
                  {
                    self.setState({ items: getResponse.listings });
                    self.setState({ isLoaded: true });
                  }else
                  {
                    self.setState({ vehicleresponseError:'No best deals are available to your location' });
                    
                      $.post(
                            "https://www.shopsmartautos.com/car_submit.php",
                            {lattd:40.94623,longtd:-73.069777,top_deals:"top_deals"
                       },
                            function(data) {
                            var getResponse=JSON.parse(data);
                             
                                  self.setState({ items: getResponse.listings });
                                  self.setState({ isLoaded: true });
                                
                            }
                         );

                  }
                }
               ); 
       }, (error)=>{


           $.post(
                            "https://www.shopsmartautos.com/car_submit.php",
                            {lattd:40.94623,longtd:-73.069777,top_deals:"top_deals"
                       },
                            function(data) {
                            var getResponse=JSON.parse(data);
                              if(getResponse.num_found!==0)
                                {
                                  self.setState({ items: getResponse.listings });
                                
                                  self.setState({ vehicleresponseError:null});
                                  self.setState({ isLoaded: true });
                                }else
                                { 

                                  self.setState({ vehicleresponseError:'No best deals are available to your location' });
                                  self.setState({ isLoaded: true });
                                }
                            }
                         ); 

                  });
                }
  render() {

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
      const { error, isLoaded, items,slides } = this.state;
              var getSlides= items.map(function(item, il)
                {
                  
              })


      if (error) {
          return <div> {this.state.vehicleresponseError}</div>;
        }else {
  return  <section id="products" className="pb-5 mt-4">
       
          
        <h1 className="h1-responsive dark-grey-text font-weight-bold my-5 text-center">
          <strong>Today Best Deals</strong>

        </h1>

        <div  className="carousel slide carousel-multi-item" data-ride="carousel">

          {this.state.items.length !==0 ? (
          <div className="controls-top">
            <button type="button" className="btn-floating primary-color waves-effect waves-light" onClick={this.previous}>
              <i className="fas fa-chevron-left" />
            </button>
            <button className="btn-floating primary-color waves-effect waves-light" onClick={this.next}>
              <i className="fas fa-chevron-right" />
            </button>

          </div>
          ):('')}
<small className="text-center h1-responsive dark-grey-text font-weight-bold my-5 text-center">{this.state.vehicleresponseError !==null ? this.state.vehicleresponseError :('')}</small>
        
           {!isLoaded ? (<SectionSpiner/>):(
          <Slider ref={c => (this.slider = c)} {...settings}>

           {items.map(item=>(
          <div className="topCarsCustom" key={item.id}>
          <div className="cdTagBox"><span className=" cdTag"><i className="fa fa-thumbs-up" aria-hidden="true"></i> {item.conditons!=null ? (item.conditons):('Recomanded')}</span></div>
          <div className="cdTagBox bo">{item.ratting ?(<img height="110px" width="60px" style={{height: '70px', width: 'auto', marginLeft: '157px', position: 'absolute'}}  className=" ls-is-cached lazyloaded" src="https://www.shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}</div>
            <div className="">

        <div className="card card-ecommerce">
          <div className="view overlay">

          <img src={item.media!==undefined ? (item.media.photo_links[0]):('../media/default-car.png')} className="img-fluid" alt="" />
            

             <Link to={"/car-details/?id="+item.id} className="dark-grey-text">  <div className="mask rgba-white-slight waves-effect waves-light" /></Link>
          </div>
          <div className="card-body">
          <ul className="custom_ratting">
            <li>

               <h5 className="card-title mb-1">
              <strong>
                <Link to={"/car-details/?id="+item.id} className="dark-grey-text">{
        
        item.price!=undefined
          ?
          '$'
          :
          item.prices !=null
          ?
          '$'
          :
           ''

          }
        {
                item.prices!=undefined
          ?
          item.prices.toLocaleString()
          :
          item.price !=null
          ?
          item.price.toLocaleString()
          :
           'NA'
        }


                </Link>
              </strong>
            </h5>
            </li>
            <li>
                <h5 className="card-title mb-1">
              <strong>
                 <p><i className="fa fa-map-marker"></i>  {item.dealer.name} far from you only {item.dist} Miles</p>
              </strong>
            </h5>
            </li>
            <li>
            <h5 className="card-title mb-1">
              <strong>
                 <p>{item.heading}</p>
              </strong>
            </h5>
            </li>
            <li>
            <strong>{item.ratting ? ('KBB.com Expert Ratings'):('')}</strong>
            </li>
            <li>
            <strong>{item.ratting ? ('Overall Rating'):('')}</strong>
            </li>
            <li>
            <div dangerouslySetInnerHTML={{ __html: item.ratting ?(item.ratting):('') }} />
              {item.value ? (item.value +'/5'):('')}
            </li>
          </ul>
            <div className="card-footer pb-0">
              <div className="row mb-0">
              <Link to={"/car-details/?id="+item.id} className="btn btn-outline-warning waves-effect custom">More details</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
          </div>
          ))}
        </Slider>
)}
        </div>
      </section>
  ;
  }}
}
export default Topcars;