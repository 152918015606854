import React from 'react';

import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import Notfound from './notfound';


class blogs extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      error: null,
		      isLoaded: false,
		      items: [],
		      vehicleClass:'new',
		      rangeValue:0,
		      selectedModel:null,
		      selectedMake:null,
		      zipcode:null,
		      errors:[],
		      formValid:false,
		       totalcount:0,
		      currentcount:10,
		      posts:null,

		    };
		    this.LoadMore = this.LoadMore.bind(this)
		  }



 componentDidMount (){
// get Vehicles

				var self = this;
			// Get User current position
			$.post(

                  "https://blog.shopsmartautos.com/react_api.php",
                  {

                    "ALL_POSTS":"ALL_POSTS"   },
                  function(data) {
                    var getResponse=JSON.parse(data);
                    self.setState({ posts: getResponse });
                    self.setState({ isLoaded: true });
                  
                  }
                  ); 

		}
		
		
			LoadMore()
			{
				
				var self=this;
				var currentblogs=$('.loadmore').length;
				var array=self.state.posts;
				console.log(currentblogs);
				 $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
				$.post(
                  "https://blog.shopsmartautos.com/react_api.php",
                  {'ALL_POSTS_LOADMORE':'ALL_POSTS_LOADMORE','startpoint':currentblogs},
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    var children = array.concat(getResponse); 
                       self.setState({ posts: children });
                     
                     $('#btn-one').html('Load More').removeClass('disabled');
                  }
               );
				

			}
				
		 render() {
		    const { error, isLoaded, items } = this.state;
		 	
  		const  posts  = this.state.posts;
  		
		    if (error) {
		      return <div>Error: {error.message}</div>;
		    } else if (!isLoaded) {
		      return <Spinner/>;
		    } else {
		      return (
		      	

		      	<div >
		      	
		      	<header className="canadanewcars">
		      	<div className="Overalay">
  						<div className="container h-100">
	    					<div className="row h-100 align-items-center">
	      						<div className="col-12 text-center">
	        						<h1 className="heading">THE EASIEST WAY TO BUY YOUR NEW CAR</h1>
	        						<p className="lead">Must-Know Advice from our Experts</p>
	      						</div>
	    					</div>
  						</div>
  						</div>
				</header>
				<div className="feature-blog w3eden">
      				<div className="">
         				<div className=""><h2 className="feature-title" style={{textAlign: 'center'}}>Feature Blogs</h2></div>
         				<p>&nbsp;</p>
      				</div>
   				</div>
				<div className="clearfix"></div>
				<div className="container-fluid blog">
			         <div className="row">
   {Object.values(posts).map(keys=> (
<div className="col-lg-3 loadmore">
        <div className="card">
          
              <div className="">
                <img src={keys.featured[0]} className="card-img-top" alt=""/>
                <a>
                  <div className="mask waves-effect waves-light"></div>
                </a>
              </div>
             
              <div className="card-body">              
                <h4 className="card-title">{keys.post_title.slice(0, 59)}..</h4>
                <hr/>
                
                <p className="card-text" dangerouslySetInnerHTML={{ __html:keys.post_content.slice(0, 200)}}/>
                <Link to={"/post/"+keys.post_name} className="black-text">
                  <h5>Read more <i className="fas fa-chevron-right"></i></h5>
                </Link>
              </div>
          
         </div>
         </div>






   ))}
      
        <div className="col-lg-12 text-center">
			         		<button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
			         </div>
         </div>
		       		 </div>
		       		 <Footer/>
		       		 </div>

		       
		      )
		    }
		  }
		}

export default blogs;