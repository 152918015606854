import React from 'react';
import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Notfound from './notfound';
import Map from './map';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import SEO from 'react-seo-component';
import {Loader, LoaderOptions} from 'google-maps';
class dealertrack extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: true,
          items: [],
          vehicleClass:'new',
          rangeValue:0,
          selectedModel:null,
          selectedMake:null,
          zipcode:null,
          errors:[],
          formValid:false,
          MaxPrice:100,
          Makesearch:null,
          totalcount:0,
          currentcount:0,
          FirstName:null,
          LastName:null,
          email:null,
          PhoneNumber:null,
          Comment:null,
          TestDriveDate:null,
          MessageStatus:'',
          TestDriveTime:null,
          TestDriveTc:1,
          VehicleDataInfo:null,
          handleVehicleData:null

        };
        this.handlesubmitTc = this.handlesubmitTc.bind(this)
      }

      handleZipcode= GetZipCode=>{
        var self=this;
        GetZipCode.persist();
        self.setState({zipcode:GetZipCode.target.value});

      }

      handlesubmitTc(){
        var self=this;
        var FirstName= self.state.FirstName;
        var LastName= self.state.LastName;
        var email= self.state.email;
        var PhoneNumber= self.state.PhoneNumber;
        var Comment= self.state.Comment;
        var TestDriveDate= self.state.TestDriveDate;
        var TestDriveTime= self.state.TestDriveTime;
        var TestDriveTc= self.state.TestDriveTc;
        var VehicleDataInfo= JSON.parse(localStorage.getItem("VehicleDataInfo"));
        
        if(self.Form2Validations())
        {
          


           var getStringPara={'FirstName':FirstName,'LastName':LastName,'email':email,'PhoneNumber':PhoneNumber,'Comment':Comment,'TestDriveDate':TestDriveDate,'TestDriveTime':TestDriveTime,'TestDriveTc':TestDriveTc,vin:VehicleDataInfo.vin,mc_id:VehicleDataInfo.id,dealer_id:VehicleDataInfo.dealer.id};
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "TestDriveRequest":"true",getStringPara:getStringPara
             },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  
                  if(getResponse.status)
                  {
                    self.setState({MessageStatus:'<div class="alert alert-success border-green"><i class="fa fa-check"></i> Thanks for the request</div>'})

                  }else
                  {
                    self.setState({MessageStatus:'<div class="alert alert-danger border-red">Try again..</div>'})

                  }
                  
                  }
                );
        }
          
      }

      Form2Validations=getValidate=>{
        var self=this;
        var FirstName= self.state.FirstName;
        var LastName= self.state.LastName;
        var email= self.state.email;
        var PhoneNumber= self.state.PhoneNumber;
        var Comment= self.state.Comment;
        var TestDriveDate= self.state.TestDriveDate;
        var TestDriveTime= self.state.TestDriveTime;
        var TestDriveTc= self.state.TestDriveTc;

        var Messages={};
        if(FirstName==null)
        {
          Messages['FirstName']='Please select the  First Name!';
          self.setState({errors:Messages});
          return false;
        }else if(LastName==null)
        {
          Messages['LastName']='Please select the Last Name!';
          self.setState({errors:Messages});
          return false;
        }else if(TestDriveDate==null)
        {
          Messages['TestDriveDate']='Please select the Test Drive Date!';
          self.setState({errors:Messages});
          return false;
        }else if(TestDriveTime==null)
        {
          Messages['TestDriveDate']='Please select the Test Drive Time!';
          self.setState({errors:Messages});
          return false;
        }else if(TestDriveTc==null)
        {
          Messages['TestDriveTc']='Please select the Test Drive Term & condition!';
          self.setState({errors:Messages});
          return false;
        }else if(Comment==null)
        {
          Messages['Comment']='Please select the Comment!';
          self.setState({errors:Messages});
          return false;
        }else if(PhoneNumber==null)
        {
          Messages['PhoneNumber']='Please select the Phone Number!';
          self.setState({errors:Messages});
          return false;
        }else if(email==null)
        {
          Messages['email']='Please fill up the email!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }


  handleFirstName= FirstName=>{
                  this.setState({FirstName:FirstName.target.value})
                }

    handleLastName= LastName=>{
                  this.setState({LastName:LastName.target.value})
                }
    handleemail= email=>{
                  this.setState({email:email.target.value})
                }
    handlePhoneNumber= PhoneNumber=>{
                  this.setState({PhoneNumber:PhoneNumber.target.value})
                }
    handleComment= Comment=>{
                  this.setState({Comment:Comment.target.value})
                }
    handleTestDriveDate= TestDriveDate=>{
                  this.setState({TestDriveDate:TestDriveDate.target.value})
                }
  handleTestDriveTime= TestDriveTime=>{
                  this.setState({TestDriveTime:TestDriveTime.target.value})
                }
    handleTC= tc=>{
                  this.setState({TestDriveTc:tc.target.value})
                }

    handleVehicleData= handleVehicleData=>{
            var self=this;
                  self.setState({VehicleDataInfo:handleVehicleData})
                }
  
     render() {
        const { error, isLoaded, items } = this.state;
      
      const  Makes  = this.state.Makes;
      const  Years  = this.state.Years;
      const  selectedMake  = this.state.selectedMake;
      const  Models  = this.state.Models;
      const { selectedOption } = this.state;
      const { selectedModel } = this.state;
      const { selectedYear } = this.state;
      const url=window.location.host;
      const href=window.location.href;

        if (error) {
          return <div>Error: {error.message}</div>;
        }else if (!isLoaded) {
          return <Spinner/>;
        } else {
          return (
           <div className="col-md-12 mb-4">
        {/* Modal: mdb-abandonedCart-hard */}
         <div className="modal fade " id="dealertrack" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-notify modal-primary" role="document">
          {/* Content */}
          <div className="modal-content">
            {/* Header */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            {/* Body */}
            <div className="modal-body p-0">
              <div className="col-lg-12">
              <div className="row">
<iframe src="https://suite.dtdrs.dealertrack.com/?accountId=7920414&dealerId=124360&vin=KNDJN2A23H7497727&price=13380&qaMode=true"  height="1131px" width="100%" frameborder="0" wmode="transparent"></iframe>
                  
              </div>
          </div>
          {/* Content */}
        </div>
      </div>
      </div>
      </div>
      </div>
      
          )
        }
      }
    }

export default dealertrack;