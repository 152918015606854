import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./Footer";
import $ from 'jquery';
import SEO from 'react-seo-component';
class Valuemycar extends React.Component {
          constructor(props){
      super(props)
      this.state ={
         errors:[],
         formValid:false,
         zipcode:null,
         mileage:null,
         email:null,
         phoneNumber:null,
         colorCar:null,
         condition:'good',
         vehicleId:null,
            }
        this.handleGetPriceData = this.handleGetPriceData.bind(this)
        this.handleSearchVin = this.handleSearchVin.bind(this)
    }

 componentDidMount() {
  var get=window.location.search.substr(1).split('=');
  if(get[0]!=='')
  {
  console.log(get);
  $('#VIN').val(get[1]);
  $('.seachByVin').click();
  }
 }
    handleCondition=getCondition=>{
      var self=this;
        self.setState({condition:getCondition.target.value})
      }

      handleColorCar=getcolor=>{
      var self=this;
        self.setState({colorCar:getcolor.target.value})
      }

       handlePhoneNumber=getphonenumber=>{
      var self=this;
        self.setState({phoneNumber:getphonenumber.target.value})
      }

       handleZipcode=getzipcode=>{
      var self=this;
        self.setState({zipcode:getzipcode.target.value})
      }
handleEmail=getEmail=>{
      var self=this;
        self.setState({email:getEmail.target.value})
      }

       handleMilage=getMilage=>{
      var self=this;
        self.setState({mileage:getMilage.target.value})
      }
  handleSearchVin()
  {               var self=this;
              var getVin=$('#VIN').val();
             $('.overlay').show();
             $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
             $.ajax({
                      type: "POST",
                      url: 'https://www.shopsmartautos.com/kbb_api.php',
                      data:{'getvindata':'get',vinsearch:getVin}, // serializes the form's elements.
                      success: function(data)
                              {
               
              var getResponse=JSON.parse(data);
               if(getResponse.errors!=undefined)
               {
                
                 $('.error_get').text(getResponse.errors[0].message);
               }else if(getResponse.warnings!=undefined)
               {
                
                 $('.error_get').text(getResponse.warnings[0].message);
               }else if(getResponse['vinResults'][0].vehicleId==undefined)
               {
                
                 $('.error_get').text('We have not much more data for this VIN Number. Please try any another one');
               }else
               {
               $('.error_get').text('');
              $('.kbb_report_show2').show();
              $('#vechileIDTrad').val(getResponse['vinResults'][0].vehicleId);
              self.setState({vehicleId:getResponse['vinResults'][0].vehicleId});
              
              var keyExterior='Exterior Color';
              if(getResponse['vinResults'][0]['vehicleOptions']['Exterior Color']==undefined)
                {
                    var keyExterior='Exterior Colors';
                }
              
              var select='';
                  for(var colori=0;colori<getResponse['vinResults'][0]['vehicleOptions'][keyExterior].length;colori++)
          {
               select += `<option value=`+getResponse['vinResults'][0]['vehicleOptions'][keyExterior][colori].vehicleOptionId+`,`+getResponse['vinResults'][0]['vehicleOptions'][keyExterior][colori].optionName+`>`+getResponse['vinResults'][0]['vehicleOptions'][keyExterior][colori].optionName+`</option>`;
              
          }
          $('.tbody').empty();
          $('#selectcolor').empty();
          $('#selectcolor').append(select);
              
              $.each(getResponse['vinResults'][0]['vehicleOptions'], function(key, value) {
                  
      if(key!='Exterior Color')
      {
          
      var contentss=`<tr class="text-left"><td colspan="4" class="h1header">`+key+`</td></tr>`;
      for(var its=0;its<value.length;its++)
      {
          
          var status='';
          var nametra=key.replace(/\s/g, '');
          if(value[its].hasRelationships==true)
          {
              status='chked'+nametra;
          }else
          {
               status='';
          }
            console.log(status);

          var arr=[];
          if(value[its].isTypical==true)
          {
              arr.push(value[its].vehicleOptionId);
              var nametraa=value[its].optionName.replace(/\s/g, '')
              arr.push(nametraa);
         contentss += `<tr><td><input type="checkbox" class="checknow `+status+`" name="options[`+nametra+`][]"  placeholder="Last Name" value=`+arr+`  checked></td><td>`+value[its].optionName+`</td></tr>`;
         
          }else
          {
               arr.push(value[its].vehicleOptionId);
              var nametraa=value[its].optionName.replace(/\s/g, '')
              arr.push(nametraa);
               contentss += `<tr><td><input type="checkbox" class="checknow `+status+`" name="options[`+nametra+`][]" placeholder="Last Name" value=`+arr+`  ></td><td>`+value[its].optionName+`</td></tr>`;
          }
          
     
      }
      }
           
           $('.tbody').append(contentss)
  })

              $('input[type=checkbox]').on('click',function(){

                  var th = $(this), name = th.attr('class').split(' ')[1];

                  if(th.is(':checked') && typeof th==undefined){

                  $('.'+ name).not(th).prop('checked',false); 

                    }
                });

      $('.year').text(getResponse.vinResults[0].yearId);
              $('.make').text(getResponse.vinResults[0].makeName);
              $('.model').text(getResponse.vinResults[0].modelName);
              $('.style').text(getResponse.vinResults[0].modelPlusTrimName);
            }
            $('#btn-one').html('Search Now').removeClass('disabled');
                 
          }
        })
      }
      FormValidations=getValidate=>{
        var self=this;
        var zipcode=self.state.zipcode;
        var mileage=self.state.mileage;
        var email=self.state.email;
        var phoneNumber=self.state.phoneNumber;
        var colorCar=self.state.colorCar;
        var condition=self.state.condition;
        var Messages={};
        if(zipcode==null)
        {
          Messages['zipcode']='Please fill the  zipcode!';
          self.setState({errors:Messages});
          return false;
        }else if(mileage==null)
        {
          Messages['mileage']='Please fill the mileage!';
          self.setState({errors:Messages});
          return false;
        }else if(email==null)
        {
          Messages['email']='Please fill up the email!';
          self.setState({errors:Messages});
          return false;
        }else if(phoneNumber==null)
        {
          Messages['phoneNumber']='Please fill up the phone Number!';
          self.setState({errors:Messages});
          return false;
        }else if(colorCar==null)
        {
          Messages['colorCar']='Please select color!';
          self.setState({errors:Messages});
          return false;
        }else if(condition==null)
        {
          Messages['condition']='Please choose condition!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }
        handleGetPriceData()
        {
          if(this.FormValidations())
          {
          $('#getprice').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
                      $.ajax({
           type: "POST",
           url: 'https://www.shopsmartautos.com/kbb_api.php',
           data: $("#genrateALead").serialize(), // serializes the form's elements.
           success: function(data)
           {
              $('.kbb_reportShow').show();
              var getResponse=JSON.parse(data);
              console.log(getResponse);
              var rangeLow = new Number(getResponse.prices.rangeLow).toLocaleString();
              var rangeHigh = new Number(getResponse.prices.rangeHigh).toLocaleString();

             $('.pricemin').text(`$`+rangeLow);
             $('.pricemax').text(`$`+rangeHigh);
             $('.valuationdate').text(getResponse.valudatindates.valuationDate);
             $('.zipcodevalue').text(getResponse.zipcode);
             $('.valuationyear').text(getResponse.vechileData.yearId);
             $('.valuationMake').text(getResponse.vechileData.makeName);
             $('.valuationModel').text(getResponse.vechileData.modelName);
             $('.valuationStyelname').text(getResponse.vechileData.modelPlusTrimName);
             $('.valuationMilage').text(getResponse.mileage);
             $('.valuationBstyle').text(getResponse.vechileData.bodyStyle);
             $('.Colorvaluatin').text(getResponse.colorname);
             $('.valuationCondition').text(getResponse.conditiontrade);
             
            var optionsss='';
            $('.optionsapendtrade').empty();
              $.each(getResponse.options, function(key, value) {
                  key = key.replace(/([A-Z])/g, ' $1').trim();
                 var getoptions= value.toString();
                getoptions = getoptions.replace(/([A-Z])/g, ' $1').trim();

         optionsss += `<tr><td><strong>`+key+`</strong></td><td>`+getoptions+`</td></tr>`;
                });
              
               $('.optionsapendtrade').append(optionsss);
           }
           
         }).done(function(){
               $('#getprice').html('Get Price').removeClass('disabled');
          
         });
        }
      }


  render() {
const url=window.location.host;
      const href=window.location.href;

    return <div >
    <SEO
  title={'Value My Car- Shopsmartautos'}
  titleTemplate={'Shopsmartautos'}
  titleSeparator={'-'}
  description='Value my Car -Based on Blue Book® Values'
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
    <header className=" value_mycar">
    <div className="Overalay">
              <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12 text-center">
                      <h1 className="heading">Value my Car</h1>
                      <p className="lead">Based on Blue Book® Values</p>
                    </div>
                </div>
              </div>
              </div>
        </header>
  <div className="container-fluid">
    <p className="container text-center">
      Not all vehicles are eligible for an Instant Cash Offer. The Offer may be
      less than the Kelley Blue Book® Trade-In Value or Range. Kelley Blue Book
      does not redeem Offers, purchase vehicles or own/operate Participating
      Dealers. Dealer inspection required. If the info you provide about the
      vehicle differs from the dealer inspection, the Offer amount may be
      decreased. <br /> <br /> Kelley Blue Book and its affiliates are not
      responsible for Offer adjustments or if a Participating Dealer won’t
      accept your vehicle. Dealers pay a fee to participate in the Kelley Blue
      Book Instant Cash Offer program.
      <br />
      <a href="https://www.kbb.com/company/terms-of-service/#termsAndConditions">
        
        Program Terms and Conditions apply.
      </a>
      <br />
    </p>
    <div className="col-lg-12 card searchbar">
    <div className="row">
      <div className="col-lg-6">
      <div className="row">
        <div className="col-lg-1">
          <img
            id="kbb-leaddriver-search-logo"
            alt="KBB Logo"
            data-src=" https://tradeinadvisor.kbb.com/images/kbb-logo.png"
            className=" lazyloaded"
            src=" https://tradeinadvisor.kbb.com/images/kbb-logo.png"
          />
          </div>
          <div className="col-lg-6">
            <div id="kbb-leaddriver-search-title " className="worth">
              What's Your Trade‑In Worth?
            </div>
            <div id="kbb-leaddriver-search-subtitle">
              Get your Kelley Blue Book® Trade‑In Value.
            </div>
          
        </div>
      </div>
      </div>
      <div className="col-lg-6 tradeinvin form-group">
        <div className="form-inline">
          <label className="mr-sm-2">ENTER YOUR VIN NUMBER</label>
          <input
            type="text"
            className="form-control"
            name="VIN"
            id="VIN"
            placeholder="Search By VIN"
          />
          
          <button onClick={this.handleSearchVin}className="btn btn-primary adsclass seachByVin" id="btn-one" type="button">
            Search Now
          </button>
        </div>
        <div className="col-lg-12"><p style={{'color':'red'}}className="error_get"></p></div>
    </div>
    </div>
    </div>
    <div className="container">
    <div className="card  kbb_report_show2" style={{display:'none'}}>
      <div className="report">
        <div className="col-lg-12 report_dev ">
          <div className="headding">
            <img src="https://www.shopsmartautos.com/wp-content/uploads/kbb/kbbtrustedresource.png" />
          </div>
          <p className="subline">KBB DEALER has teamed up with Kelley Blue Book to give you a free Trade-in Value Report on your vehicle. How? It’s simple:</p>
        </div>
      </div>
       <div className="col-lg-12">
          <h4>Here's what we have so far</h4>
          <table className="table">
            <thead>
              <tr><th>Year</th>
                <th>Make</th>
                <th>Model</th>
                <th>Style</th>
              </tr></thead>
            <tbody>
              <tr>
                <td className="year" />
                <td className="make" />
                <td className="model" />
                <td className="style" />
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-12">
          <h4>Tell us about your vehicle</h4>
          <p>Choose your configration and get best price</p>
          <form onSubmit={(e) => {this.handleGetPriceData(); e.preventDefault();}} method="POST" id="genrateALead" encType="multipart/form-data">
            <table className="table">
              <thead>
                <tr><th>Select Option</th>
                  <th>Option name</th>
                </tr></thead>
              <tbody className="tbody">
              </tbody>
            </table>
            <h4>Tell more about your vechile</h4>
            <p>We can show your vechile best worth</p>
            <input type="hidden" name="vechileIDTrad" id="vechileIDTrad" defaultValue={this.state.vehicleId} />
            <div className="form-group name ">
              <input type="text" name="Milagetrade" className="form-control" placeholder="Milage" onChange={this.handleMilage} />
            <span style={{color:"red"}}>{this.state.errors['mileage']}</span>
            </div>
            <div className="form-group name ">
              <input type="text" name="zipcodetrade" className="form-control" placeholder="Zipcode" onChange={this.handleZipcode} />
               <span style={{color:"red"}}>{this.state.errors['zipcode']}</span>
            </div>
            <div className="form-group name ">
              <input type="email" name="emailtrade" className="form-control" placeholder="Email" onChange={this.handleEmail} />
              <span style={{color:"red"}}>{this.state.errors['email']}</span>
            </div>
            <div className="form-group name ">
              <input type="number" id="pnumber" name="phonenumbertrade" className="form-control" placeholder="Phone Number" onChange={this.handlePhoneNumber} />
               <span style={{color:"red"}}>{this.state.errors['phoneNumber']}</span>
            </div>
            <div className="options">
            </div>
            <div className="form-group name ">
              <select name="colortrade" id="selectcolor" onChange={this.handleColorCar} className="form-control">
                <option>select Color</option>
              </select>
               <span style={{color:"red"}}>{this.state.errors['colorCar']}</span>
            </div>
            <div className="condtion">
              <h4>Your vechile condition</h4>
              <div className="form-group name ">
              <div className=" custom-control custom-radio custom-control-inline">
                <input type="radio" name="conditiontrade" placeholder="Last Name" id="defaultInline2" defaultValue="Fair" onChange={this.handleCondition} />
                <label className="control-label" htmlFor="defaultInline2">Fair</label>
                </div>
                <div className=" custom-control custom-radio custom-control-inline">
                <input type="radio" defaultChecked name="conditiontrade" id="defaultInline3" placeholder="Last Name" defaultValue="Good" onChange={this.handleCondition}/>
                <label className="control-label" htmlFor="defaultInline3">Good</label>
                </div>
                 <div className=" custom-control custom-radio custom-control-inline">
                <input type="radio" name="conditiontrade" placeholder="Last Name" id="defaultInline4" defaultValue="Very Good" onChange={this.handleCondition}/>
                <label className="control-label" htmlFor="defaultInline4">Very Good</label>
                </div>
                <div className=" custom-control custom-radio custom-control-inline">
                <input type="radio" name="conditiontrade" placeholder="Last Name" id="defaultInline1" defaultValue="Excellent" onChange={this.handleCondition}/>
                <label className="control-label" htmlFor="defaultInline1">Excellent</label>
                </div>
                 <span style={{color:"red"}}>{this.state.errors['condition']}</span>
              </div>
            </div>
            <div className="send-btn col-md-12">
              <button type="submit" className="btn lastsubmit btn-primary submit_now" id="getprice" >Get Your Price</button>
            </div>
          </form>
        </div>
        </div>
        <div className="card  kbb_reportShow" style={{display:'none'}}>
        <div className="col-lg-12 report_final report_dev ">
          <div className="headding">
            <img src="https://www.shopsmartautos.com/wp-content/uploads/kbb/kbbtrustedresource.png" />
          </div>
          <h2 className="rangetradin">Kelley Blue Book® Trade-in Range</h2> </div>
          <div className="col-lg-12 text-center">
          <span className="pricerangetradein"><span className="pricemin">$8,456</span> -<span className="pricemax">$8,456</span></span>               <p>Kelley Blue Book info valid for ZIP Code </p><span className="zipcodevalue">92620 </span> through <span className="valuationdate">05/25/2020</span><br /> 
          <a href="https://www.kbb.com/company/privacy-policy/">Kelley Blue Book Privacy</a>
          <table className="table table-responsive">
            <thead>
              <tr><th>Options</th>
                <th>Selected</th>
              </tr></thead>
            <tbody className="optionsapendtrade">
              <tr><td>Year</td><td className="valuationyear" /></tr>
              <tr><td>Make</td><td className="valuationMake" /></tr>
              <tr><td>Model</td><td className="valuationModel" /></tr>
              <tr><td>Style Name</td><td className="valuationStyelname" /></tr>
              <tr><td>Milage</td><td className="valuationMilage" /></tr>
              <tr><td>Body Style</td><td className="valuationBstyle" /></tr>
              <tr><td>Color</td><td className="Colorvaluatin" /></tr>
              <tr><td>Condition</td><td className="valuationCondition" /></tr>
            </tbody>
          </table>
        </div>
        </div>
        
  <div className="col-sm-12 bdr">
    <div className="row">
      <div className="col-sm-4">
        <img
          data-src="https://www.shopsmartautos.com/wp-content/uploads/TellUsAboutYourCar.svg"
          className="ic lazyloaded"
          src="https://www.shopsmartautos.com/wp-content/uploads/TellUsAboutYourCar.svg"
        />
      </div>
      <div className="col-sm-8 ist">
        <h2>Tell Us About Your Car.</h2>
        <h3>Tell us your car's specific features and condition.</h3>
      </div>
    
      <div className="col-sm-8 ist3">
        <h2>Get the Cash Offer.</h2>
        <h3>
          Find out what you can get for your car today. Offers are good for 3
          days (not counting Sundays).
        </h3>
      </div>
    
      <div className="col-sm-4 ist2">
        {" "}
        <img
          data-src="https://www.shopsmartautos.com/wp-content/uploads/SeeWhatItsWorth.svg"
          className="ic lazyloaded"
          src="https://www.shopsmartautos.com/wp-content/uploads/SeeWhatItsWorth.svg"
        />
        <noscript>&lt;img src="https://www.shopsmartautos.com/wp-content/uploads/SeeWhatItsWorth.svg"&gt;</noscript>
      </div>
  
    
  
      <div className="col-sm-4">
        <img
          data-src="https://www.shopsmartautos.com/wp-content/uploads/ChooseAParticipatingDealer.svg"
          className="ic lazyloaded"
          src="https://www.shopsmartautos.com/wp-content/uploads/ChooseAParticipatingDealer.svg"
        />
        <noscript>
          &lt;img src="https://www.shopsmartautos.com/wp-content/uploads/ChooseAParticipatingDealer.svg"&gt;
        </noscript>
      </div>
      <div className="col-sm-8 ist">
        <h2>Choose a Participating Dealer.</h2>
        <h3>
          Bring in your car and offer. They'll verify the offer using the same
          questions you answered at home.
        </h3>
      </div>
    
      <div className="col-sm-8 ist3">
        <h2>Get Cash or Trade-In Credit.</h2>
        <h3>The offer is the same whether you sell or trade in.</h3>
      </div>
      <div className="col-sm-4 ist2">
        {" "}
        <img
          data-src="https://www.shopsmartautos.com/wp-content/uploads/GetCashOrTradeInCredit.svg"
          className="ic lazyloaded"
          src="https://www.shopsmartautos.com/wp-content/uploads/GetCashOrTradeInCredit.svg"
        />
      </div>
    </div>
      </div>
    </div>
  </div>
    <Footer/>
</div>;
    ;
  }
}
export default Valuemycar;