import React from 'react';

import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Footer from "./Footer";
import Notfound from './notfound';
import SEO from 'react-seo-component';
class canadadealers extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      error: null,
		      isLoaded: false,
		      items: [],
		      rangeValue:0,
		      zipcode:null,
		      errors:[],
		      formValid:false,
		      totalcount:0,
		      currentcount:10,
		    };
		      this.LoadMore = this.LoadMore.bind(this);
		      this.handleSearchZipRadius = this.handleSearchZipRadius.bind(this);
		  }
 state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };


				LoadMore()
					{
				var data_query={};
				var self=this;
				var CurrentCars=$('.loadmore').length;
				
				var array=self.state.items;
				 if(self.state.rangeValue!=0 && self.state.zipcode!=null)
				 {
				 	data_query['radius']=self.state.rangeValue;
				 	data_query['zip']=self.state.zipcode;
				 }
				 data_query['country']='ca';
				
				 $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
				$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {data_query:data_query,startpoint:CurrentCars,'loadMoreDealer':'loadMoreDealer'},
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    		 var children = array.concat(getResponse.dealers); 
                     var CurrentCarsTotal=self.state.currentcount+getResponse.dealers.length;
                      self.setState({ items: children });
                      self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: CurrentCarsTotal });
                     $('#btn-one').html('Load More').removeClass('disabled');
                  }
               );	

			}
  			handleRange= handleGetRange=>{
				var self=this;
				handleGetRange.persist();
				self.setState({rangeValue:handleGetRange.target.value});

			}


			handleSearchZipRadius(){
				
				var self=this;
				var zipcode=self.state.zipcode;
				var rangeValue=self.state.rangeValue;
					console.log('cvgjbh');
				if(self.FormValidations())
				{
					  self.setState({ isLoaded: false });
					 var getStringPara={'zip':zipcode,'radius':rangeValue,'country':'ca'};
					$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"SearchZipRadios":"true",getStringPara:getStringPara
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.dealers });
                      self.setState({ totalcount: getResponse.num_found });
                      self.setState({ currentcount: getResponse.dealers.length });
                    self.setState({ isLoaded: true });

                  }
               	); 
				}

			}


			handleZipcode= GetZipCode=>{
				var self=this;
				GetZipCode.persist();
				self.setState({zipcode:GetZipCode.target.value});

			}
 capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
		 componentDidMount() {
		 	 var self = this;
			// Get User current position
			self.setState({ isLoaded: false });
				$.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                  	"nearByDealers":"nearByDealers",'country':'ca'
   				   },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse.dealers });
                      self.setState({ totalcount: getResponse.num_found });
                        self.setState({ currentcount: getResponse.dealers.length });
                    self.setState({ isLoaded: true });
                  }
               );
		   		// Get zipcodes accoding current location
		  }


		  FormValidations=getValidate=>{
				var self=this;
				var zipcode=self.state.zipcode;
				var Messages={};
				if(zipcode==null)
				{
					Messages['zipcode']='Please fill a zipcode!';
					self.setState({errors:Messages});
					return false;
				}else
				{
					self.setState({errors:''});
					return true;
				}
			}
		 render() {
		 	 const { selectedOption } = this.state;
		    const { error, isLoaded, items } = this.state;
		     const url=window.location.host;
      		const href=window.location.href;
		    if (error) {
		      return <div>Error: {error.message}</div>;
		    } else if (!isLoaded) {
		      return <Spinner/>;
		    } else {
		      return (
		      	

		      	<div >
		      		<SEO
				  title={'Canadian Dealers- Shopsmartautos'}
				  titleTemplate={'Shopsmartautos'}
				  titleSeparator={'-'}
				  description='Helping you connect to the right car dealers-Must-Know Advice from our Experts'
				  image={'../media/newcars.jpg'}
				  pathname={href}
				  siteLanguage={'en-US'}
				  siteLocale={'en-US'}
				  twitterUsername={'shopsmartautos'}
					/>
		      	<header className="candadealers">
		      	<div className="Overalay">
  						<div className="container h-100">
	    					<div className="row h-100 align-items-center">
	      						<div className="col-12 text-center">
	        						<h1 className="heading">Helping you connect to the right car dealers</h1>
	        						<p className="lead">Must-Know Advice from our Experts</p>
	      						</div>
	    					</div>
  						</div>
  						</div>
				</header>
				<div className="col-lg-12">

					<h4 className="searchResults">Searching results showing ({this.state.currentcount.toLocaleString()}) of ({this.state.totalcount.toLocaleString()}) </h4>
				</div>
				<div className="clearfix"></div>
				<div className="container-fluid">
			        <div className="row vechilePage newcarss">

						<div className="col-lg-3 ">
						<div className="card">
							<div className="form-group ">
							<label> Search by zipcode</label>
							<input type="text" onChange={this.handleZipcode} className="form-control" />
							</div>
							<div className="form-group">
							<label> Select radius</label>
							<p className="RangeValue">{this.state.rangeValue} Miles</p>
								  <input 
							      id="typeinp" 
							      type="range" 
							      min="0" max="100" 
							      value={this.state.rangeValue} 
							      onChange={this.handleRange}
							      step="1"/>
							      </div>
							<div className="form-group ">
							<button onClick={this.handleSearchZipRadius}className="btn btn-primary">Search By zipcode</button>
							</div>

						</div>
						</div>
			        		{items=='' ? (<Notfound/>):(
			        		<div className="col-lg-9">
			        		          		 <div className="row">  
			             		    {items.map(item => (
									<div className="col-lg-3 loadmore"  key={item.id}>
									<div className="card">
	  										<div className="card-body">
	    										<h5 className="card-title">{item.seller_name}</h5>
	    										<p className="card-text"> <i className="fa fa-map-marker"></i> {item.street},{item.city},{item.zip},{item.state},{item.country}</p>
	    										<p><i class="fa fa-rss" aria-hidden="true"></i>  {this.capitalize(item.status)}</p>

	    										<p><i class="fa fa-id-badge" aria-hidden="true"></i> {this.capitalize(item.dealer_type)}</p>
	    										{item.seller_phone ? (<p><i class="fa fa-phone" aria-hidden="true"></i>
   <a href={"tel:"+item.seller_phone}>{item.seller_phone}</a></p>):('')}
	    											
	    											{item.seller_email ? (<p><i class="fa fa-envelope" aria-hidden="true"></i> <a href={"mailto:"+item.seller_email}>{item.seller_email.slice(0, 20)}..</a></p>):('')}

	    											{item.distance ? (<p><i class="fa fa-road" aria-hidden="true"></i> From you only {item.distance} Miles</p>):('')}

	    												    											{item.inventory_url ? (<p><i class="fa fa-external-link" aria-hidden="true"></i><a target="_blank" href={'https://'+item.inventory_url}>{item.inventory_url.slice(0, 20)}..</a></p>):('')}


	  										</div>
									</div>
									</div>
									))}
			            	</div>
			            	
			         </div>

			        		 )}
			         <div className="col-lg-12 text-center">
			         		{this.state.currentcount<this.state.totalcount ? (
			         		<button onClick={this.LoadMore} type="button" id="btn-one" className="btn btn-primary">Load More</button>
			         ):('')} </div>
			        </div>
		       		 </div>
		       		 <Footer/>
		       		 </div>
		       
		      )
		    }
		  }
		}
export default canadadealers;