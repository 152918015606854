import React from 'react';

import $ from 'jquery';
import '../App.css';
import './app.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Footer from "./Footer";
import ImageGallery from 'react-image-gallery';
import SEO from 'react-seo-component';
class carreviewsresults extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: [],
          Vehicle_id:null,
          extras:null,
          photos:null, 
          imagess:[],
          build:null,
          userName:null,
          userEmail:null,
          userMesssage:null,
          userPhone:null,
          errors:[],
          vehicleData:[],
          MessageResponse:null,
          Realted:null,

        };
         this.humanize = this.humanize.bind(this)
         this.FormValidations = this.FormValidations.bind(this)
         this.handleLeadSubmit = this.handleLeadSubmit.bind(this)
       
      }
 state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
     componentDidMount() {
         var self = this;
         var url_string = window.location.href;
         var url = new URL(url_string);
         var Vehicle_id = url.searchParams.get("id");
        console.log(Vehicle_id);
       $.post(
                "https://www.shopsmartautos.com/react_api.php",
                  {
                    "GetVehicleByIdReviews":"GetVehicleByIdReviews",'Vehicle_id':Vehicle_id
                   },
                  function(data) {
                    var getResponse=JSON.parse(data);
                    var arrData={};
                    console.log(getResponse.build);

                    self.setState({ items: getResponse });
                    arrData['vin']=getResponse.vin;
                    arrData['zip']=getResponse.dealer.zip;
                    arrData['price']=getResponse.prices ? getResponse.prices : getResponse.price ;
                    self.setState({ vehicleData: arrData });
                    self.setState({ extras: getResponse.extra });
                    self.setState({ build: getResponse.build });
                    self.setState({ photos: getResponse.photos });
                    self.setState({ isLoaded: true });
                  }
               );

      }

              

                humanize(str) {
                var i, frags = str.split('_');
                for (i=0; i<frags.length; i++) {
                  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
                }
                // form values update into state
                handleNameChaneg= getName=>{
                  this.setState({userName:getName.target.value})
                }
                handleEmailChaneg= getEmail=>{
                  this.setState({userEmail:getEmail.target.value})
                }
                handlePhoneChaneg= getPhone=>{
                  this.setState({userPhone:getPhone.target.value})
                  
                }
                handleMessageChaneg= getMessage=>{
                  this.setState({userMessage:getMessage.target.value})
                }
               FormValidations=getValidate=>{
                               var email = this.state.userEmail;
                               var userName = this.state.userName;
                               var userPhone = this.state.userPhone;
                               var userMessage = this.state.userMessage;
                                var errors = {};
                                var isValid = true;
                            
                                if (!userName) {
                                  isValid = false;
                                  errors["name"] = "Please enter your name.";
                                }
                            
                                if (!email) {
                                  isValid = false;
                                  errors["email"] = "Please enter your email Address.";
                                }
                            
                                if (typeof email !== "undefined") {
                                    
                                  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                                  if (!pattern.test(email)) {
                                    isValid = false;
                                    errors["email"] = "Please enter valid email address.";
                                  }
                                }
                            
                                if (!userPhone) {
                                  isValid = false;
                                  errors["phone"] = "Please enter your phone number.";
                                }
                            
                                if (typeof userPhone !== "undefined") {
                                    
                                  var pattern = new RegExp(/^[0-9\b]+$/);
                                  if (!pattern.test(userPhone)) {
                                    isValid = false;
                                    errors["phone"] = "Please enter only number.";
                                  }else if(userPhone.length != 10){
                                    isValid = false;
                                    errors["phone"] = "Please enter valid phone number.";
                                  }
                                }
                            
                                if (!userMessage) {
                                  isValid = false;
                                  errors["message"] = "Please enter your Message.";
                                }
                            
                                this.setState({
                                  errors: errors
                                });
                            
                                return isValid;
                              
                            }

                  handleLeadSubmit() {              
                  if(this.FormValidations())
                  {
                      var email = this.state.userEmail;
                      var self=this;
                      var userName = this.state.userName;
                      var userPhone = this.state.userPhone;
                      var userMessage = this.state.userMessage;
                      var vehicleData = this.state.vehicleData;
                      vehicleData['email']=email;
                      vehicleData['userName']=userName;
                      vehicleData['userPhone']=userPhone;
                      vehicleData['userMessage']=userMessage;
                      vehicleData['vin_marketcheck']='getData';
                   
                        $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                      vehicleData,
                      function(data) {
                      var getResponse=JSON.parse(data);
                        self.setState({ MessageResponse: getResponse.Message });
                        self.setState({ userName: '' });
                        self.setState({ userPhone: '' });
                        self.setState({ userMessage:'' });
                        self.setState({ userEmail: '' });
                        self.setState({ isLoaded: true });

                                    }
                            );
                    }
                  }

     render() {
       const { selectedOption } = this.state;
        const { error, isLoaded, items,Extras } = this.state;
        var extras =this.state.extras;
        var photos =this.state.photos;
        var build =this.state.build;
        var MessageResponse =this.state.MessageResponse;
        var Realted =this.state.Realted;
       

        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <Spinner/>;
        } else {
           var getkeys=Object.keys(build);
           var getValues=Object.values(build);
           var getErros= this.state.errors;
           var getDealerKeys=Object.keys(this.state.items.dealer);
           var getDealerValues=Object.values(this.state.items.dealer);
           const url=window.location.host;
           const href=window.location.href;
          return (
            <div >
             <SEO
  title={'Car Reviews Result- Shopsmartautos'}
  titleTemplate={'Shopsmartautos'}
  titleSeparator={'-'}
  description={this.state.items.heading}
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
        <div className="text-center h2 my-5 pt-4"></div>
        <div className="container-fluid">
              <div className="row vechilePage">
                  <div className="col-lg-8">
                                 <div className="">  
                                  <ImageGallery items={photos} />
                       <div className="card">
                    <div className="card-body">
                                  
  <h3 style={{color: '#000000c2', fontSize: '20px'}}>{this.state.items.heading}</h3><br /><br /> <div dangerouslySetInnerHTML={{__html:items.ratting ? ('Consumer Reviews '+items.ratting):('<h4>Ratting are not available</h4>')}}/> {items.value ? (items.value+'/5'):('')}<br /><br />  
{items.reviews!==null ? 
  <div dangerouslySetInnerHTML={{__html:items.reviews.reviewSections.length ?('<small>Overall Ratting</small> <a href="#">Average based on '+items.reviews.reviewSections.length+ ' reviews.</a>'):('')}}/> 
:<h4>Reviews are not available</h4>}
  <div className="col-lg-8" />
     <hr style={{border: '3px solid #f1f1f1'}} />
     {items.all_ratting!==''  && items.all_ratting!== null? 

items.all_ratting.map(key=>(
       
         <div className="row">
        <div className="col-lg-6 ">
           <h2 className="rr">{key.name}</h2>
        </div>
        <div className="col-lg-6 padltop" ><div dangerouslySetInnerHTML={{ __html:key.star}}/><span className="spn">{key.value}/5</span></div>
     </div>

  ))
      :<h1>Ratting not available</h1>}
</div>
</div>
<div className="card">
<section id="reviews" className="pb-5 mt-4 card-body">
        <hr />
        <h4 className="h4-responsive dark-grey-text font-weight-bold my-5 text-center">
          <strong>Vehicle Reviews</strong>
        </h4>
        <hr className="mb-5" />
        {/* Main wrapper */}
        <div className="comments-list text-center text-md-left">
          {/* First row */}

          {items.reviews!==null && items.reviews.reviewSections.length > 0 ? (

items.reviews.reviewSections.map(key=>(
          <div className="row mb-5">
            

            <div className="col-sm-10 col-12">
              <a>
                <h5 className="user-name font-weight-bold">{key.sectionHeader}</h5>
              </a>
              <p className="dark-grey-text article"><div dangerouslySetInnerHTML={{ __html:key.text}}/></p>
            </div>
            {/* Content column */}
          </div>
                  )) ):('Reviews not available')}
        </div>
        {/* Main wrapper */}
      </section>
                    </div>
                    </div>
                    </div>
               <div className="col-lg-4 ribbon">
                  {this.state.items.conditons ?(<span class="ribbon1"><span>{this.state.items.conditons}</span></span>):(<span class="ribbon1"><span>Recomanded</span></span>)
                      
                  }
            <div className="card">
              <div className="card-body pb-0 searchbar">
             <div className="price-box-3"><h2 className="newprice card-title dark-grey-text text-center lighten-4 py-2">${items.prices!=undefined
          ?
          items.prices.toLocaleString()
          :
          items.price !=null
          ?
          items.price.toLocaleString()
          :
           'NA'}<small className="smalls">Listing price</small></h2></div>
              <h2 className="card-title dark-grey-text text-center lighten-4 py-2">{this.state.items.heading}</h2>
              <div className>
              <h3 className="h3custom">
              {this.state.items.ratting ? ('KBB.com Expert Ratings'):('')}
              {this.state.items.ratting ? (<img style={{height: '70px', width: 'auto'}}  className=" ls-is-cached lazyloaded" src="https://www.shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" />):('')}
                 
                 <p /><small><div dangerouslySetInnerHTML={{ __html: this.state.items.ratting ?(this.state.items.ratting):('') }} />{this.state.items.value ?(<strong>{this.state.items.value}/5</strong>):('')}</small>                 
                  <div className="m-border" />
              </h3>
              <h3 className="sidebar-title">Refine Your Search</h3>
              <div className="s-border" />
              <div className="m-border" />
                 <ul>

 {
  Object.keys(getkeys).map((keyjj)=>(
    <li><span><strong>{ this.humanize(getkeys[keyjj])}</strong></span>{getValues[keyjj]}</li>
    ))
        }
                 </ul>
              </div>
              <div className="widget question">
              <h5 className="sidebar-title">Get a Question?</h5>
              <div className="s-border" />
                 <div className="m-border" />
                 <h5 className="text-center">
                         <i className="fa fa-envelope-o" aria-hidden="true">
                         <a href="mailto:info@shopsmartautos.com"> info@shopsmartautos.com </a>
                         </i>
                 </h5>
                   
                 </div>
              </div>
            </div>
            </div>
              </div>
              <section className="contact-section my-5">
        {/* Form with header */}
        <div className="card">
          {/* Grid row */}
          <div className="row">
            {/* Grid column */}
            <div className="col-lg-8">
              <div className="card-body form">
                {/* Header */}
                <h3 className="mt-4"><i className="fas fa-envelope pr-2" />Write to us:</h3>
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input type="text" id="form-contact-name" value={this.state.userName} onChange={this.handleNameChaneg} className="form-control" placeholder=""/>
                      <label htmlFor="form-contact-name" className>Your name</label>
                        <p style={{color:'red'}}>{getErros['name']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input type="email" id="form-contact-email" value={this.state.userEmail}  onChange={this.handleEmailChaneg} className="form-control" />
                      <label htmlFor="form-contact-email" className>Your email</label>
                     <p style={{color:'red'}}>{getErros['email']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                    
                       <input type="text" className="form-control" id="form-contact-phone"  name="phno" value={this.state.userPhone} onChange={this.handlePhoneChaneg}/>
                      <label htmlFor="form-contact-phone" className>Your phone</label>
                       <p style={{color:'red'}}>{getErros['phone']}</p>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <textarea type="text" id="form-contact-message" className="form-control md-textarea" rows={3} value={this.state.userMessage} defaultValue={""} onChange={this.handleMessageChaneg}/>
                      <label htmlFor="form-contact-message" className>Your message</label>
                       <p style={{color:'red'}}>{getErros['message']}</p>
                      <a onClick={this.handleLeadSubmit}className="btn-floating btn-lg blue waves-effect waves-light">
                        <i className="far fa-paper-plane" />
                      </a>
                    </div>
                   
                  </div>

                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </div>
               {MessageResponse!=null ? (<div className=" card alert alert-success">{MessageResponse}</div>):('')}
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-lg-4 searchbar ">
              <div className="card-body contact text-center h-100 black-text">
                <h3 className="my-4 pb-2">Dealer information</h3>
                <ul className="text-lg-left list-unstyled ml-4">
                 

 {
  Object.keys(getDealerKeys).map((keyjji)=>(
  getDealerKeys[keyjji]!='id' && getDealerKeys[keyjji]!='latitude' && getDealerKeys[keyjji]!='website' && getDealerKeys[keyjji]!='longitude'   ? ( <li><span><strong>{ this.humanize(getDealerKeys[keyjji])}</strong></span>{getDealerValues[keyjji]}</li>):('') 
   
    ))
        }


                 
                </ul>
                <hr className="hr-light my-4" />
                <ul className="list-inline text-center list-unstyled">
                  <li className="list-inline-item">
                    <a className="p-2 fa-lg tw-ic">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="p-2 fa-lg li-ic">
                      <i className="fab fa-linkedin-in"> </i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="p-2 fa-lg ins-ic">
                      <i className="fab fa-instagram"> </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
        {/* Form with header */}
      </section>
            </div>
           <Footer/>
         </div>
           
          )
        }
      }
    }

export default carreviewsresults;