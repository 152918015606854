import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./Footer";

import Select from 'react-select';
import $ from 'jquery';
import SEO from 'react-seo-component';
import Latestblog from "./latestblog";
class Valuemycar extends React.Component {
constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          errors:[],
          items: [],
          ReviewsMakes:null,
          ReviewsYears:null,
          ReviewsModels:null,
          latitude:null,
          longitude:null,
          SelectedMake:null,
          SelectedModel:null,
          SelectedYear:null,
          makeerror:null,

        };
  this.getReviewsById = this.getReviewsById.bind(this)
  this.FormValidations = this.FormValidations.bind(this)

      }
 state = {
    selectedOption: null,
  };
  handleChangeYear = selectedOption => {
    this.setState({SelectedYear:selectedOption.value });
  };

  handleChangeMake = getMake => {
    var self=this;
    var self=this;
      $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadModelReviews":"LoadModelReviews",make:getMake.value,latitude: self.state.latitude,longitude:self.state.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    if(getResponse!==null)
                  {
                    
                    self.setState({ makeerror: null });
                    self.setState({ ReviewsModels: getResponse });
                    self.setState({ isLoaded: true });
                  }else
                  {
                    self.setState({ makeerror: 'Currently, We are not available to this location' });
                    self.setState({ isLoaded: true });
                  }
                  }
            ); 
    self.setState({SelectedMake:getMake.value});
  };
   
   handleChangeModel = getmodel => {
      var self=this;
      $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadYearReviews":"LoadYearReviews",make:self.state.SelectedMake,model:getmodel.value,latitude: self.state.latitude,longitude:self.state.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ ReviewsYears: getResponse });
                  
                  }
            ); 
    self.setState({SelectedModel:getmodel.value});
  };
  
FormValidations=getValidate=>{
        var self=this;
        var Make=self.state.SelectedMake;
        var Model=self.state.SelectedModel;
        var Year=self.state.SelectedYear;
        var Messages={};
        if(Make==null)
        {
          Messages['make']='Please select the  make!';
          self.setState({errors:Messages});
          return false;
        }else if(Model==null)
        {
          Messages['model']='Please select the Model!';
          self.setState({errors:Messages});
          return false;
        }else if(Year==null)
        {
          Messages['year']='Please select the Year!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }
      }
  componentDidMount (){
        var self = this;

        // get makes for reviews
         navigator.geolocation.getCurrentPosition(function(position) {
          self.setState({latitude:position.coords.latitude,longitude:position.coords.longitude});
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadMakeReviews":"LoadMakeReviews",latitude: position.coords.latitude,longitude:  position.coords.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ ReviewsMakes: getResponse });
                  
                  }
               ); 
          }, (error)=>{

                  self.setState({latitude:40.94623,longitude:-73.069777});
          
            $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadMakeReviews":"LoadMakeReviews",latitude:40.94623,longitude:-73.069777
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ ReviewsMakes: getResponse });
                  
                  }
               );


          })
         }

          getReviewsById()
          { var self=this;
            var make=self.state.SelectedMake;
            var model=self.state.SelectedModel;
            var Year=self.state.SelectedYear;
            if(self.FormValidations())
            {
            $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "GetReviewsId":"GetReviewsId",'make':make,'model':model,'year':Year,latitude: self.state.latitude,longitude:self.state.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    window.open(`/car-reviews-results/?id=`+getResponse.listings[0].id, '_blank');
                  
                  }
               );
            }
          }


  render() {
     const { selectedOption } = this.state;
     const SelectedMake  = this.state.SelectedMake;
     const url=window.location.host;
      const href=window.location.href;
    return <div >
    <SEO
  title={'Car Reviews- Shopsmartautos'}
  titleTemplate={'Shopsmartautos'}
  titleSeparator={'-'}
  description='10,000+ Genuine Reviews To Find The Right Car -Based on Blue Book® Values'
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
    <header className=" carreviews">
    <div className="Overalay">
              <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12 text-center">
                      <h1 className="heading">10,000+ Genuine Reviews To Find The Right Car!</h1>
                      <p className="lead">Based on Blue Book® Values</p>
                    </div>
                </div>
              </div>
    </div>
        </header>
        
  <div className="container-fluid">

  <div className="col-lg-12">
   <div className="card">
      <div className="row">
       <div className="col-lg-6">
         <div className="card-body">
            <h4>Car Reviews</h4>
            <p className="card-text">Get the straight story from our experts and real-world feedback from consumers like you.</p>
            <div className="form-group">
                <Select   onChange={this.handleChangeMake} options={this.state.ReviewsMakes}/>
                <p style={{'color':'red'}}>{this.state.errors['make']}</p>
            </div>
            <div className="form-group">
                <Select value={selectedOption}  onChange={this.handleChangeModel} options={this.state.ReviewsModels}/>
                <p style={{'color':'red'}}>{this.state.errors['model']}</p>
            </div>
            <div className="form-group">
                <Select value={selectedOption} onChange={this.handleChangeYear} options={this.state.ReviewsYears}/>
                <p style={{'color':'red'}}>{this.state.errors['year']}</p>
            </div>
            <div className="form-group">
               <button onClick={this.getReviewsById}className="btn btn-primary"> Search now</button>
               <div className="copyrights">
                  <p>©2020 Kelley Blue Book Co., Inc. All rights reserved.</p>
                  <a href="https://www.kbb.com/company/privacy-policy/">Kelley Blue Book Privacy</a>
               </div>
            </div>
          </div>
        </div>
         <div className="col-lg-6  text-center"> <img data-src="https://static.kbb.com/static-host/prod/images/KBBLogoSeal.png" className=" lazyloaded" src="https://static.kbb.com/static-host/prod/images/KBBLogoSeal.png" /><h2>Browse Top-Rated Cars</h2></div>
    </div>
    </div>
</div>
</div>
<Latestblog/>
    <Footer/>
</div>;
    ;
  }
}
export default Valuemycar;