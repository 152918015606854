import React from 'react';
import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Notfound from './notfound';
import Map from './map';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import SEO from 'react-seo-component';
import {Loader, LoaderOptions} from 'google-maps';
class testdrive extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: true,
          items: [],
          vehicleClass:'new',
          rangeValue:0,
          selectedModel:null,
          selectedMake:null,
          zipcode:null,
          errors:[],
          formValid:false,
          MaxPrice:100,
          Makesearch:null,
          totalcount:0,
          currentcount:0,
          FirstName:null,
          LastName:null,
          email:null,
          PhoneNumber:null,
          Comment:null,
          TestDriveDate:null,
          MessageStatus:'',
          TestDriveTime:null,
          TestDriveTc:1,
          VehicleDataInfo:null,
          handleVehicleData:null

        };
        this.handlesubmitTc = this.handlesubmitTc.bind(this)
      }

      handleZipcode= GetZipCode=>{
        var self=this;
        GetZipCode.persist();
        self.setState({zipcode:GetZipCode.target.value});

      }

      handlesubmitTc(){
        var self=this;
        var FirstName= self.state.FirstName;
        var LastName= self.state.LastName;
        var email= self.state.email;
        var PhoneNumber= self.state.PhoneNumber;
        var Comment= self.state.Comment;
        var TestDriveDate= self.state.TestDriveDate;
        var TestDriveTime= self.state.TestDriveTime;
        var TestDriveTc= self.state.TestDriveTc;
        var VehicleDataInfo= JSON.parse(localStorage.getItem("VehicleDataInfo"));
        
        if(self.Form2Validations())
        {
          


           var getStringPara={'FirstName':FirstName,'LastName':LastName,'email':email,'PhoneNumber':PhoneNumber,'Comment':Comment,'TestDriveDate':TestDriveDate,'TestDriveTime':TestDriveTime,'TestDriveTc':TestDriveTc,vin:VehicleDataInfo.vin,mc_id:VehicleDataInfo.id,dealer_id:VehicleDataInfo.dealer.id};
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "TestDriveRequest":"true",getStringPara:getStringPara
             },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  
                  if(getResponse.status)
                  {
                    self.setState({MessageStatus:'<div class="alert alert-success border-green"><i class="fa fa-check"></i> Thanks for the request</div>'})

                  }else
                  {
                    self.setState({MessageStatus:'<div class="alert alert-danger border-red">Try again..</div>'})

                  }
                  
                  }
                );
        }
          
      }

      Form2Validations=getValidate=>{
        var self=this;
        var FirstName= self.state.FirstName;
        var LastName= self.state.LastName;
        var email= self.state.email;
        var PhoneNumber= self.state.PhoneNumber;
        var Comment= self.state.Comment;
        var TestDriveDate= self.state.TestDriveDate;
        var TestDriveTime= self.state.TestDriveTime;
        var TestDriveTc= self.state.TestDriveTc;

        var Messages={};
        if(FirstName==null)
        {
          Messages['FirstName']='Please select the  First Name!';
          self.setState({errors:Messages});
          return false;
        }else if(LastName==null)
        {
          Messages['LastName']='Please select the Last Name!';
          self.setState({errors:Messages});
          return false;
        }else if(TestDriveDate==null)
        {
          Messages['TestDriveDate']='Please select the Test Drive Date!';
          self.setState({errors:Messages});
          return false;
        }else if(TestDriveTime==null)
        {
          Messages['TestDriveDate']='Please select the Test Drive Time!';
          self.setState({errors:Messages});
          return false;
        }else if(TestDriveTc==null)
        {
          Messages['TestDriveTc']='Please select the Test Drive Term & condition!';
          self.setState({errors:Messages});
          return false;
        }else if(Comment==null)
        {
          Messages['Comment']='Please select the Comment!';
          self.setState({errors:Messages});
          return false;
        }else if(PhoneNumber==null)
        {
          Messages['PhoneNumber']='Please select the Phone Number!';
          self.setState({errors:Messages});
          return false;
        }else if(email==null)
        {
          Messages['email']='Please fill up the email!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }


  handleFirstName= FirstName=>{
                  this.setState({FirstName:FirstName.target.value})
                }

    handleLastName= LastName=>{
                  this.setState({LastName:LastName.target.value})
                }
    handleemail= email=>{
                  this.setState({email:email.target.value})
                }
    handlePhoneNumber= PhoneNumber=>{
                  this.setState({PhoneNumber:PhoneNumber.target.value})
                }
    handleComment= Comment=>{
                  this.setState({Comment:Comment.target.value})
                }
    handleTestDriveDate= TestDriveDate=>{
                  this.setState({TestDriveDate:TestDriveDate.target.value})
                }
  handleTestDriveTime= TestDriveTime=>{
                  this.setState({TestDriveTime:TestDriveTime.target.value})
                }
    handleTC= tc=>{
                  this.setState({TestDriveTc:tc.target.value})
                }

    handleVehicleData= handleVehicleData=>{
            var self=this;
                  self.setState({VehicleDataInfo:handleVehicleData})
                }
  
     render() {
        const { error, isLoaded, items } = this.state;
      
      const  Makes  = this.state.Makes;
      const  Years  = this.state.Years;
      const  selectedMake  = this.state.selectedMake;
      const  Models  = this.state.Models;
      const { selectedOption } = this.state;
      const { selectedModel } = this.state;
      const { selectedYear } = this.state;
      const url=window.location.host;
      const href=window.location.href;

        if (error) {
          return <div>Error: {error.message}</div>;
        }else if (!isLoaded) {
          return <Spinner/>;
        } else {
          return (
           <div className="col-md-12 mb-4">
        {/* Modal: mdb-abandonedCart-hard */}
         <div className="modal fade " id="centralModalInfoDemo" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-notify modal-primary" role="document">
          {/* Content */}
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <p className="heading lead">Schedule a Test Drive</p>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="white-text">×</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              <div className="col-lg-12">
              <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                <label className="p-0">First Name</label>
                <input type="text" onChange={this.handleFirstName} className="form-control" name="fname" placeholder="John"/>
                <p style={{color:"red"}}>{this.state.errors['FirstName']}</p>
                </div>
                <div className="form-group">
                <label className="p-0">Email Address</label>
                <input type="email" className="form-control" onChange={this.handleemail} name="email" placeholder="example@example.com"/>
                <p style={{color:"red"}}>{this.state.errors['email']}</p>
                </div>
                <div className="form-group">
                <label className="p-0">Test Drive date</label>
                <input type="date" className="form-control" onChange={this.handleTestDriveDate} name="dt" placeholder="12-12-2020"/>
        <p style={{color:"red"}}>{this.state.errors['TestDriveDate']}</p>
                </div>
              </div>
              <div className="col-lg-6">
                  <div className="form-group">
                    <label className="p-0">Last Name</label>
                    <input type="text" className="form-control" onChange={this.handleLastName} name="fname" placeholder="Doe"/>
                    <p style={{color:"red"}}>{this.state.errors['LastName']}</p>
                  </div>
                  <div className="form-group">
                    <label className="p-0">Phone Number</label>
                    <input type="number" className="form-control" name="phnumber" onChange={this.handlePhoneNumber} placeholder="+1234567890"/>
                    <p style={{color:"red"}}>{this.state.errors['PhoneNumber']}</p>
                  </div>
                  <div className="form-group">
                    <label className="p-0">Time of day</label>
                    <select onChange={this.handleTestDriveTime} className="form-control" >
                      <option value="" selected="selected">Select Time Period</option>
        <option value="">----------</option>
        <option value="Any Time">Any Time</option>
        <option value="Early Morning">Early Morning</option>
        <option value="Morning">Morning</option>
        <option value="Afternoon">Afternoon</option>
        <option value="Late Afternoon">Late Afternoon</option>
                    </select>
                    <p style={{color:"red"}}>{this.state.errors['TestDriveTime']}</p>
              
                  </div>

              </div>
                  
              </div>
              <div className="form-group">
                      <textarea placeholder="Comment" onChange={this.handleComment} className="form-control"></textarea>
               <p style={{color:"red"}}>{this.state.errors['Comment']}</p>
              </div>
              <p>By submitting my cell phone number to the Dealership, I agree to receive text messages, and phone calls, which may be recorded and/or sent using automated dialing equipment or software from Shop Smart Autos and its affiliates in the future, unless I opt-out from such communications. I understand that my consent to be contacted is not a requirement to purchase any product or service and that I can opt-out at any time. I agree to pay my mobile service provider’s text messaging rates, if applicable.</p>
              <div>
                <div className="custom-control custom-checkbox">
    <input type="checkbox" className="custom-control-input" id="defaultUnchecked" onChange={this.handleTC}  value="1" checked/>
    
    <label className="custom-control-label" for="defaultUnchecked">By submitting this form, I agree to providing my contact information, including all details provided therein, to the dealer and to receiving calls and text messages about vehicles from the dealer selling this car at the number provided (including via autodial or prerecorded calls) or via my other contact information provided above. Additionally, calls may be recorded for quality assurance purposes. This consent does not require me to buy anything. Standard message and data rates may apply</label>
    <p style={{color:"red"}}>{this.state.errors['TestDriveTc']}</p>
</div>
              </div>
             </div>
            </div>
            <div className="card-body" dangerouslySetInnerHTML={{ __html:this.state.MessageStatus}}/>
            {/* Footer */}
            <div className="modal-footer ">
              <button type="button" onClick={this.handlesubmitTc} className="btn btn-outline-info waves-effect">Submit</button>
            </div>
          </div>
          {/* Content */}
        </div>
      </div>
      </div>
          )
        }
      }
    }

export default testdrive;