import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
class Newsvarter extends React.Component {

         constructor(props) {
          super(props);
          this.state = {
            error: null,
            isLoaded: false,
            Brands: [],
            valueCarType:null,
            emailNewsvarter:null,
            responseText:null,
            FirstName:null,
          LastName:null,
          email:null,
          PhoneNumber:null,
          Comment:null,
          TestDriveDate:null,
          MessageStatus:'',
          TestDriveTime:null,
          TestDriveTc:1,
          VehicleDataInfo:null,

          };

        
        }
         
         handleChangeEmailNewsL= handleChangeEmailNewsL=>{
        var self=this;
        handleChangeEmailNewsL.persist();
        self.setState({emailNewsvarter:handleChangeEmailNewsL.target.value});

          }
           SubscribeNewsvarter= (newsvarter)=>{
             $('#Subscribe').html('<span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span><span className="text-white">Subscribing..</span>').addClass('disabled');
          
            var self=this;
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                     "Newsvarter":"Newsvarter",Email:self.state.emailNewsvarter,
                 },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ isLoaded: true });
                    if(getResponse.status==true)
                    {

                    self.setState({responseText:'<div class="alert alert-success">'+getResponse.message+'</div>'});

                    }else
                    {

                    self.setState({responseText:'<div class="alert alert-danger">'+getResponse.message+'</div>'});

                    }
                     $('#Subscribe').html('Subscribe').removeClass('disabled');
                    }

                  
               );
         }

  render() {
   const  Brands  = this.state.Brands;
    return <div className="card ">
                 <div className="row card-body text-center">
                    <div className="col-lg-6 ">
                    <h5>Email me price drops & new results for this search.</h5>
                      <small>By subscribing, you agree to our <a target="_blank" href="/privacy-policy">Privacy Statement</a>.</small>
                    </div>
                    <div className="col-lg-6 form-inline">
<input type="text" name="email" onChange={this.handleChangeEmailNewsL} className="form-control " placeholder="Type your email..."/><button className="btn btn-primary btn-rounded" id="Subscribe" onClick={this.SubscribeNewsvarter}>Subscribe</button>
                    <div dangerouslySetInnerHTML={{ __html:this.state.responseText}}/>
                    </div>
                </div>
           </div>

  }
}
export default Newsvarter;