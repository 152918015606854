import React from 'react';
import $ from 'jquery';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import Notfound from './notfound';
import Map from './map';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import SEO from 'react-seo-component';
import {Loader, LoaderOptions} from 'google-maps';
class dealershow extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: [],
          vehicleClass:'new',
          rangeValue:0,
          selectedModel:null,
          selectedMake:null,
          dealerid:null,
          dealerImage:null,
          errors:[],
          formValid:false,
          MaxPrice:100,
          Makesearch:null,
          totalcount:0,
          currentcount:0,
          dealerinfo:null,
          inventoryUrl:null,

        };
        
        this.PostData = this.PostData.bind(this)

        this.getRandomNumber = this.getRandomNumber.bind(this);
      }

      PostData(SendData)
      {
          var self = this;
          var CurrentCars=$('.loadmore').length;
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  SendData,
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ items: getResponse });
                    
                    self.setState({ isLoaded: true });

                  }
                );
      }

  componentDidMount (){

     var self = this;
     var url_string = window.location.href;
         var url = new URL(url_string);
     var lat = url.searchParams.get("lat");
     var long = url.searchParams.get("long");
     var name = url.searchParams.get("name");
     var nameurl = url.searchParams.get("name").replace(/ /g,"-");
     var index = url.searchParams.get("index");
        var url='/dealer-view/'+nameurl+'-'+index;

      var SendData={};
        self.setState({ isLoaded: false,inventoryUrl: url});
           SendData['dealer_view_show']='nearBy';
           SendData['lat']=lat;
           SendData['lng']=long;
           SendData['name']=name;
        self.PostData(SendData);
        
    } 
      
            getRandomNumber(){
      var getNumber=  Math.floor(Math.random() * 30) + 1;
      const content = [
                            "../media/images/amg-1880381_1280.webp",
                            "../media/images/auto-2179220_1280.webp",
                            "../media/images/car-race-438467_1280.webp",
                            "../media/images/car-5548242_1280.webp",
                            "../media/images/bmw-918407_1280.webp",
                            "../media/images/ford-mustang-146580_1280.webp",
                            "../media/images/auto-2179220_1280.webp",
                            "../media/images/car-1376190_1280.webp",
                            "../media/images/amg-1880381_1280.webp",
                            "../media/images/auto-2209439_1280.webp",
                            "../media/images/ferrari-360-2918130_1280.webp",
                            "../media/images/sports-car-146185_1280.webp",
                            "../media/images/bmw-768688_1280.webp",
                            "../media/images/bmw-918407_1280.webp",
                            "../media/images/car-1376190_1280.webp",
                            "../media/images/mercedes-841465_1280.webp",
                            "../media/images/taxi-238478_1280.webp",
                            "../media/images/ferrari-458-spider-2932191_1280.webp",
                            "../media/images/mercedes-1737908_1280.webp",
                            "../media/images/mclaren-2317538_1280.webp",
                            "../media/images/lamborghini-aventador-2932196_1280.webp",
                            "../media/images/car-2115060_1280.webp",
                            "../media/images/auto-3209449_1280.webp",
                            "../media/images/mustang-2338356_1280.webp",
                            "../media/images/car-2176944_1280.webp",
                            "../media/images/nissan-gtr-2975651_1280.webp",
                            "../media/images/car-2176960_1280.webp",
                            "../media/images/lamborghini-huracan-2975652_1280.webp",
                            "../media/images/mercedes-1737913_1280.webp",
                            "../media/images/ferrari-f430-2930661_1280.webp",
                            "../media/images/mustang-2338358_1280.webp",
];
  return content[getNumber];
      }

  // get model on user make selection end

  // get model on user make selection start
 
     render() {
        const { error, isLoaded, items } = this.state;
      
      const  Makes  = this.state.Makes;
      const  Years  = this.state.Years;
      const  selectedMake  = this.state.selectedMake;
      const  Models  = this.state.Models;
      const { selectedOption } = this.state;
      const { selectedModel } = this.state;
      const { selectedYear } = this.state;
      const url=window.location.host;
      const href=window.location.href;

  var values=this.getRandomNumber();
        if (error) {
          return <div>Error: {error.message}</div>;
        }else if (!isLoaded) {
          return <Spinner/>;
        } else {
          return (
            
            <div >
              <SEO
            title={'Dealer Profile Show'}
            titleTemplate={'shopsmartautos'}
            titleSeparator={'-'}
            description='THE EASIEST WAY TO BUY YOUR NEW CAR-Must-Know Advice from our Experts '
            image={'https://cdn.pixabay.com/photo/2015/01/19/13/51/car-604019_1280.jpg'}
            pathname={href}
            siteLanguage={'en-US'}
            siteLocale={'en-US'}
            twitterUsername={'shopsmartautos'}
          />
          

            <header className="dealerCars" style={{'backgroundImage':'url('+values+')'}}>
<div className="Overalays">
              <div className="container h-100">
                <div className="row h-100 align-items-center">

                    <div className="col-12 text-center">
                          <div className="row">
                          <h3 className="text-white col-12"><i className="fa fa-home"></i> {this.state.items.result.name!==undefined ? this.state.items.result.name:('') }</h3>
                          <p className="text-white col-12">{this.state.items.result.formatted_address!==undefined ? this.state.items.result.formatted_address:('')}</p>   
                    </div>
                    </div>
                </div>
              </div>
              </div>
        </header>
        <div className="container-fluid">
        <div className="row">

          <div className="col-lg-4">
<div className="card">
        <div className="card-body">
            <h3><i className="fa fa-home"></i> {this.state.items.result.name!==undefined ? this.state.items.result.name:('Na') }</h3>
            <p><i className="fa fa-map-marker"></i> {this.state.items.result.formatted_address!==undefined ? this.state.items.result.formatted_address:('Na')}</p>
            <p><i className="fa fa-car"></i><Link to={this.state.inventoryUrl}>View inventory</Link></p>
           {this.state.items.result.formatted_phone_number!==null || this.state.items.result.formatted_phone_number!==undefined  ? <p><a href={"tel:"+this.state.items.result.formatted_phone_number}><i className="fa fa-phone"></i> Call {this.state.items.result.formatted_phone_number}</a></p> :('Na')}
            {this.state.items.result.opening_hours.weekday_text!==undefined ?
            <ul className="p-0">

            {this.state.items.result.opening_hours.weekday_text.map(item => (
              <li>
<i className="fa fa-clock"></i> {item}
              </li>
              ))}
            </ul>
            :('Wokring Hours Not available')}
          </div>
          </div>
          </div>
          <div className="col-lg-8">
           <Map/>
          </div>
        </div>
        </div>
               <Footer/>
               </div>

           
          )
        }
      }
    }

export default dealershow;