import React from 'react';
import jQuery from 'jquery';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

class BrwoseByPrice extends React.Component {
constructor(props){
	super(props)
        this.state ={
                posts: null,
                isLoaded:false,
                error:false,
                lowerBound:5000,
                upperBound:1000000,
                value: [5000, 1000000],
                changeLowvalue:5000.,
                changeHighvalue:1000000,
                    }
                 this.handleVehcileClassMake = this.handleVehcileClassMake.bind(this)
                        }


handleVehcileClassMake= getClassValue=>{
    var self = this;
    
    var getUrl=$('.link_price').attr('href');
    var spliurl=getUrl.split('?');
    // change form action url according user input
    console.log(spliurl);

    if (getClassValue.target.value=='new') {
         
         var url     ='new-cars/?'+spliurl[1];
          $('.link_price').attr('href',url);

    }else if(getClassValue.target.value=='used')
    { 
     
    
        var url     ='used-cars/?'+spliurl[1];
       $('.link_price').attr('href',url);
    }else if(getClassValue.target.value=='certified')
    { 
     
      var url     ='certified-cars/?'+spliurl[1];
      $('.link_price').attr('href',url);
    }

  }

 onSliderChange = value => {
   console.log(value);
   var self=this;
self.setState({changeLowvalue:value[0]});
self.setState({changeHighvalue:value[1]});
var geturls=  $('.link_price').attr('href').split('/');

      $('.link_price').attr('href',geturls[0]+'/?getprice=true&MaxPrice='+value[0]+'-'+value[1]);
  }

  onMinChange = e => {
    this.setState({
      min: +e.target.value || 0,
    });
  };

  onMaxChange = e => {
    this.setState({
      max: +e.target.value || 100,
    });
  };

  render() {

    return <div className="col-lg-12 text-center">
    
   <h2 className="uk-text-center uk-margin-large-top uk-margin-small-bottom"> Browse By Price</h2>
  <div className="container slider_outer">
        <div className="row">
          <div className="col-sm-12 ">
            <div >
              <span className="min_price" id="slider-range-value1"  >${this.state.changeLowvalue.toLocaleString()}</span><span className="min_price">-</span>
              <span className="max_price" id="slider-range-value2" >${this.state.changeHighvalue.toLocaleString()}</span>
                         </div>
            <div className="slider-range"> <Range
          defaultValue={this.state.value}
          min={this.state.lowerBound}
          max={this.state.upperBound}
          onChange={this.onSliderChange}
        /></div>

   
          </div>
        </div>
        <div className="row slider-labels">
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="col-lg-12 chk-new-label">
            <input id="vehicle1" defaultChecked="checked" name="car_type" type="radio" value="new" onClick={this.handleVehcileClassMake}/>
            <label htmlFor="vehicle1"> New Car</label>
            <input id="vehicle2" name="car_type" type="radio" value="used" onClick={this.handleVehcileClassMake} />
            <label htmlFor="vehicle2">Used Car</label>
            <input id="vehicle3" name="car_type" type="radio" value="certified" onClick={this.handleVehcileClassMake} />
            <label htmlFor="vehicle3">Certified Car</label>
            </div>
            <form>
              <input type="hidden" id="min_price" name="min-value" defaultValue />
              <input type="hidden" id="max_price" name="max-value" defaultValue />
              <a href="new-cars/?getprice=true&MaxPrice=5000" className="link_price btn btn-primary">
                Get Results
              </a>
            </form>
          </div>
        </div>
      </div>
</div>;
  }
}
export default BrwoseByPrice;