import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./Footer";
import $ from 'jquery';
import Select from 'react-select';
import SEO from 'react-seo-component';
import jQuery from 'jquery';

import 'bootstrap/dist/css/bootstrap.min.css';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Latestblog from "./latestblog";
class ResearchTool extends React.Component {
  
constructor(props){
      super(props)
      this.state ={
         errors:[],
         formValid:false,
         zipcode:null,
         vin:null,
         error: null,
          isLoaded: false,
          items: [],
          vehicleClass:'new',
          rangeValue:0,
          selectedModel:null,
          selectedMake:null,
          selectedYear:null,
          Makes:null,
          Models:null,
          Years:null,
          selectedYear:null,
          lowerBound:5000,
          upperBound:1000000,
          value: [5000, 1000000],
          changeLowvalue:5000.,
          changeHighvalue:1000000,

          leaseTermstart:2,
          leaseTermEnd:72,
          valueLeasterm: [2, 72],
          changeLeaseLowterm:2.,
          changeLeaseHighTerm:72,


          leaseDownPaymentstart:200,
          leaseDownPaymentEnd:10000,
          valueLeaseDownPayemnt: [200, 10000],
          changeLeaseDownPayemntLowTerm:200,
          changeLeaseDownPayemntHighTerm:10000,

          leaseEMPstart:200,
          leaseEMPEnd:10000,
          valueLeaseEMP: [200, 10000],
          changeLeaseEMPLowTerm:200,
          changeLeaseEMPHighTerm:10000,
          latitude:null,
          longitude:null,



            }
        this.handleSearchPriceAdvisior = this.handleSearchPriceAdvisior.bind(this)
        this.handleVehcileCompare = this.handleVehcileCompare.bind(this)
        this.FormValidations = this.FormValidations.bind(this)
        }

componentDidMount (){
        var self = this;           
        // get makes accordig to curretn position
        navigator.geolocation.getCurrentPosition(function(position) {
          self.setState({latitude:position.coords.latitude,longitude:position.coords.longitude});
          
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadMakeResearch":"LoadMakeResearch",latitude: position.coords.latitude,longitude:position.coords.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                  
                  
                    self.setState({ Makes: getResponse });
                  
                  }
               ); 
                }, (error)=>{
          self.setState({latitude:40.94623,longitude:-73.069777});
          
                 $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadMakeResearch":"LoadMakeResearch",latitude:40.94623,longitude:-73.069777
                  },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Makes: getResponse });
                  
                  }
               );
          })     

    }

handleVehcileClassMake= getClassValue=>{
    var self = this;
    
    var getUrl=$('.link_price').attr('href');
    var spliurl=getUrl.split('?');
    // change form action url according user input
    console.log(spliurl);

    if (getClassValue.target.value=='new') {
         
         var url     ='new-cars/?'+spliurl[1];
          $('.link_price').attr('href',url);

    }else if(getClassValue.target.value=='used')
    { 
     
    
        var url     ='used-cars/?'+spliurl[1];
       $('.link_price').attr('href',url);
    }else if(getClassValue.target.value=='certified')
    { 
     
      var url     ='certified-cars/?'+spliurl[1];
      $('.link_price').attr('href',url);
    }

  }

 onSliderChange = value => {
   console.log(value);
   var self=this;
self.setState({changeLowvalue:value[0]});
self.setState({changeHighvalue:value[1]});
var geturls=  $('.link_price').attr('href').split('/');

      $('.link_price').attr('href',geturls[0]+'/?getprice=true&MaxPrice='+value[0]+'-'+value[1]);
  }

 onLeaseTermChange = value => {
   console.log(value);
   var self=this;
self.setState({changeLeaseLowterm:value[0]});
self.setState({changeLeaseHighTerm:value[1]});
var geturls=  $('.link_price').attr('href').split('/');

      //$('.link_price').attr('href',geturls[0]+'/?getprice=true&MaxPrice='+value[0]+'-'+value[1]);
  }


onLeaseDownPaymentChange = value => {
   console.log(value);
   var self=this;
self.setState({changeLeaseDownPayemntLowTerm:value[0]});
self.setState({changeLeaseDownPayemntHighTerm:value[1]});
var geturls=  $('.link_price').attr('href').split('/');

      //$('.link_price').attr('href',geturls[0]+'/?getprice=true&MaxPrice='+value[0]+'-'+value[1]);
  }


 onLeaseEMP = value => {
   console.log(value);
   var self=this;
self.setState({changeLeaseEMPLowTerm:value[0]});
self.setState({changeLeaseEMPHighTerm:value[1]});
var geturls=  $('.link_price').attr('href').split('/');

      $('.link_price').attr('href',geturls[0]+'/?getprice=true&MaxPrice='+value[0]+'-'+value[1]);
  }






  onMinChange = e => {
    this.setState({
      min: +e.target.value || 0,
    });
  };

  onMaxChange = e => {
    this.setState({
      max: +e.target.value || 100,
    });
  };


handleChangeVin=getvin=>{
  var self=this;
  self.setState({vin:getvin.target.value})
}

handleChangeZipcode=getzip=>{
  var self=this;
  self.setState({zipcode:getzip.target.value})
}
    // get model on user make selection start
      handleChange = selectedOption => {
        var self = this;
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadModelResearch":"LoadModelResearch",make:selectedOption.value,latitude: self.state.latitude,longitude:self.state.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Models: getResponse });
                    self.setState({ isLoaded: true });
                    self.setState({ selectedMake: selectedOption.value });
                  }
               ); 

      }

      handleModelChange= getValue=>{
        var self=this;
          self.setState({selectedModel:getValue.value});
          var self = this;           
        // get makes accordig to curretn position
          $.post(
                  "https://www.shopsmartautos.com/react_api.php",
                  {
                    "LoadYearResearch":"LoadYearResearch",make:self.state.selectedMake,model:getValue.value,latitude: self.state.latitude,longitude:self.state.longitude
            },
                  function(data) {
                  var getResponse=JSON.parse(data);
                    self.setState({ Years: getResponse });
                  
                  }
               ); 
      }

       handleChangeYears= getValue=>{
        var self=this;
          self.setState({selectedYear:getValue.value});
      }
handleSearchPriceAdvisior()
{

            var getvin=$('#vin').val();
            var zipcode=$('#zipcode').val();
            $('#btn-vin').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
            
             var settings = {
              "url": "https://www.shopsmartautos.com/car_submit.php",
              "data":{'vin_priceadvisor':getvin},
              "method": "POST",
              "timeout": 0,
              "dataType":"json",
                    };
             
              $.ajax(settings).done(function(getData){
    $('.ifrmaaes').html(`<iframe width="300" height="375" frameBorder="0" scrolling="no"
style="border:0px none #FFFFFF;" src="//pauc.syndication.kbb.com/pr
iceadvisorusedcar/fairpurchaseprice?APIKey=e9068180-03c3-4b3f-99c5-81adc74aa49d&VehicleId=`+getData.vinResults[0]['vehicleId']+`&ZIPCode=`+zipcode+`"></iframe>`);
              })
              $('#btn-vin').html('Search Now').removeClass('disabled');
                 
}

FormValidations=getValidate=>{
        var self=this;
         var make=self.state.selectedMake;
         var model=self.state.selectedModel;
         var Year=self.state.selectedYear;

        var Messages={};
        if(make==null)
        {
          Messages['make']='Please select the  make!';
          self.setState({errors:Messages});
          return false;
        }else if(model==null)
        {
          Messages['model']='Please select the Model!';
          self.setState({errors:Messages});
          return false;
        }else if(Year==null)
        {
          Messages['year']='Please select year!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }


handleVehcileCompare()
{
                var self=this;
                var make=self.state.selectedMake;
                var model=self.state.selectedModel;
                var Year=self.state.selectedYear;

              if(self.FormValidations())
              {

              
            $('.btn_click').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').addClass('disabled');
            
             var settings = {
              "url": "https://www.shopsmartautos.com/react_api.php",
              "data":{'VehcileCompareData':'VehcileCompareData','make':make,'model':model,'Year':Year,latitude: self.state.latitude,longitude:self.state.longitude

            },
              "method": "POST",
              "timeout": 0,
              "dataType":"json",
                    };
             
              $.ajax(settings).done(function(getData){
                if (getData!=null) {



                    if(getData.vinResults[0].vehicleId!=undefined)
                     {

                         window.open(`/compare-car/?id=`+getData.vinResults[0].vehicleId+'&zipcode='+getData.vinResults[0].zipcode,'_blank');
                          self.setState({error:''});
                    }else if(getData.warnings!=null)
                    {
                      self.setState({error:getData.warnings[0].message});
                    }
             

              }else
              {
                self.setState({error:'Vehicle currently not available to compare. Please try with anothe year or model'});
              }
              })
              $('.btn_click').html('Compare').removeClass('disabled');
            }
                 
}
  render() {
    const  Makes  = this.state.Makes;
    const  Models  = this.state.Models;
    const  Years  = this.state.Years;
    const url=window.location.host;
      const href=window.location.href;
    return <div >
     <SEO
  title={'Research Tool- Shopsmartautos'}
  titvaremplate={'Shopsmartautos'}
  titleSeparator={'-'}
  description='An Official Offer to Buy Your Car-Based on Blue Book® Values'
  image={'../media/newcars.jpg'}
  pathname={href}
  siteLanguage={'en-US'}
  siteLocale={'en-US'}
  twitterUsername={'shopsmartautos'}
/>
    <header className=" value_mycar">
    <div className="Overalay">
              <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12 text-center">
                      <h1 className="heading">Research Tool</h1>
                    </div>
                </div>
              </div>
              </div>
        </header>
    <div className="container">
  <div className="row">
   <div className="col-lg-6">
      <div className="card">
         <div className="card-body">
            <img data-src="https://www.shopsmartautos.com/wp-content/uploads/logo_kbb_125x71.png" className=" lazyloaded" src="https://www.shopsmartautos.com/wp-content/uploads/logo_kbb_125x71.png" />
            <h4>Find Best Price by VIN and Zipcode</h4>
            <h5 className="card-title">Price Advisor</h5>
            <p className="card-text">Put your vechile VIN and get the best price to sell or buy at Kelley Blue Book®</p>
            <div className="form-group"> <input type="text" name="vin" className="form-control" id="vin"  placeholder="VIN" onChange={this.handleChangeVin}/></div>
            <div className="form-group"> <input type="text" name="zipcode" id="zipcode" className="form-control"  placeholder="Zipcode" onChange={this.handleChangeZipcode} /></div>
            <div className="form-group">
               <button className="searchNow btn btn-primary" onClick={this.handleSearchPriceAdvisior} id="btn-vin"> Search now</button>
               <div className="ifrmaaes" /></div>
               <div className="copyrights">
                  <p>©2020 Kelley Blue Book Co., Inc. All rights reserved.</p>
                  <a href="https://www.kbb.com/company/privacy-policy/">Kelley Blue Book Privacy</a>
               </div>
            </div>
         </div>
      </div>
      <div className="col-lg-6 newcarss">
         <div className="card" style={{marginTop: '3%'}}>
         <div className="card-body">
            <div>
               <h3>Select a Vehicle</h3>
               <p />Choose a Vehicle to Start Comparing
               <p />
                 <div className="form-group">
              <label> Select  Make</label>
                 <Select name="make"

                          onChange={this.handleChange}
                          options={Makes}/>
                          <p style={{color:"red"}}>{this.state.errors['make']}</p>
              </div>
               <p className="error_make" />
            </div>
            <div className="form-group">
              <label> Select Model</label>
                   <Select name="model"
                          onChange={this.handleModelChange}
                          options={Models}/>
                      <p style={{color:"red"}}>{this.state.errors['model']}</p>
              </div>
            <div className="form-group">
              <label> Select  Year</label>
                 <Select name="make"

                          onChange={this.handleChangeYears}
                          options={Years}/>
                          <p style={{color:"red"}}>{this.state.errors['year']}</p>
              </div>
            <p style={{color:"red"}} className="error_genral" >{this.state.error}</p>
            <div className="form-group"> <button onClick={this.handleVehcileCompare}type="button" className="btn btn-primary btn_click"> Compare </button></div>
         </div>
      </div>         
      </div>
      </div>
 
</div>
<Latestblog/>
  <Footer/>
</div>;
    
  }
}
export default ResearchTool;