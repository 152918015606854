import React from 'react';
import '../App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Img from 'react-cool-img';
import LazyLoad from 'react-lazy-load';

class BrowseByBodyStyle extends React.Component {
  render() {
    return <div className="col-lg-12 BrowseByBodyStyle">
   <h2 className="uk-text-center uk-margin-large-top uk-margin-small-bottom">Browse By Body Style</h2>
   <div className="row">
   <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
    <a href="/certified-cars/?Body_Type=Sedan">
   			<div className="card">
        <div className="card-body">
        
         <div className="card-img-top">
         <LazyLoad height="175">
          <img className="card-img-top"
            src="../media/sedan.jpg"
            alt="Sedan"
            style={{'width':'100%','height':'175px'}}
            />
            </LazyLoad>
           </div>
        
         <h5 className="card-title text-center text-black">Sedan</h5>
         </div>
         </div>
          </a>
         </div>
    
      
     <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
       <a href="/certified-cars/?Body_Type=Convertible">
   			<div className="card">
   			 <div className="card-body">
       
          <div className="card-img-top">
            <img className="card-img-top"
            src="../media/convertible.jpg"
            alt="Convertible"
            style={{'width':'100%','height':'175px'}}
            />
         </div>
       
         <h5 className="card-title text-center text-black">Convertible</h5>
        </div>
        </div>
          </a>
        </div>
      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
       <a href="/certified-cars/?Body_Type=Coupe">
   			<div className="card">
   			 <div className="card-body">
        
             <div className="card-img-top">
              <img className="card-img-top"
            src="../media/coupe.jpg"
            alt="Coupe"
            style={{'width':'100%','height':'175px'}}
            />
            </div>
      
         <h5 className="card-title text-center text-black">Coupe</h5>
      </div>
      </div>
         </a>
      </div>
       <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
        <a href="/certified-cars/?Body_Type=Hatchback">
   			<div className="card">
   			 <div className="card-body">
        
           <div className="card-img-top">
           <img className="card-img-top"
            src="../media/hatchback.jpg"
            alt="hatchback"
            style={{'width':'100%','height':'175px'}}
            />
           
            </div>
         
         <h5 className="card-title text-center text-black">Hatchback</h5>
         </div>
         
         </div>
         </a>
         </div>
      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
       <a href="/certified-cars/?Body_Type=SUV">
   			<div className="card">
   			 <div className="card-body">
        
             <div className="card-img-top">
               <img className="card-img-top"
              style={{'width':'100%','height':'175px'}}
            src="../media/suv.jpg"
            alt="suv"
            />
         </div>
        
         <h5 className="card-title text-center text-black">SUV</h5>
         </div>
         </div>
          </a>
         </div>
       <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
         <a href="/certified-cars/?Body_Type=Wagon">
   			<div className="card">
   			 <div className="card-body">
       
             <div className="card-img-top">
             <Img className="card-img-top"
          style={{'width':'100%','height':'175px'}}
            src="../media/maruti-wagon-r-car-500x500-1.png"
            alt="Wagon"
            />
           </div>
        
         <h5 className="card-title text-center text-black">Wagon</h5>
         </div>
         </div>
          </a>
         </div>
     
   </div>
   </div>;
  }
}
export default BrowseByBodyStyle;