import React from 'react';

import { Route,BrowserRouter as Router } from "react-router-dom";
import Header from '../component/Header'; // Import a component from another file
import Maincontent from '../component/Maincontent'; // Import a component from another file
import Vehicles from '../component/Vehicles'; // Import a component from another file
class Routes extends React.Component {
  render() {
  	return(
   <div>
      
      </div>

      );
  }
}
export default Routes;