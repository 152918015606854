import React from 'react';

import $ from 'jquery';
import '../App.css';
import './app.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import Spinner from './spiner';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import ImageGallery from 'react-image-gallery';
import SEO from 'react-seo-component';
import NewsLetter from "./newsletter";
class chat extends React.Component {
			constructor(props) {
		    super(props);
		    this.state = {
		      error: null,
		      isLoaded: false,
		      items: [],
          Vehicle_id:null,
          extras:null,
          photos:null, 
          imagess:[],
          build:null,
          userName:null,
          userEmail:null,
          userMesssage:null,
          userPhone:null,
          errors:[],
          vehicleData:[],
          MessageResponse:null,
          Realted:null,

		    };
       
		  }
		 componentDidMount() {
		 	   var self = this;
         var url_string = window.location.href;
         var url = new URL(url_string);
         var Vehicle_id = url.searchParams.get("id");
         // get Related Products
              
        $("#includedContent").load("https://staging.fastor.in/chatbot/bot2/"); 
      
               
		  }

              

               
		 render() {
		 	

		   
		      return  <div id="includedContent"></div>;
		   
		}
  }

export default chat;